import "./style.css"
import React, { useCallback, useState } from 'react'
import { Container, Card, Spinner, Button } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { UserController } from "../../Redux/controllers/UserController"
import Utility from "../../Utils/Utility"
import { useSelector } from "react-redux"

export default function YearlyLeaves() {
    const token = useSelector((state) => state.user.token);
    const [uploadedFile, setUploadedFile] = useState(null)
    const [uploading, setUploading] = useState(false)
    const [uploadedFileUrl, setUploadedFileUrl] = useState(null)

    const onDrop = useCallback(async (acceptedFiles) => {
        const file = acceptedFiles[0]
        if (file && file.type === 'application/pdf') {
            const formData = new FormData()
            formData.append("doc", file)
            setUploading(true)
            try {
                const response = await UserController.SingleImageUploader(formData)
                console.log("Upload response:", response)
                setUploadedFile(file)
                setUploadedFileUrl(response.data.doc)
            } catch (error) {
                console.error("Error uploading file:", error)
                alert("Failed to upload the file. Please try again.")
            } finally {
                setUploading(false)
            }
        } else {
            alert('Please upload only PDF files')
        }
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf']
        },
        multiple: false
    })

    const updateYearlyLeaves = async () => {
        if (uploadedFileUrl) {
            try {
                const data = {
                    holidayMediaUrl: uploadedFileUrl
                }
                const response = await UserController.updateYearlyLeave(data,token)
                console.log(response)
                Utility.sToast("Yearly Leaves Updated Successfully")
            } catch (error) {
                console.log(error)
            }
        }
    }

        return (
            <Container className="py-5">
                <Card className="shadow-sm">
                    <Card.Header className="bg-danger text-white py-3">
                        <h4 className="mb-0">Yearly Leaves PDF</h4>
                    </Card.Header>
                    <Card.Body>
                        <div
                            {...getRootProps()}
                            className={`text-center p-5 border-2 border-dashed rounded-3 ${isDragActive ? 'bg-light border-danger' : 'border-gray-300'
                                }`}
                            style={{ cursor: 'pointer' }}
                        >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <p className="mb-0 text-danger">Drop the PDF file here</p>
                            ) : (
                                <div>
                                    <p className="mb-0">Drag and drop a PDF file here, or click to select file</p>
                                    {uploadedFile && (
                                        <p className="text-success mt-2">
                                            Uploaded: {uploadedFile.name}
                                        </p>
                                    )}
                                </div>
                            )}
                        </div>
                        {uploading && (
                            <div className="text-center mt-3">
                                <Spinner animation="border" variant="primary" />
                                <p className="mt-2 mb-0">Uploading file...</p>
                            </div>
                        )}
                        {uploadedFileUrl && (
                            <>
                                <div className="text-center mt-3">
                                    <p className="text-success">File uploaded successfully!</p>
                                    <a href={uploadedFileUrl} target="_blank" rel="noopener noreferrer">
                                        View Uploaded File
                                    </a>
                                </div>
                                <div className="d-flex mt-3 justify-content-center align-content-center">
                                    <Button variant="secondary" className="" onClick={() => {updateYearlyLeaves() }}>Conform to Replace With The Current Yearly Leaves</Button>
                                </div>
                            </>
                        )}
                    </Card.Body>
                </Card>
            </Container>
        )
    }
