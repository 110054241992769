import React, { useEffect, useState } from "react";
import "./DashBoard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BsCartCheckFill } from "react-icons/bs";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { BarChart } from "@mui/x-charts/BarChart";
import TickPlacementBars from "../../../globalcomponents/Barchart/DashboaedChart";
import Card from "../../../globalcomponents/Admincardslice/Card";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { PieChart } from "@mui/x-charts/PieChart";
import { LineChart } from "@mui/x-charts/LineChart";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserController } from "../../Redux/controllers/UserController";
import Utility from "../../Utils/Utility";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";
import CustomerQueries from "../CustomerQueries/CustomerQueries";
import { FaArrowRight } from "react-icons/fa";
import CustomerLeaveModal from "../Modals/LeaveModal/LeaveModal";
import { logOut } from "../../Redux/Slice/userSlice";
import * as XLSX from "xlsx";
import { PDFDocument, StandardFonts } from "pdf-lib";
import saveAs from "file-saver";
import html2canvas from "html2canvas";
import moment from "moment";

const getCurrentMonth = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const now = new Date();
  return monthNames[now?.getMonth()];
};

export default function DashBoard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [statisticsData, setStatisticsData] = useState({});
  const [staticsLoading, setStaticsLoading] = useState(false);
  const token = useSelector((state) => state.user.token);

  const getOrderDetails = async () => {
    try {
      setLoading(true);
      const response = await UserController.getOrderDetailsAPI(token);
      setOrderData(response.data.data);
      console.log("Orders are......", response.data.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      Utility.eToast(error?.response?.data?.message || error?.message);
      if (
        error?.response?.data?.message ===
        "token has expired , please login again"
      ) {
        dispatch(logOut());
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const getDashboardStatistics = async () => {
    try {
      setStaticsLoading(true);
      const resp = await UserController.getDashboardStatistics(token);
      setStatisticsData(resp.data.data);
      setStaticsLoading(false);
    } catch (error) {
      setStaticsLoading(false);
      console.log("Error", error);
    } finally {
      setStaticsLoading(false);
    }
  };

  const [financeData, setFinanceData] = useState([]);
  const [profitValue, setProfitValue] = useState("6-month");
  const [lossValue, setLossValue] = useState("6-month");

  const getFinancialChartDetails = async () => {
    try {
      setStaticsLoading(true);
      const resp = await UserController.financialChartDetails(token);
      setFinanceData(resp.data.data);
      setStaticsLoading(false);
    } catch (error) {
      setStaticsLoading(false);
      console.log("Error", error);
    } finally {
      setStaticsLoading(false);
    }
  };

  const [leaveData, setLeaveData] = useState([]);

  const getAllLeaveData = async () => {
    try {
      setLoading(true);
      const res = await UserController.getAllLeaveEmployeeToDay(token);
      setLeaveData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllLeaveData();
  }, []);

  useEffect(() => {
    getOrderDetails();
  }, []);

  useEffect(() => {
    getDashboardStatistics();
  }, []);

  useEffect(() => {
    getFinancialChartDetails();
  }, []);

  // console.log("leaveData",leaveData);

  let allOrder = orderData;

  const currentMonth = getCurrentMonth();

  const valueFormatter = Utility.valueFormatter;

  const [salesTimePeriod, setSalesTimePeriod] = useState("6-month");
  const [expensesTimePeriod, setExpensesTimePeriod] = useState("6-month");
  const [salaryTimePeriod, setSalaryTimePeriod] = useState("6-month");

  const shortenMonthValues = (data) => {
    return data.map((item) => {
      const [month, year] = item.month.split(" ");
      const shortMonth = month.slice(0, 3);
      const shortYear = year.slice(-2);
      return {
        ...item,
        month: `${shortMonth} ${shortYear}`,
      };
    });
  };

  let dataset1;
  let dataset2;
  let dataset3;

  if (salesTimePeriod === "6-month") {
    dataset1 = shortenMonthValues(financeData?.slice(6, 12));
  } else if (salesTimePeriod === "year") {
    dataset1 = shortenMonthValues(financeData);
  }
  if (expensesTimePeriod === "6-month") {
    dataset2 = shortenMonthValues(financeData?.slice(6, 12));
  } else if (expensesTimePeriod === "year") {
    dataset2 = shortenMonthValues(financeData);
  }
  if (salaryTimePeriod === "6-month") {
    dataset3 = shortenMonthValues(financeData?.slice(6, 12));
  } else if (salaryTimePeriod === "year") {
    dataset3 = shortenMonthValues(financeData);
  }

  function getLossValues(data) {
    return data.map((item) => item.loss);
  }
  function getProfitValues(data) {
    return data.map((item) => item.profit);
  }

  const lossData = getLossValues(financeData);
  const profitData = getProfitValues(financeData);
  const axisData = Utility.convertMonthsToNumbers(financeData);
  // console.log("Axis Data", axisData);

  let graphLossData;
  let graphProfitData;
  let lossAxisValue;
  let profitAxisValue;

  if (lossValue === "6-month") {
    graphLossData = lossData?.slice(6, 12);
    lossAxisValue = axisData?.slice(6, 12)?.sort((a, b) => a - b);
  } else {
    graphLossData = lossData;
    lossAxisValue = axisData?.sort((a, b) => a - b);
  }

  if (profitValue === "6-month") {
    graphProfitData = profitData?.slice(6, 12);
    profitAxisValue = axisData?.slice(6, 12)?.sort((a, b) => a - b);
  } else {
    graphProfitData = profitData;
    profitAxisValue = axisData?.sort((a, b) => a - b);
  }

  const [showLeaveModal, setShowLeaveModal] = useState(false);

  const viewLeaveModal = () => {
    if (leaveData?.length === 0) {
      return;
    }
    setShowLeaveModal(true);
  };

  const downloadExcel = () => {
    const formattedOrderData = orderData.map((order) => {
      const formattedOrder = { ...order };
      Object.keys(formattedOrder).forEach((key) => {
        const value = formattedOrder[key];
        if (moment(value, moment.ISO_8601, true).isValid()) {
          formattedOrder[key] = moment(value).format("MMMM Do, YYYY");
        }
      });
      return formattedOrder;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedOrderData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

    XLSX.writeFile(workbook, "Dashboard.xlsx");
  };

  const downloadPDF = async () => {
    const pdfDoc = await PDFDocument.create();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

    const lineHeight = 15;

    let page = pdfDoc.addPage();
    const { width, height } = page.getSize();
    let yOffset = height - 50;

    const createNewPage = () => {
      page = pdfDoc.addPage();
      yOffset = height - 50;
    };

    page.drawText("Dashboard Report", {
      x: 50,
      y: yOffset,
      font: boldFont,
      size: 18,
    });
    yOffset -= 30;

    const currentDate = moment().format("MMMM Do, YYYY");
    page.drawText(`Generated on: ${currentDate}`, {
      x: 50,
      y: yOffset,
      font,
      size: 12,
    });
    yOffset -= 30;

    page.drawText("Order Details:", {
      x: 50,
      y: yOffset,
      font: boldFont,
      size: 14,
    });
    yOffset -= 20;

    orderData.forEach((order, index) => {
      if (yOffset < 100) {
        createNewPage();
      }

      page.drawText(`Order ${index + 1}:`, {
        x: 50,
        y: yOffset,
        font: boldFont,
        size: 12,
      });
      yOffset -= lineHeight;

      const orderInfo = {
        "Order ID": order?.orderID,
        "Creation Date": moment(order?.orderCreationDate).format(
          "MMMM Do, YYYY"
        ),
        "Estimated Delivery Date": moment(order?.estimatedDeliveryDate).format(
          "MMMM Do, YYYY"
        ),
        "Delivery Status": order?.deliveryStatus,
      };

      Object.entries(orderInfo).forEach(([key, value]) => {
        if (yOffset < 50) createNewPage();
        page.drawText(`${key}: ${value}`, {
          x: 70,
          y: yOffset,
          font,
          size: 10,
        });
        yOffset -= lineHeight;
      });

      if (order.customerDetails) {
        page.drawText("Customer Details:", {
          x: 70,
          y: yOffset,
          font: boldFont,
          size: 12,
        });
        yOffset -= lineHeight;

        const customerInfo = {
          Name: order?.customerDetails?.customer?.name,
          Email: order?.customerDetails?.customer?.email,
          Phone: order?.customerDetails?.customer?.phoneNo,
          Address: order?.customerDetails?.customer?.address,
        };

        Object.entries(customerInfo).forEach(([key, value]) => {
          if (yOffset < 50) createNewPage();
          page.drawText(`${key}: ${value}`, {
            x: 90,
            y: yOffset,
            font,
            size: 10,
          });
          yOffset -= lineHeight;
        });
      }

      if (order.paymentDetails) {
        page.drawText("Payment Details:", {
          x: 70,
          y: yOffset,
          font: boldFont,
          size: 12,
        });
        yOffset -= lineHeight;

        const paymentInfo = {
          "Total Amount": `INR  ${order?.paymentDetails?.totalAmount}`,
          "Remaining Amount": `INR  ${order?.paymentDetails?.remainingAmount}`,
          Comments: order?.paymentDetails?.additionalComments,
        };

        Object.entries(paymentInfo).forEach(([key, value]) => {
          if (yOffset < 50) createNewPage();
          page.drawText(`${key}: ${value}`, {
            x: 90,
            y: yOffset,
            font,
            size: 10,
          });
          yOffset -= lineHeight;
        });
      }

      if (order?.productDetails && Array.isArray(order?.productDetails)) {
        page.drawText("Product Details:", {
          x: 70,
          y: yOffset,
          font: boldFont,
          size: 12,
        });
        yOffset -= lineHeight;

        order.productDetails.forEach((product, idx) => {
          page.drawText(`Product ${idx + 1}:`, {
            x: 90,
            y: yOffset,
            font: boldFont,
            size: 10,
          });
          yOffset -= lineHeight;

          const productInfo = {
            Name: product?.product?.name,
            // Category: product.product.category,
            Price: `INR  ${product?.product?.price}`,
            // Color: product.product.color?.join(", "),
            Size: product?.product?.size?.join(", "),
            "Stock Status": product?.product?.stockStatus?.join(", "),
          };

          Object.entries(productInfo).forEach(([key, value]) => {
            if (yOffset < 50) createNewPage();
            page.drawText(`${key}: ${value}`, {
              x: 110,
              y: yOffset,
              font,
              size: 10,
            });
            yOffset -= lineHeight;
          });
        });
      }

      yOffset -= 20;
    });

    const pdfBytes = await pdfDoc.save();
    saveAs(
      new Blob([pdfBytes], { type: "application/pdf" }),
      "DashboardReport.pdf"
    );
  };

  return (
    <Container className="dashboardmaincontainer">
      {showLeaveModal && (
        <CustomerLeaveModal
          show={showLeaveModal}
          onHide={() => setShowLeaveModal(false)}
          data={leaveData}
        />
      )}
      {/* cards */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <button className="btn btn-success me-2" onClick={downloadExcel}>
          Export Excel
        </button>
        <button className="btn btn-primary me-3" onClick={downloadPDF}>
          Export PDF
        </button>
      </div>

      <Row
        className="mainrow"
        style={{ display: "flex", gap: "10px", marginTop: "1.5rem" }}
      >
        <Col className="cardCol">
          <Col>
            <Card
              name={"Total Order"}
              icon={<BsCartCheckFill />}
              ordernumber={statisticsData?.orderStats?.totalOrders || 0}
              customernumber={[
                statisticsData?.orderStats?.currentWeekOrders,
                statisticsData?.orderStats?.currentMonthOrders,
                statisticsData?.orderStats?.currentYearOrders,
              ]}
            />
          </Col>
          <Col>
            <Card
              name={"Total Customer"}
              icon={<BsFillPersonLinesFill />}
              ordernumber={statisticsData?.customerStats?.totalCustomers || 0}
              customernumber={[
                statisticsData?.customerStats?.currentWeekCustomers,
                statisticsData?.customerStats?.currentMonthCustomers,
                statisticsData?.customerStats?.currentYearCustomers,
              ]}
            />
          </Col>
          <Col>
            <div
              className={`leaveEmp ${
                leaveData?.length > 0 ? "cursor-pointer" : ""
              }`}
              onClick={viewLeaveModal}
            >
              <p style={{ color: "black" }}>
                <strong className="TotalEmployee">
                  Total Employee On Leave Today
                </strong>
              </p>
              <p>
                <strong>{leaveData?.length}</strong>
              </p>
            </div>
          </Col>
        </Col>

        <Col className="queryCol">
          <CustomerQueries page={6} />
        </Col>
      </Row>

      {/* graphs */}
      <Row className="secondRow">
        <Row
          sm={7}
          xs={12}
          className="p-0 m-0 "
          style={{ display: "flex", gap: "10px" }}
        >
          <Col className="piChartAndBarGraph">
            <Col className="firstgraph">
              <div className="graphTitle">
                <div>
                  <p style={{ color: "black" }}>Financial Charts</p>
                </div>
                <div>
                  <p>{currentMonth}</p>
                </div>
              </div>
              {!statisticsData ? (
                <div className="spinnerrowitem">
                  <CircularIndeterminate />
                </div>
              ) : (
                <PieChart
                  series={[
                    {
                      data: [
                        { color: "#fefefe" },
                        {
                          id: 0,
                          value: statisticsData?.financialStats?.monthly[1],
                          label: `Expenses (${valueFormatter(
                            statisticsData?.financialStats?.monthly[1]
                          )})`,
                          color: "#FACC15",
                        },
                        {
                          id: 1,
                          value: statisticsData?.financialStats?.monthly[0],
                          label: `Sales(${valueFormatter(
                            statisticsData?.financialStats?.monthly[0]
                          )})`,
                          color: "#F59E0B",
                        },
                        {
                          id: 2,
                          value: statisticsData?.financialStats?.monthly[2],
                          label: `Salary(${valueFormatter(
                            statisticsData?.financialStats?.monthly[2]
                          )})`,
                          color: "#EC4899",
                        },
                      ],
                    },
                  ]}
                  height={200}
                  margin={{ left: -50, top: 40 }}
                />
              )}
            </Col>

            <Col className="firstgraph">
              <div className="graphTitle">
                <div>
                  <p style={{ color: "black" }}>Sales</p>
                </div>
                <div>
                  <select
                    name="period"
                    id="period"
                    data-placeholder="This Week"
                    className="cardselect"
                    value={salesTimePeriod}
                    onChange={(e) => setSalesTimePeriod(e.target.value)}
                  >
                    <option value="6-month">Last 6 Months</option>
                    <option value="year">Last Year</option>
                  </select>
                </div>
              </div>
              {!statisticsData ? (
                <div className="spinnerrowitem">
                  <CircularIndeterminate />
                </div>
              ) : (
                <TickPlacementBars
                  data={dataset1}
                  accessKey="totalSales"
                  widthOfBar={0.85}
                />
              )}
            </Col>
          </Col>

          <Col sm={5} xs={12} className="alluserdataborder">
            <Col>
              <Col className="alluserdatabordertop">
                <p style={{ color: "black" }}>Pending Orders</p>
                <p
                  onClick={() => navigate("/orderhistory")}
                  style={{ cursor: "pointer", color: "black" }}
                >
                  All Order{" "}
                  <FaArrowRight
                    style={{
                      fontSize: "16px",
                      marginLeft: "5px",
                      color: "black",
                    }}
                  />
                </p>
              </Col>
              {loading ? (
                <div className="spinnerrowitem">
                  <CircularIndeterminate />
                </div>
              ) : (
                <Col id="allPendingOrder">
                  {allOrder
                    ?.filter((order) => order?.deliveryStatus === "pending")
                    ?.map((order, index) => (
                      <Col className="alldatainfo" key={index}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginTop: "10px",
                          }}
                        >
                          <p>{Utility?.formatDate(order?.orderCreationDate)}</p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="hightalluser">
                            Order ID: {order?.orderID}
                          </p>
                          <GlobalButton
                            disabledBgColor={Utility?.getStatusColor(
                              order?.deliveryStatus
                            )}
                            disabled={true}
                            name={
                              order?.deliveryStatus?.charAt(0).toUpperCase() +
                                order?.deliveryStatus?.slice(1) || "N/A"
                            }
                            width="5rem"
                            padding="2px"
                            color="#fff"
                            height="20px"
                          />
                        </div>
                        <hr />
                      </Col>
                    ))}
                </Col>
              )}
            </Col>
          </Col>
        </Row>

        <Row className="lastRow">
          <Col className="firstgraph">
            <div className="graphTitle">
              <div>
                <p style={{ color: "black" }}>Expenses</p>
              </div>
              <div>
                <select
                  name="period"
                  id="period"
                  data-placeholder="This Week"
                  className="cardselect"
                  value={expensesTimePeriod}
                  onChange={(e) => setExpensesTimePeriod(e.target.value)}
                >
                  <option value="6-month" style={{ color: "black" }}>
                    Last 6 Months
                  </option>
                  <option value="year">Last Year</option>
                </select>
              </div>
            </div>
            {!financeData ? (
              <div className="spinnerrowitem">
                <CircularIndeterminate />
              </div>
            ) : (
              <BarChart
                borderRadius={8}
                dataset={dataset2}
                yAxis={[
                  {
                    scaleType: "band",
                    dataKey: "month",
                    categoryGapRatio: 0.75,
                  },
                ]}
                series={[
                  {
                    dataKey: "totalExpenses",
                    valueFormatter,
                    color: "#C76E00",
                  },
                ]}
                layout="horizontal"
                grid={{ vertical: true }}
                height={400}
                margin={{ left: 65 }}
              />
            )}
          </Col>

          <Col className="firstgraph">
            <div className="graphTitle">
              <div>
                <p style={{ color: "black" }}>Salary</p>
              </div>
              <div>
                <select
                  name="period"
                  id="period"
                  data-placeholder="This Week"
                  className="cardselect"
                  value={salaryTimePeriod}
                  onChange={(e) => setSalaryTimePeriod(e.target.value)}
                >
                  <option value="6-month">Last 6 Months</option>
                  <option value="year">Last Year</option>
                </select>
              </div>
            </div>
            {!financeData ? (
              <div className="spinnerrowitem">
                <CircularIndeterminate />
              </div>
            ) : (
              <TickPlacementBars data={dataset3} accessKey="totalSalary" />
            )}
          </Col>
        </Row>

        <Row className="lastRow">
          <Col className="firstgraph">
            <div className="graphTitle">
              <div>
                <p style={{ color: "black" }}>Profit</p>
              </div>
              <div>
                <select
                  name="period"
                  id="period"
                  data-placeholder="This Week"
                  className="cardselect"
                  value={profitValue}
                  onChange={(e) => setProfitValue(e.target.value)}
                >
                  <option value="6-month">Last 6 Months</option>
                  <option value="year">Last Year</option>
                </select>
              </div>
            </div>
            <div style={{ width: "95%", height: "100%" }}>
              {!financeData ? (
                <div className="spinnerrowitem">
                  <CircularIndeterminate />
                </div>
              ) : (
                <LineChart
                  margin={{ left: 60, top: 60 }}
                  grid={{ horizontal: true, vertical: true }}
                  xAxis={[
                    {
                      data: profitAxisValue,
                      valueFormatter: Utility.getShortMonthName,
                    },
                  ]}
                  series={[
                    {
                      data: graphProfitData,
                      color: "#C76E00",
                      valueFormatter,
                    },
                  ]}
                  // width={500}
                  height={400}
                  leftAxis={{
                    disableTicks: true,
                  }}
                />
              )}
            </div>
          </Col>

          <Col className="firstgraph">
            <div className="graphTitle">
              <div>
                <p style={{ color: "black" }}>Loss</p>
              </div>
              <div>
                <select
                  name="period"
                  id="period"
                  data-placeholder="This Week"
                  className="cardselect"
                  value={lossValue}
                  onChange={(e) => setLossValue(e.target.value)}
                >
                  <option value="6-month">Last 6 Months</option>
                  <option value="year">Last Year</option>
                </select>
              </div>
            </div>
            <div style={{ width: "97%", height: "100%" }}>
              {!financeData ? (
                <div className="spinnerrowitem">
                  <CircularIndeterminate />
                </div>
              ) : (
                <LineChart
                  margin={{ left: 70, top: 60 }}
                  grid={{ horizontal: true, vertical: true }}
                  xAxis={[
                    {
                      data: lossAxisValue,
                      valueFormatter: Utility.getShortMonthName,
                    },
                  ]}
                  series={[
                    {
                      data: graphLossData,
                      color: "#C76E00",
                      valueFormatter,
                    },
                  ]}
                  // width={lossAxisValue.length === 12 ? 550 : 500}
                  height={400}
                  leftAxis={{
                    disableTicks: true,
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}
