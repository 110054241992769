import React, { useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "./TransactionDetailModal.css";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import { MdOutlineFileDownload } from "react-icons/md";

const TransactionDetailModal = (props) => {
  return (
    <div>
      <Modal id="transactionModal" {...props} size="lg">
        <Modal.Header closeButton style={{ background: "#E4CDAA" }}>
          <Modal.Title style={{ color: "#642315E5", background: "#E4CDAA" }}>
            Transaction Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* first row  */}
          <Row className="mt-1">
            <h6 className="transactionHeading">Transaction Details</h6>
            <Row className="mt-1">
              <Col>Transaction ID</Col>
              <Col className="value">TRX2024001</Col>
            </Row>
            <Row className="mt-1">
              <Col>Transaction Date</Col>
              <Col className="value">TRX2024001</Col>
            </Row>
            <Row className="mt-1">
              <Col>Transaction Type</Col>
              <Col className="value">TRX2024001</Col>
            </Row>
          </Row>

          {/* second row  */}
          <Row className="mt-1">
            <h6 className="transactionHeading">Transaction Description</h6>
            <Row className="mt-1">
              <Col>Transaction Description</Col>
              <Col className="value">Buy Light Materials</Col>
            </Row>
            <Row className="mt-1">
              <Col>Transaction Party</Col>
              <Col className="value">XYZ Electronics Supplier</Col>
            </Row>
            <Row className="mt-1">
              <Col>Transaction Party Mobile Number</Col>
              <Col className="value">+91 639874561</Col>
            </Row>
            <Row className="mt-1">
              <Col>Payment Method</Col>
              <Col className="value">UPI Transfer</Col>
            </Row>
            <Row className="mt-1">
              <Col>Transaction Amount</Col>
              <Col className="value">$12500</Col>
            </Row>
            <Row className="mt-1">
              <Col>Transaction ID</Col>
              <Col className="value">Not Available</Col>
            </Row>
          </Row>

          <Row>
            <h6 className="transactionHeading">View Bill</h6>
            <Row>
              <Col>
                <div id="btnAndIcon">
                  <button>
                    Download Transaction Bill{" "}
                    <MdOutlineFileDownload color="#642315E5" />{" "}
                  </button>
                </div>
              </Col>
            </Row>
            <Row className="d-flex flex-row-reverse">
              <GlobalButton
                name={"Delete"}
                background={"#E4CDAA"}
                color={"#642315"}
                width={"3rem"}
                padding={"0"}
                border={'1px solid #642315'}
                borderradius={'0.3rem'}
              />

              <GlobalButton
                name={"Edit"}
                background={"#E4CDAA"}
                color={"#642315"}
                width={"3rem"}
                padding={"0.8"}
                marginRight={"1rem"}
                border={'1px solid #642315'}
                borderradius={'0.3rem'}
                onclick={()=>props.onHideFirstModal()}
              />
            </Row>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TransactionDetailModal;
