import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import "./Barchart.css";
import { useState } from "react";
import Utility from "../../Admin/Utils/Utility";

const valueFormatter = Utility.valueFormatter;

export default function TickPlacementBars({
  data,
  accessKey = "",
  widthOfBar = 0.8,
}) {
  const [tickPlacement, setTickPlacement] = useState("middle");
  const [tickLabelPlacement, setTickLabelPlacement] = useState("middle");
  const props = { data, accessKey };

  // console.log("Data from barCart", typeof props.accessKey);

  const dataset = props?.data;
  const key = props?.accessKey || "seoul";
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <BarChart
        grid={{ horizontal: true }}
        sx={{ paddingTop: "20px" }}
        dataset={dataset}
        borderRadius={15}
        series={[{ dataKey: key, valueFormatter, color: ["#C76E00"] }]}
        height={400}
        xAxis={[
          {
            scaleType: "band",
            dataKey: "month",
            tickPlacement,
            tickLabelPlacement,
            categoryGapRatio: widthOfBar,
          },
        ]}
        leftAxis={{
          disableLine: true,
          disableTicks: true,
        }}
        bottomAxis={{
          disableLine: true,
          disableTicks: true,
        }}
      />
    </div>
  );
}
