import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { IoIosAddCircleOutline } from "react-icons/io";
import AddNewTaskModal from "../Modals/AddNewTaskModal/AddNewTaskModal";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { useNavigate } from "react-router-dom";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import { UserController } from "../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import Utility from "../../Utils/Utility";
import * as XLSX from "xlsx";
import { PDFDocument, StandardFonts } from "pdf-lib";

const AllTasks = () => {
  const token = useSelector((state) => state.user.token);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [taskModal, setTaskModal] = useState(false);
  const [taskLoading, setTaskLoading] = useState(false);
  const navigate = useNavigate();
  const [allTasks, setAllTasks] = useState([]);

  const handleView = (task) => {
    navigate("/task", { state: task });
  };

  const getAllTasks = useCallback(async () => {
    try {
      setTaskLoading(true);
      const resp = await UserController.getAllTask(token);
      setAllTasks(resp?.data?.data);
      setTaskLoading(false);
    } catch (error) {
      setTaskLoading(false);
      console.log("error in get all task", error);
    } finally {
      setTaskLoading(false);
    }
  }, [token]);

  useEffect(() => {
    getAllTasks();
  }, [token, getAllTasks]);

  const data = allTasks
    .filter((task) => (selectedStatus ? task?.status === selectedStatus : true))
    .map((task, index) => ({
      slNo: index + 1,
      assignedOn: Utility?.formatDate(task?.createdAt) || "N/A",
      assignedBy: task?.assignedBy?.name || "N/A",
      assignedTo: task?.assignedTo?.name || "N/A",
      deadLine: Utility?.formatDate(task?.deadline) || "N/A",
      dept:
        (task?.assignedTo?.empDepartment &&
          Utility.getDepartment(task?.assignedTo?.empDepartment)) ||
        "N/A",
      status: (
        <GlobalButton
          disabledBgColor={Utility?.getStatusColor(task?.status)}
          disabled={true}
          name={task?.status === "pending" ? "Pending" : "Completed"}
          width="4rem"
          padding="1.5px"
          color="#fff"
          fontsize="10px"
        />
      ),
      action: (
        <div className="d-flex gap-2">
          <GlobalButton
            name={"View"}
            background={"#bc2329"}
            color={"#fff"}
            padding={"1.5px"}
            fontsize="10px"
            width="1rem"
            onclick={() => handleView(task)}
          />
        </div>
      ),
    }));

  const exportToExcel = () => {
    const formattedData = allTasks.map((task, index) => ({
      "Sl No": index + 1,
      "Assigned On": Utility?.formatDate(task?.createdAt) || "N/A",
      "Assigned By": task?.assignedBy?.name || "N/A",
      "Assigned To": task?.assignedTo?.name || "N/A",
      "Dead Line": Utility?.formatDate(task?.deadline) || "N/A",
      Department:
        (task?.assignedTo?.empDepartment &&
          Utility.getDepartment(task?.assignedTo?.empDepartment)) ||
        "N/A",
      Status: task?.status === "pending" ? "Pending" : "Completed",
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Tasks");
    XLSX.writeFile(wb, "AllTasks.xlsx");
  };

  const exportToPDF = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 800]);
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontSize = 12;
    const title = "All Tasks";
    page.drawText(title, {
      x: 250,
      y: 780,
      size: 16,
      font,
    });

    const headers = [
      "Sl No",
      "Assigned On",
      "Assigned By",
      "Assigned To",
      "Dead Line",
      "Department",
      "Status",
    ];
    const rows = allTasks.map((task, index) => [
      (index + 1).toString(),
      Utility?.formatDate(task?.createdAt) || "N/A",
      task?.assignedBy?.name || "N/A",
      task?.assignedTo?.name || "N/A",
      Utility?.formatDate(task?.deadline) || "N/A",
      task?.assignedTo?.empDepartment
        ? Utility.getDepartment(task?.assignedTo?.empDepartment)
        : "N/A",
      task?.status === "pending" ? "Pending" : "Completed",
    ]);

    let y = 740;
    headers.forEach((header, i) => {
      page.drawText(header, { x: 50 + i * 75, y, size: fontSize, font });
    });

    y -= 20;
    rows.forEach((row) => {
      row.forEach((cell, i) => {
        page.drawText(cell, { x: 50 + i * 75, y, size: fontSize, font });
      });
      y -= 20;
    });
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "AllTasks.pdf";
    link.click();
  };

  const columns = [
    { accessorKey: "slNo", header: "Sl No", size: 30 },
    { accessorKey: "assignedOn", header: "Assigned On", size: 50 },
    { accessorKey: "assignedBy", header: "Assigned By", size: 50 },
    { accessorKey: "assignedTo", header: "Assigned To", size: 50 },
    { accessorKey: "dept", header: "Dept.", size: 50 },
    { accessorKey: "status", header: "Status", size: 50 },
    { accessorKey: "deadLine", header: "Dead Line", size: 50 },
    { accessorKey: "action", header: "Action", size: 50 },
  ];

  return (
    <>
      {taskModal && (
        <AddNewTaskModal onHide={() => setTaskModal(false)} show={taskModal} />
      )}
      <Row className="secondRow">
        <Col className="allTask">
          <div>
            <span
              style={{
                color: "black",
                marginLeft: "10px",
                marginBottom: "10px",
                width: "100px",
              }}
            >
              <strong>All Tasks</strong>
            </span>
          </div>
        </Col>
        <Col className="filterAndAddNewTask">
          <button className="btn btn-success me-2" onClick={exportToExcel}>
            Export Excel
          </button>
          <button className="btn btn-primary me-3" onClick={exportToPDF}>
            Export PDF
          </button>
          <div className="filterButton">
            <button id="btnnn" className="selectButton">
              <Form.Select
                onChange={(e) => setSelectedStatus(e.target.value)}
                aria-label="Default select example"
                className="multiselect-dropdown"
              >
                <option value="">All</option>
                <option value="completed">Completed</option>
                <option value="pending">Pending</option>
              </Form.Select>
            </button>
          </div>
          <div className="addNewTask">
            <button
              id="btnnn"
              onClick={() => setTaskModal(true)}
              className="addNewSubButton"
            >
              <IoIosAddCircleOutline id="plusicon" /> Add New Task
            </button>
          </div>
        </Col>
      </Row>
      <Row>
        {taskLoading ? (
          <div className="spinnerrowitem">
            <CircularIndeterminate />
          </div>
        ) : (
          <DataTable columns={columns} data={data} />
        )}
      </Row>
    </>
  );
};

export default AllTasks;
