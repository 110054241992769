import React, { useEffect, useState } from "react";
import "./ProductCategoty.css";
import { Container, Row, Col } from "react-bootstrap";
import { IoIosAddCircleOutline } from "react-icons/io";
import AddCategory from "./AddCategory";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../Redux/Slice/userSlice";
import { UserController } from "../../Redux/controllers/UserController";
import * as XLSX from "xlsx";
import { PDFDocument, StandardFonts } from "pdf-lib";
import saveAs from "file-saver";
import Utility from "../../Utils/Utility";

const ProductCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const token = useSelector((state) => state?.user?.token);
  const [alldata, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleModal = () => {
    setShowModal(true);
  };

  const allcategory = async () => {
    try {
      setLoading(true);
      const response = await UserController.GetAllCategory(token);
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Utility.eToast(error?.response?.data?.message || error?.message);
      if (
        error?.response?.data?.message ===
        "token has expired , please login again"
      ) {
        dispatch(logOut());
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    allcategory();
  }, [token]);

  const downloadExcel = () => {
    const formattedData = alldata.map((item) => ({
      ID: item.id,
      Name: item.name,
      Image: item.image,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Categories");

    XLSX.writeFile(workbook, "Categories.xlsx");
  };

  const downloadPDF = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 800]);
    const { width, height } = page.getSize();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    page.drawText("Product Categories", {
      x: 50,
      y: height - 50,
      font,
      size: 18,
    });

    let yOffset = height - 100;
    const lineHeight = 20;

    alldata.forEach((item) => {
      if (yOffset < 50) {
        page.addPage();
        yOffset = height - 50;
      }
      page.drawText(`ID: ${item.id}`, { x: 50, y: yOffset, font, size: 12 });
      yOffset -= lineHeight;
      page.drawText(`Name: ${item.name}`, {
        x: 50,
        y: yOffset,
        font,
        size: 12,
      });
      yOffset -= lineHeight;
    });

    const pdfBytes = await pdfDoc.save();
    saveAs(new Blob([pdfBytes], { type: "application/pdf" }), "Categories.pdf");
  };

  return (
    <Container>
      {showModal && (
        <AddCategory
          show={showModal}
          onHide={() => setShowModal(false)}
          Allcategory={allcategory}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <button className="btn btn-success me-2" onClick={downloadExcel}>
          Export Excel
        </button>
        <button className="btn btn-primary me-3" onClick={downloadPDF}>
          Export PDF
        </button>
      </div>
      <Row className="second-box">
        <Col style={{ marginTop: "1rem" }}>
          <button id="btnnn" onClick={handleModal}>
            <IoIosAddCircleOutline id="plusicon" /> Add Category
          </button>
        </Col>
      </Row>
      <Row style={{ marginLeft: "0.1rem" }} className="productsecondrow">
        {loading ? (
          <div className="spinnerrowitem">
            <CircularIndeterminate />
          </div>
        ) : (
          <Row className="maptoprow">
            {alldata.map((item) => (
              <div key={item.id} sm={2} className="productmaincontainer">
                <div className="productimage">
                  <img src={item.image} alt={item.title} />
                </div>
                <div className="productimagefont">{item.name}</div>
              </div>
            ))}
          </Row>
        )}
      </Row>
    </Container>
  );
};

export default ProductCategory;
