import React, { useEffect, useState } from "react";
import { Container, Modal, Row, Col, Form } from "react-bootstrap";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import { UserController } from "../../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import CircularIndeterminate from "../../../../globalcomponents/Spinner/Spinner";
import Utility from "../../../Utils/Utility";

const AddNewTaskModal = (props) => {
  const { token } = useSelector((st) => st?.user);
  const { _id } = useSelector((st) => st?.user?.userInfo);
  const [loading, setLoading] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [isOrder, setIsOrder] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [assignData, setAssignData] = useState({
    assignedBy: _id,
    assignedTo: "",
    empDepartment: "",
    taskName: "",
    deadline: "",
    orderID: "",
  });
  const handleChange = (e) => {
    setAssignData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  let departments = [
    "sales",
    "production",
    "procurement_and_inventory",
    "delivery",
    "accounts",
    "manager",
  ];

  const getEmployeesOfSelectedDept = async (dept) => {
    if (!dept) {
      return;
    }
    try {
      setLoading(true);
      const { data } = await UserController.getEmployeeList(token, {
        dept: [dept],
      });
      setEmployees(data?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  console.log("employees", employees);
  const getOrderList = async () => {
    try {
      setLoading(true);
      const { data } = await UserController.getOrderList(token);
      setOrderList(data?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleAssign = async () => {
    let finalData = assignData;
    if (!finalData?.assignedTo) {
      return Utility.eToast("select an employee");
    }
    if (!finalData?.taskName) {
      return Utility.eToast("provide task name");
    }
    if (!finalData?.deadline) {
      return Utility.eToast("provide a deadline");
    }
    if (finalData?.deadline < Date.now()) {
      return Utility.eToast("provide valid deadline");
    }
    if (isOrder && !finalData?.orderID) {
      return Utility.eToast("select an order");
    }
    if (finalData?.orderID === "") {
      finalData.orderID = undefined;
    }
    try {
      setAssignLoading(true);

      const { data } = await UserController.assignNewTask(token, finalData);
      Utility.sToast(data?.message);
      props.onHide();
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setAssignLoading(false);
    }
  };

  return (
    <Modal {...props} size="lg">
      <Modal.Header closeButton style={{ background: "#f4f3f0" }}>
        <Modal.Title style={{ color: "black", background: "#f4f3f0" }}>
          Assign New Task
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row className="mt-2">
            <Col sm={6}>
              <p7 className="titleofcontainer">Select Department</p7>
              <br />
              <Form.Select
                name="empDepartment"
                className="amthirdcoltype"
                onChange={(e) => {
                  getEmployeesOfSelectedDept(e.target.value);
                  handleChange(e);
                }}
              >
                <option value="">Select an option</option>
                {departments?.map((department) => (
                  <option key={department} value={department}>
                    {Utility.getDepartment(department)}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col sm={6}>
              <p7 className="titleofcontainer">Select Employee</p7>
              <br />
              <Form.Select
                name="assignedTo"
                onChange={(e) => handleChange(e)}
                className="amthirdcoltype"
                disabled={loading}
              >
                <option value="">Select an option</option>
                {employees?.map((emp) => (
                  <option key={emp} value={emp?._id}>
                    {emp?.empSubDepartment
                      ? `${emp?.name} (${emp?.empSubDepartment})`
                      : emp?.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label className="titleofcontainer">
                  Task Details
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="taskName"
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label className="titleofcontainer">Deadline</Form.Label>
                <Form.Control
                  type="date"
                  name="deadline"
                  onChange={(e) => handleChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm={6}>
              <Form.Group
                className="mb-3 d-flex align-items-center gap-3"
                controlId="formGroupEmail"
              >
                <Form.Label className="titleofcontainer">
                  Is task related to an order?
                </Form.Label>
                <Form.Switch
                  onChange={() => {
                    setIsOrder((prev) => !prev);
                    getOrderList();
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          {isOrder && (
            <Row className="mt-2 mb-5">
              <Col sm={12}>
                <p7 className="titleofcontainer">Select Order</p7>
                <br />
                <Form.Select
                  disabled={loading}
                  name="orderID"
                  className="amthirdcoltype"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option value="">Select an option</option>
                  {orderList?.map((order) => (
                    <option key={order?._id} value={order?._id}>
                      {`#${order?.orderID}  --   ${Utility.formatDate(
                        order?.createdAt
                      )}`}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          )}

          <Row className="subadminlastbutton">
            <Col className="d-flex align-items-center justify-content-end">
              {assignLoading ? (
                <CircularIndeterminate />
              ) : (
                <GlobalButton
                  name={"Assign"}
                  paddingTop={"0.4rem"}
                  paddingBottom={"0.4rem"}
                  paddingLeft={"1rem"}
                  paddingRight={"1rem"}
                  fontFamily="Roboto"
                  background={"#dc2329"}
                  color={"#fff"}
                  width={"3rem"}
                  onclick={handleAssign}
                />
              )}
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddNewTaskModal;
