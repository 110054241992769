import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Container } from "react-bootstrap";
import "./Notification.css";
import { IoNotifications } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import { useSelector } from "react-redux";
import { UserController } from "../../../Redux/controllers/UserController";
import CircularIndeterminate from "../../../../globalcomponents/Spinner/Spinner";
import Utility from "../../../Utils/Utility";

const Notification = (props) => {
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.userInfo._id);
  console.log("user Id", userId);
  const [allNotification, setAllNotification] = useState([]);
  const [loading, setLoading] = useState(false);

  const deleteSingleNotification = async (token, id) => {
    try {
      const resp = await UserController.deleteNotificationById(token, id);
      // console.log("Resp", resp);
      getNotification();
    } catch (error) {
      console.log("Error", error);
    }
  };

  const getNotification = async () => {
    try {
      setLoading(true);
      const res = await UserController.getAllNotification(token, userId);
      // console.log("RESP", res.data.data);
      setLoading(false);
      setAllNotification(res.data.data);
    } catch (error) {
      setLoading(false);
      console.log("Error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNotification();
  }, [token]);

  const deleteAllNotification = async () => {
    try {
      const res = await UserController.deleteAllNotificationById(token, userId);
      console.log("RESP", res);
      getNotification();
    } catch (error) {
      console.log("Error", error);
    }
  };

  console.log("Here is all notification ===========", allNotification);

  return (
    <>
      <Container className="p-2">
        <Modal id="transactionModal" {...props} size="lg">
          <Modal.Header closeButton style={{ background: "#F4F3F0" }}>
            <Modal.Title style={{ color: "black", background: "#F4F3F0" }}>
              {/* <IoNotifications /> */}
              <p>Notifications</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            {allNotification.length > 0 ? (
              <div className="all-button">
                <div className="twoButton">
                  <button
                    className="Button"
                    style={{ backgroundColor: "#F4F3F0", color: "black" }}
                  >
                    All
                  </button>
                  {/* <button className="Button">Unread(2)</button> */}
                </div>
                <button
                  disabled={allNotification.length === 0}
                  className="Button"
                  onClick={deleteAllNotification}
                  style={{ backgroundColor: "#dc2329", color: "white" }}
                >
                  Delete all{" "}
                </button>
              </div>
            ) : (
              ""
            )}

            {!loading ? (
              <div className="allNotification">
                {allNotification.length ? (
                  allNotification.map((notification, index) => (
                    <Row
                      className="Notification"
                      key={index}
                      style={{
                        backgroundColor: "#F4F3F0",
                      }}
                    >
                      <Col>
                        <Row>
                          <Col className="notificationCrossIcon">
                            <RxCrossCircled
                              style={{ height: "24px", width: "50px" }}
                              className="cross"
                              onClick={() =>
                                deleteSingleNotification(
                                  token,
                                  notification?._id
                                )
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div
                              className="Inventory"
                              style={{ color: "black", fontSize: "20px" }}
                            >
                              {notification.title}
                            </div>
                            <div>{notification.body}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="notificationDateCross">
                            <h6 style={{ width: "100px", padding: 0 }}>
                              {Utility.formatDate(notification.createdAt)}
                            </h6>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ))
                ) : (
                  <div className="notificationNotFound">
                    Notification not available
                  </div>
                )}
              </div>
            ) : (
              <div className="spinner">
                <CircularIndeterminate />
              </div>
            )}
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default Notification;
