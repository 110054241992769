import React from "react";
import InitialRenderer from "./navigations/InitialRenderer";
import { ToastContainer } from "react-toastify";

export default function App() {
  return (
    <>
      <InitialRenderer />
      <ToastContainer />
    </>
  );
}
