import React, { useState } from "react";
import { Row, Col, Modal, Container } from "react-bootstrap";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import { MdOutlineFileDownload } from "react-icons/md";
import "./TransactionInfoSales.css";
import { useNavigate } from "react-router-dom";
import { AiOutlineFilePdf } from "react-icons/ai";

const TransactionInfoSales = (props) => {
  const navigate = useNavigate();
  const handleForm = () => {
    navigate("/transactionform");
  };
  return (
    <Container className="p-1">
      <Modal id="transactionModal" {...props} size="lg">
        <Modal.Header closeButton style={{ background: "#E4CDAA" }}>
          <Modal.Title style={{ color: "#642315E5", background: "#E4CDAA" }}>
            Transaction Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* first row  */}
          <Row className="">
            <h6 className="transactionHeading">Transaction Details</h6>
            <Row className="mt-1">
              <Col>Transaction ID</Col>
              <Col className="value">TRX2024001</Col>
            </Row >
            <Row className="mt-1">
              <Col>Transaction Date</Col>
              <Col className="value">TRX2024001</Col>
            </Row>
            <Row className="mt-1">
              <Col>Transaction Type</Col>
              <Col className="value">TRX2024001</Col>
            </Row>
          </Row>

          {/* second row  */}
          <Row className="mt-1">
            <h6 className="transactionHeading">Product Details</h6>
            <Row className="mt-1">
              <Col>Items Name</Col>
              <Col className="value">Sofa Set</Col>
            </Row>
            <Row className="mt-1">
              <Col>Quantity</Col>
              <Col className="value">2 PCS</Col>
            </Row>
            <Row className="boldText mt-1">
              <Col>Rate</Col>
              <Col className="value">$12000</Col>
            </Row>
            <Row className="mt-1">
              <Col>Amount</Col>
              <Col className="value">$24000</Col>
            </Row>
            <Row className="mt-1">
              <Col>Transaction Amount</Col>
              <Col className="value">$12500</Col>
            </Row>
          </Row>
          <Row className="mt-1">
            <h6 className="transactionHeading">Amount Breakdown</h6>
            <Row className="boldText mt-1">
              <Col>Subtotal</Col>
              <Col className="value">$24000</Col>
            </Row>
            <Row className="mt-1">
              <Col>Discount</Col>
              <Col className="value">- $ 1,000</Col>
            </Row>
            <Row className="mt-1">
              <Col>Shipping Cost</Col>
              <Col className="value">$500</Col>
            </Row>
            <Row className="mt-1">
              <Col>Tax</Col>
              <Col className="value">$ 600 (5%)</Col>
            </Row>
            <Row className="boldText mt-1">
              <Col>Total</Col>
              <Col className="value">$24100</Col>
            </Row>
            <Row className="mt-1">
              <Col>Amount paid</Col>
              <Col className="value">$15000</Col>
            </Row>
            <hr id="dueMoney" ></hr>

            <Row className="boldText mt-1">
              <Col>Amount Due</Col>
              <Col className="value">$ 13,100</Col>
            </Row>
          </Row>

          <Row>
            <h6 className="transactionHeading">Payment Details</h6>
            <Row className="mt-1">
              <Col>Payment Method</Col>
              <Col className="value">UPI - Phonepe</Col>
            </Row>
            <Row className="mt-1">
              <Col>Transaction ID (if online payment )</Col>
              <Col className="value">ID0000012365478964640</Col>
            </Row>
            <Row className="mt-1">
              <Col>Payment Date</Col>
              <Col className="value">19 Jan 2024</Col>
            </Row>
          </Row>
          <Row>
            <h6 className="transactionHeading">Shipping Details</h6>
            <Row className="mt-1">
              <Col>Name</Col>
              <Col className="value">John D</Col>
            </Row>
            <Row className="mt-1">
              <Col>Phone Number</Col>
              <Col className="value">980923740</Col>
            </Row>
            <Row className="mt-1">
              <Col>Shipping Address</Col>
              <Col className="value">91 ,near HM Mall , CDA - 9 , Cuttack </Col>
            </Row>
            <Row className="mt-1">
              <Col>Billing Address</Col>
              <Col className="value">91 ,near HM Mall , CDA - 9 , Cuttack </Col>
            </Row>
            <Row className="boldText mt-1">
              <Col>Notes</Col>
              <Col className="value">Need Employee for Assembly. </Col>
            </Row>
            <Row className="subadminlastbutton">
              <GlobalButton
                name={"Delete"}
                fontFamily="Roboto"
                border={'1px solid #642315'}
                background={"#E4CDAA"}
                color={"#642315"}
                width={"3rem"}
              />
              <GlobalButton
                name={"Edit"}
                fontFamily="Roboto"
                border={'1px solid #642315'}
                background={"#E4CDAA"}
                color={"#642315"}
                width={"3rem"}
                onclick={handleForm}
              />
              <button id="downloadBtn"> 
               <AiOutlineFilePdf /> Download Invoice
              </button>
            </Row>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default TransactionInfoSales;
