import React, { useEffect, useState } from "react";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import "../EmployeeData/EmployeeData.css";
import { UserController } from "../../Redux/controllers/UserController.js";
import { useSelector } from "react-redux";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner.js";
import CustomerInformationModal from "../Modals/CustomerInformationModal/CustomerInformationModal.js";
import Utility from "../../Utils/Utility.js";
import { deleteProductItem } from "./../../Redux/controllers/UserController";
import QueryInfoModal from "../Modals/QueryInformationModal/QueryInfoModal.js";

const CustomerQueries = ({ page }) => {
  const [allQueries, setAllQueries] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState({});

  const handleView = (item) => {
    navigate("/query", { state: item });
  };

  const token = useSelector((state) => state.user.token);

  const getAllQueries = async () => {
    try {
      setLoading(true);
      const res = await UserController.getAllQueries(token);
      setAllQueries(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // console.log("All Queries", allQueries);

  useEffect(() => {
    getAllQueries();
  }, []);

  const columns = [
    {
      accessorKey: "slNo",
      header: "Date",
      size: 30,
    },
    {
      accessorKey: "name",
      header: "Client Name",
      size: 50,
    },
    // {
    //   accessorKey: "email",
    //   header: "Client Email",
    //   size: 100,
    // },
    {
      accessorKey: "interestedProduct",
      header: "Interested Product",
      size: 50,
    },

    {
      accessorKey: "status",
      header: "Status",
      size: 50,
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 50,
    },
  ];

  const data = allQueries?.map((item, index) => ({
    slNo: Utility.formatDate(item?.createdAt),
    name: item?.name || "N/A",
    email: item?.email || "N/A",
    phoneNo: item?.phoneNumber || "N/A",
    interestedProduct: item?.interestedProduct || "N/A",
    status: (
      <GlobalButton
        disabledBgColor={Utility.getStatusColor(item?.isFollowedOn)}
        disabled={true}
        name={item?.isFollowedOn ? "Followed" : "Pending"}
        width="4rem"
        padding="1.5px"
        color="#fff"
        fontsize="10px"
      />
    ),
    action: (
      <div className="d-flex gap-2">
        {" "}
        <GlobalButton
          name={"View"}
          background={"#dc2923"}
          color={"#fff"}
          padding={"1.5px"}
          fontsize="10px"
          // width="1rem"
          // hoverColor={"#fff"}
          onclick={() => handleView(item)}
        />
        {/* <GlobalButton
          name={"Action"}
          background={"#E4CDAA"}
          color={"#642315"}
          // padding={"0"}
          fontsize="12px"
          // width="2rem"
          hoverColor={"#fff"}
          onclick={() => handleView(item)}
        /> */}
      </div>
    ),
  }));

  return (
    <>
      <div style={{ position: "relative" }}>
        <span
          style={{
            color: "#642315",
            marginLeft: "10px",
            position: "absolute",
            marginTop: "15px",
            zIndex: "10",
          }}
        >
          <strong
            style={{
              color: "#251C1A",
            }}
          >
            All Queries
          </strong>
        </span>
        <Row>
          {loading ? (
            <div className="spinnerrowitem">
              <CircularIndeterminate />
            </div>
          ) : (
            <DataTable columns={columns} data={data} page={page} />
          )}
        </Row>
      </div>
    </>
  );
};

export default CustomerQueries;
