import React, { useEffect, useState } from "react";
import "./Saleryform.css";
import { Container, Row, Col } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { Table } from "react-bootstrap";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import tablelogo from "../../assets/dashboard/tablelogo.png";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { IoMdEye } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { UserController } from "../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import Utility from "../../Utils/Utility";
import { HttpClient } from "../../Redux/controllers/HttpClient";
const Saleryform = (props) => {
  const [AttendanceData, setAttendanceData] = useState([]);
  const [SaletyDetails, setSaletyDetails] = useState([]);

  const navigate = useNavigate();

  const { selectedData } = props;

  // console.log(
  //   "Data  of salery form page====;[[[[[[}}}}}}}}}}}}}}}}}}}}}}",
  //   selectedData
  // );
  const token = useSelector((state) => state.user.token);
  const employe_id = selectedData?._id;

  const getAllAttendanceData = async () => {
    try {
      const response = await UserController.getAttendanceById(
        token,
        employe_id
      );
      setAttendanceData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleclick = () => {
    navigate(`/edit-subadmin/${employe_id}`);
  };

  const deleteUser = async () => {
    console.log("first");
    const confirmation = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (!confirmation) return;

    try {
      await HttpClient.deleteApicaller(`auth/delete-user/${employe_id}`, token);

      props.onclose();
      alert("User deleted successfully");
    } catch (error) {
      console.error(error);
      alert("Failed to delete user");
    }
  };

  const getSaleryDeytails = async () => {
    try {
      const response = await UserController.getSaleryDetails(token, employe_id);
      console.log(response.data.data);
      setSaletyDetails(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log("Here is the salery Details ++++++++ ", SaletyDetails);

  useEffect(() => {
    getAllAttendanceData();
    getSaleryDeytails();
  }, []);

  // salery history table
  const columns = [
    {
      accessorKey: "slno",
      header: "Sl No",
      size: 100,
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 100,
    },
    {
      accessorKey: "basicsalary",
      header: "Basic Salary",
      size: 100,
    },
    {
      accessorKey: "allowance",
      header: "Allowance",
      size: 100,
    },
    {
      accessorKey: "overtimePay",
      header: "Overtime Pay",
      size: 100,
    },
    {
      accessorKey: "totalearning",
      header: "Total Earning",
      size: 100,
    },

    {
      accessorKey: "action",
      header: "Action",
      size: 100,
    },
  ];

  const handleDownload = (url) => {
    window.open(url, "_blank");
  };

  const data = SaletyDetails.map((item, index) => ({
    slno: index + 1,
    date: Utility.formatDate(item?.createdAt),
    allowance: Utility.MoneyFormatter(item?.allowances) || "N/A",
    basicsalary: Utility.MoneyFormatter(item?.basicSalary) || "N/A",
    overtimePay: Utility.MoneyFormatter(item?.overTimePay) || "N/A",
    totalearning: Utility.MoneyFormatter(item?.totalEarning) || "N/A",
    action: (
      <GlobalButton
        background={"#dc2329"}
        name={"Download"}
        color={"#fff"}
        fontFamily="Roboto"
        width={"80px"}
        onclick={() => handleDownload(item.slip)}
      />
    ),
  }));

  //Attendance

  const AttendanceColomn = [
    {
      accessorKey: "slno",
      header: "Sl No",
      size: 100,
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 100,
    },
    {
      accessorKey: "checkin",
      header: "Check In",
      size: 100,
    },
    {
      accessorKey: "checkout",
      header: "Check Out",
      size: 100,
    },
    {
      accessorKey: "working",
      header: "Working Hour",
      size: 100,
    },
    {
      accessorKey: "gps",
      header: "GPS",
      size: 100,
    },
    {
      accessorKey: "pht",
      header: "Photos",
      size: 100,
    },
  ];

  const calculateWorkingHours = (checkIn, checkOut) => {
    const [checkInDate, checkInTime] = checkIn.split(", ");
    const [checkOutDate, checkOutTime] = checkOut.split(", ");

    const checkInDateTime = new Date(`${checkInDate} ${checkInTime}`);
    const checkOutDateTime = new Date(`${checkOutDate} ${checkOutTime}`);

    const diffMs = checkOutDateTime - checkInDateTime;
    const diffHrs = Math.floor(diffMs / 3600000);
    const diffMins = Math.round((diffMs % 3600000) / 60000);

    return `${diffHrs} hours ${diffMins} mins`;
  };

  const ViwedAttendanceData = AttendanceData.map((item, index) => ({
    slno: index + 1,
    date: item?.checkInTime.split(",")[0] || "N/A",
    checkin: item?.checkInTime.split(",")[1] || "N/A",
    checkout: item?.checkOutTime.split(",")[1] || "N/A",
    working: !item?.checkOutTime
      ? "working"
      : calculateWorkingHours(item.checkInTime, item.checkOutTime),
    gps: item.checkInLocation,
    pht: <img src={item.checkInImage} alt="pht" className="attendance-photo" />,
  }));

  const convertDate = (date) => {
    const convertdDate = new Date(date).toDateString();
    return convertdDate;
  };
  // color: "#642315E5", background: "#f4f3f0"
  return (
    <Container className="salerymaincontainer">
      <Row className="amtopbarsalery">
        <Col className="colnameuser" style={{ color: "black" }}>
          Employee Information
        </Col>

        <Col className="amsecondcol" onClick={() => props.onclose()}>
          <RxCross2 />
        </Col>
      </Row>

      <Row>
        <Col className="amproductdetails" style={{ color: "black" }}>
          Personal Information
        </Col>
      </Row>

      <Row>
        <Col className="straightline"></Col>
      </Row>

      <Row className="saleryrow">
        <Row sm={12} className="">
          <Col className="texttopcontainer">Full Name</Col>
          <Col className="textalignment">{selectedData.name || "N/A"}</Col>
        </Row>
        <Row sm={12} className="">
          <Col className="texttopcontainer">Phone Number</Col>
          <Col className="textalignment">{selectedData.phoneNo || "N/A"}</Col>
        </Row>
        <Row sm={12} className="">
          <Col className="texttopcontainer">Email Adress</Col>
          <Col className="textalignment">{selectedData.email || "N/A"}</Col>
        </Row>
        <Row sm={12} className=" ">
          <Col className="texttopcontainer">DOB</Col>
          <Col className="textalignment">
            {Utility.formatDate(selectedData.dob) || "N/A"}
          </Col>
        </Row>
        <Row sm={12} className="">
          <Col className="texttopcontainer">Address</Col>
          <Col className="textalignment">{selectedData?.address || "N/A"}</Col>
        </Row>
      </Row>

      <Row>
        <Col className="amproductdetails" style={{ color: "black" }}>
          Employment Details
        </Col>
      </Row>

      <Row>
        <Col className="straightline"></Col>
      </Row>

      <Row className="saleryrow">
        {/* <Row sm={12} className=" ">
          <Col className="texttopcontainer">Position</Col>
          <Col className="textalignment">Head Furniture desiner</Col>
        </Row> */}
        <Row sm={12} className=" ">
          <Col className="texttopcontainer">Employee ID</Col>
          <Col className="textalignment">
            {selectedData?.empEmployeeID || "N/A"}
          </Col>
        </Row>
        {/* <Row sm={12} className="">
          <Col className="texttopcontainer">
            Transaction Party Mobile Number
          </Col>
          <Col className="textalignment">8909303892</Col>
        </Row> */}
        <Row sm={12} className=" ">
          <Col className="texttopcontainer">Date of Hire</Col>
          <Col className="textalignment">
            {Utility.formatDate(selectedData?.empDateOfHire) || "N/A"}
          </Col>
        </Row>
        <Row sm={12} className=" ">
          <Col className="texttopcontainer">Department</Col>
          <Col className="textalignment">
            {Utility.getDepartment(selectedData?.empDepartment) || "N/A"}
          </Col>
        </Row>
      </Row>

      <Row>
        <Col className="amproductdetails" style={{ color: "black" }}>
          Security Information
        </Col>
      </Row>

      <Row>
        <Col className="straightline"></Col>
      </Row>

      <Row className="saleryrow">
        <Row sm={12} className=" ">
          <Col className="texttopcontainer">Username</Col>
          <Col className="textalignment">
            {selectedData?.empUserName || "N/A"}
          </Col>
        </Row>
        <Row sm={12} className=" ">
          <Col className="texttopcontainer">Password</Col>
          <Col className="textalignment">########</Col>
        </Row>
        {/* <Row id="managepass">
          <div sm={2} xss={2} className="managepassword">
            <IoMdEye /> Manage Password
          </div>
        </Row> */}
      </Row>

      <Row>
        <Col className="amproductdetails" style={{ color: "black" }}>
          Salary Details
        </Col>
      </Row>

      <Row>
        <Col className="straightline"></Col>
      </Row>

      <Row className="saleryrow">
        <Row sm={12} className=" ">
          <Col className="texttopcontainer">Salary Amount</Col>
          <Col className="textalignment">
            {Utility.MoneyFormatter(selectedData?.empSalaryPerFrequency)}
            {/* {" "}
            ₹ {selectedData?.empTotalSalaryPerFrequency || "N/A"}{" "} */}
          </Col>
        </Row>
        <Row sm={12} className=" ">
          <Col className="texttopcontainer">Pay Frequency</Col>
          <Col className="textalignment">
            {" "}
            {selectedData?.empPayFrequency?.charAt(0).toUpperCase() +
              selectedData?.empPayFrequency?.slice(1) || "N/A"}
          </Col>
        </Row>
        <Row sm={12} className=" ">
          <Col className="texttopcontainer">Payment Method</Col>
          <Col className="textalignment">
            {Utility.getPaymentMethod(selectedData?.empPaymentMethod)}
          </Col>
        </Row>
      </Row>

      {selectedData?.empPaymentMethod === "bank_transfer_or_upi" && (
        <>
          <Row>
            <Col className="amproductdetails" style={{ color: "black" }}>
              Bank Details
            </Col>
          </Row>

          <Row>
            <Col className="straightline"></Col>
          </Row>

          <Row className="saleryrow">
            <Row sm={12} className=" ">
              <Col className="texttopcontainer">Bank Name</Col>
              <Col className="textalignment">
                {selectedData?.empBankName || "N/A"}
              </Col>
            </Row>
            <Row sm={12} className=" ">
              <Col className="texttopcontainer">Account Number</Col>
              <Col className="textalignment">
                {selectedData?.empAccountNo || "N/A"}
              </Col>
            </Row>
            <Row sm={12} className=" ">
              <Col className="texttopcontainer">IFCS Code</Col>
              <Col className="textalignment">
                {selectedData?.empIFSC || "N/A"}
              </Col>
            </Row>
          </Row>
        </>
      )}

      <Row>
        <Col className="amproductdetails" style={{ color: "black" }}>
          Salary History
        </Col>
      </Row>

      <Row className="tablepaddinginner">
        <DataTable columns={columns} data={data} />
      </Row>

      <Row>
        <Col className="amproductdetails" style={{ color: "black" }}>
          Attendance
        </Col>
      </Row>

      <Row className="tablepaddinginner">
        <DataTable columns={AttendanceColomn} data={ViwedAttendanceData} />
      </Row>

      {/* style come's from subadminform css  */}
      <Row className="subadminlastbutton">
        <GlobalButton
          name={"Delete"}
          paddingTop={"0.4rem"}
          paddingBottom={"0.4rem"}
          paddingLeft={"1rem"}
          paddingRight={"1rem"}
          fontFamily="Roboto"
          background={"#dc2329"}
          color={"#fff"}
          width={"3rem"}
          onclick={deleteUser}
        />
        <GlobalButton
          name={"Edit"}
          paddingTop={"0.4rem"}
          paddingBottom={"0.4rem"}
          paddingLeft={"1rem"}
          paddingRight={"1rem"}
          onclick={handleclick}
          fontFamily="Roboto"
          background={"#dc2329"}
          color={"#fff"}
          width={"3rem"}
        />
      </Row>
    </Container>
  );
};

export default Saleryform;
