import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Utility from "../../../Utils/Utility";
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from "../../../../globalcomponents/DataTable/DataTable";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import { UserController } from "../../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import CircularIndeterminate from "../../../../globalcomponents/Spinner/Spinner";
import { RxCross2 } from "react-icons/rx";
import { FaUserPlus } from "react-icons/fa6";
import AssignEmployeeModal from "./AssignEmployeeModal";

const QueryInfoModal = (props) => {
  const { token } = useSelector((st) => st?.user);
  const location = useLocation();
  const queryId = location?.state?._id;
  const [selectedQuery, setSelectedQuery] = useState({});
  const [loadingMap, setLoadingMap] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const navigate = useNavigate();

  const getQuery = async () => {
    try {
      setRefresh(false);
      setPageLoading(true);
      const { data } = await UserController.getQueryByID(token, queryId);
      setSelectedQuery(data?.data);
    } catch (error) {
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    getQuery();
  }, [refresh]);

  const handleRevoke = async (item) => {
    try {
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        [item._id]: true,
      }));

      let revokeData = {
        action: "revoke",
        employeeIds: [item?.employee?._id],
        queryId: selectedQuery?._id,
      };

      const { data } = await UserController.grantOrRevokeQueryAccess(
        token,
        revokeData
      );
      Utility.sToast(data?.message);
      setRefresh(true);
    } catch (error) {
      Utility?.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setLoadingMap((prevLoadingMap) => ({
        ...prevLoadingMap,
        [item._id]: false,
      }));
    }
  };

  const columns = [
    {
      accessorKey: "slNo",
      header: "Sl No",
      size: 30,
    },
    {
      accessorKey: "name",
      header: "Employee Name",
      size: 100,
    },
    {
      accessorKey: "email",
      header: "Employee Email",
      size: 100,
    },
    {
      accessorKey: "assignedOn",
      header: "Assigned On",
      size: 100,
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 50,
    },
  ];

  const data = selectedQuery?.assignedEmployees?.map((item, index) => ({
    slNo: index + 1,
    name: item?.employee?.name || "N/A",
    email: item?.employee?.email || "N/A",
    assignedOn: Utility?.formatDate(item?.assignedOn) || "N/A",
    action: (
      <>
        {loadingMap[item?._id] ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <GlobalButton
            name={"Revoke"}
            background={"#E4CDAA"}
            color={"#642315"}
            padding={"1.5px"}
            fontsize="12px"
            hoverColor={"#fff"}
            onclick={() => handleRevoke(item)}
            disabled={loadingMap[item._id]}
          />
        )}
      </>
    ),
  }));

  console.log("selectedQuery", selectedQuery);

  const [isKeyFeaturesOpen, setIsKeyFeaturesOpen] = useState(false);

  const toggleKeyFeatures = () => {
    setIsKeyFeaturesOpen(!isKeyFeaturesOpen);
  };

  // const toggleKeyFeatures = (index) => {
  //   setIsKeyFeaturesOpen((prev) => {
  //     const newState = [...prev];
  //     newState[index] = !newState[index];
  //     return newState
  //   });
  // };

  const [isNotesOpen, setIsNotesOpen] = useState([]);

  const toggleNotes = (index) => {
    setIsNotesOpen((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <>
      {showAssignModal && (
        <AssignEmployeeModal
          show={showAssignModal}
          onHide={() => {
            setShowAssignModal(false);
            setRefresh(true);
          }}
          selectedQuery={selectedQuery}
        />
      )}
      <Container
        size="lg"
        className="mt-3 "
        style={{ background: "white", padding: "20px" }}
      >
        <Row
          closeButton
          style={{ background: "#f4f3f0", padding: "20px" }}
          className="mb-3 p-2 rounded-2 fw-bold "
        >
          <Col
            style={{ color: "#642315E5", background: "#f4f3f0" }}
            className="query-col"
          >
            Query Information
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <RxCross2
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
            />
          </Col>
        </Row>
        {pageLoading ? (
          <div className="d-flex justify-content-center align-items-center mt-lg-5">
            <CircularIndeterminate />
          </div>
        ) : (
          <>
            <Row className="mt-1">
              <Col>Name</Col>
              <Col className="value">{selectedQuery?.name || "N/A"}</Col>
            </Row>
            <Row className="mt-1">
              <Col>Mobile No</Col>
              <Col className="value">{selectedQuery?.phoneNumber || "--"}</Col>
            </Row>
            <Row className="mt-1">
              <Col>Email</Col>
              <Col className="value">{selectedQuery?.email || "--"}</Col>
            </Row>
            <Row className="mt-1">
              <Col>Interested Product</Col>
              <Col className="value">
                {selectedQuery?.interestedProduct || "--"}
              </Col>
            </Row>

            <Row className="mt-3 mb-1">
              <Col>Message</Col>
              <Col className="value">
                {(
                  <>
                    <GlobalButton
                      name={isKeyFeaturesOpen ? "Close" : "View"}
                      background={"#dc2923"}
                      color={"#fff"}
                      padding={"0"}
                      onclick={toggleKeyFeatures}
                    />
                  </>
                ) || "N/A"}
              </Col>
              <Col sm={12} xs={12}>
                <div
                  className={`key-features-slider ${
                    isKeyFeaturesOpen ? "open" : ""
                  }`}
                >
                  <div />
                  <h6 className="descriptionparser">
                    {selectedQuery?.message || "--"}
                  </h6>
                </div>
              </Col>
            </Row>

            <div id="orderHistory" className="mt-1">
              <h6>Assigned Employees</h6>
            </div>
            {selectedQuery?.assignedEmployees?.length > 0 ? (
              <Row>
                <DataTable columns={columns} data={data} />
              </Row>
            ) : (
              <Row className="mt-1 d-flex align-items-center justify-content-center">
                No Employee has been assigned yet
              </Row>
            )}
            <Row>
              <Col className="d-flex w-100 justify-content-end mt-3">
                <GlobalButton
                  icon={<FaUserPlus />}
                  name={"Assign New Employee"}
                  background={"#dc2923"}
                  color={"#fff"}
                  padding={"5px"}
                  onclick={() => {
                    setShowAssignModal(true);
                  }}
                />
              </Col>
            </Row>

            <div id="orderHistory" className="mt-1">
              <h6>Follow On Details</h6>
            </div>
            {selectedQuery?.followedBy?.length > 0 ? (
              selectedQuery.followedBy.map((followed, index) => (
                <React.Fragment key={index}>
                  <Row className="mt-1">
                    <Col>Followed On</Col>
                    <Col className="value">
                      {Utility.formatDate(followed?.followedOn)}
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col>Employee Name</Col>
                    <Col className="value">
                      {followed?.employee?.name || "--"}
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col>Employee Email</Col>
                    <Col className="value">
                      {followed?.employee?.email || "--"}
                    </Col>
                  </Row>

                  <Row className="mt-3 mb-1">
                    <Col>Notes</Col>
                    <Col className="value">
                      {(
                        <>
                          <GlobalButton
                            name={isNotesOpen[index] ? "Close" : "View"}
                            background={"#E4CDAA"}
                            color={"#642315"}
                            padding={"0"}
                            onclick={() => toggleNotes(index)}
                          />
                        </>
                      ) || "N/A"}
                    </Col>
                    <Col sm={12} xs={12}>
                      <div
                        className={`key-features-slider ${
                          isNotesOpen[index] ? "open" : ""
                        }`}
                      >
                        <div />
                        <h6>{followed?.notes || "--"}</h6>
                      </div>
                    </Col>
                  </Row>
                  <hr className="my-3" />
                </React.Fragment>
              ))
            ) : (
              <Row className="mt-1 d-flex align-items-center justify-content-center">
                Not followed yet
              </Row>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default QueryInfoModal;
