import React from "react";
import "./AboutUs.css";

export default function AboutUs() {
  return (
    <div>
      <span>Hello about</span>
    </div>
  );
}
