import React from "react";
import { Row, Tab, Tabs } from "react-bootstrap";
import TransactionExpense from "../TransactionExpense/TransactionExpense";
import TransactionSales from "../TransactionSales/TransactionSales";
import "./Financials.css";
const Financials = () => {
  return (
    <Row>
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        
      >
        <Tab eventKey="home" title="Expenses Data" className="expenseTab">
          <TransactionExpense />
        </Tab>
        <Tab eventKey="profile" title="Sales Data" id="salesTab">
          <TransactionSales />
        </Tab>
      </Tabs>
    </Row>
  );
};

export default Financials;
