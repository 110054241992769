import { useEffect, useState } from "react";
import "./SideBarMenu.css";
import { Col, Image, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import mmlogo from "../../Admin/assets/dashboard/mmlogo.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdTask } from "react-icons/md";
import { MdArrowForwardIos, MdClose } from "react-icons/md";
import logo from "../../Admin/assets/dashboard/logo.png";
import { IoStatsChartOutline } from "react-icons/io5";
import { IoMdArrowDropright } from "react-icons/io";
import { MdDashboard } from "react-icons/md";
import { BsPeople } from "react-icons/bs";
import TopNavBar from "../TopNavBar/TopNavBar";
import { ImParagraphRight } from "react-icons/im";
import { GoOrganization } from "react-icons/go";

function SideBarMenu() {
  const expand = "md";
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const handleClose = () => setShowOffcanvas(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  const [active, setActive] = useState("");

  const location = useLocation();

  useEffect(() => {
    setActive(location.pathname);
  }, [location.pathname]);

  const navigate = useNavigate();

  return (
    <>
      {/* <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3"> */}
      <Navbar key={expand} expand={expand}>
        <Container fluid>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-${expand}`}
            onClick={() => setShowOffcanvas(true)}
          />
          {screenWidth > 599.95 ? null : (
            <Navbar.Brand>
              {/* <Image
                src={logo}
                alt="logo"
                className="sbm1"
                onClick={() => navigate("/")}
              /> */}
              <TopNavBar />
            </Navbar.Brand>
          )}

          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="start"
            show={showOffcanvas}
            // onHide={handleClose}
            className="sbm3"
          >
            <Offcanvas.Header>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <Image
                  src={logo}
                  alt="logo"
                  className="sbm2"
                  onClick={() => {
                    navigate("/");
                    handleClose();
                  }}
                />
              </Offcanvas.Title>
              <span>
                <MdClose
                  onClick={handleClose}
                  style={{
                    height: "1.2rem",
                    width: "1.2rem",
                  }}
                />
              </span>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Container className="sbm15">
                {screenWidth < 599.95 ? null : (
                  <Row className="sbm17">
                    <Col className="mmlogo">
                      <Image
                        src={mmlogo}
                        alt="logo"
                        className="sbm18"
                        onClick={() => navigate("/")}
                      />
                      <h5 className="jbsContained"> JBS Interior</h5>
                      <div className="panel-name">Admin</div>
                    </Col>
                  </Row>
                )}
                <Row className="sbm14">
                  <Row
                    as={Link}
                    to="/"
                    onClick={() => {
                      handleClose();
                    }}
                    className={active === "/" ? "sbm6" : "sbm4"}
                  >
                    <Col
                      xxs={10}
                      xs={10}
                      sm={10}
                      md={10}
                      lg={10}
                      xl={10}
                      xxl={10}
                      className="sbm9"
                    >
                      <span className="sbm5">
                        <MdDashboard
                          className={active === "/" ? "sbm12" : "sbm11"}
                        />
                      </span>
                      <span
                        className={
                          active === "/"
                            ? "dashboardTextActive"
                            : "dashboardText"
                        }
                      >
                        Dashboard
                      </span>
                    </Col>
                    <Col
                      xxs={2}
                      sm={2}
                      xs={2}
                      md={2}
                      lg={2}
                      xl={2}
                      xxl={2}
                      className="sbm16"
                    >
                      <span xl={2}>
                        {active === "/" ? (
                          <IoMdArrowDropright className="sbm7" />
                        ) : (
                          <MdArrowForwardIos className="sbm8" />
                        )}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    as={Link}
                    to="/employeedata"
                    onClick={() => {
                      handleClose();
                    }}
                    className={active === "/employeedata" ? "sbm6" : "sbm4"}
                  >
                    <Col
                      xxs={10}
                      xs={10}
                      sm={10}
                      md={10}
                      lg={10}
                      xl={10}
                      xxl={10}
                      className="sbm9"
                    >
                      <span className="sbm5">
                        <BsPeople
                          className={active === "inbox" ? "sbm12" : "sbm11"}
                        />
                      </span>
                      <span
                        className={
                          active === "/employeedata" ? "EmpActive" : "EmpText"
                        }
                      >
                        Employee Data
                      </span>
                    </Col>
                    <Col
                      xxs={2}
                      sm={2}
                      xs={2}
                      md={2}
                      lg={2}
                      xl={2}
                      xxl={2}
                      className="sbm16"
                    >
                      <span>
                        {active === "/employeedata" ? (
                          <IoMdArrowDropright className="sbm7" />
                        ) : (
                          <MdArrowForwardIos className="sbm8" />
                        )}
                      </span>
                    </Col>
                  </Row>

                  <Row
                    as={Link}
                    to="/customerdata"
                    onClick={() => {
                      handleClose();
                    }}
                    className={active === "/customerdata" ? "sbm6" : "sbm4"}
                  >
                    <Col
                      xxs={10}
                      xs={10}
                      sm={10}
                      md={10}
                      lg={10}
                      xl={10}
                      xxl={10}
                      className="sbm9"
                    >
                      <span className="sbm5">
                        <IoStatsChartOutline
                          className={
                            active === "/customerdata" ? "sbm12" : "sbm11"
                          }
                        />
                      </span>
                      <span
                        className={
                          active === "/customerdata" ? "customerDataActive" : ""
                        }
                      >
                        Customer Data
                      </span>
                    </Col>
                    <Col
                      xxs={2}
                      sm={2}
                      xs={2}
                      md={2}
                      lg={2}
                      xl={2}
                      xxl={2}
                      className="sbm16"
                    >
                      <span>
                        {active === "/customerdata" ? (
                          <IoMdArrowDropright className="sbm7" />
                        ) : (
                          <MdArrowForwardIos className="sbm8" />
                        )}
                      </span>
                    </Col>
                  </Row>

                  <Row
                    as={Link}
                    to="/orderhistory"
                    onClick={() => {
                      handleClose();
                    }}
                    className={active === "/orderhistory" ? "sbm6" : "sbm4"}
                  >
                    <Col
                      xxs={10}
                      xs={10}
                      sm={10}
                      md={10}
                      lg={10}
                      xl={10}
                      xxl={10}
                      className="sbm9"
                    >
                      <span className="sbm5">
                        <ImParagraphRight
                          className={
                            active === "/orderhistory" ? "sbm12" : "sbm11"
                          }
                        />
                      </span>
                      <span
                        className={
                          active === "/orderhistory" ? "OrderActive" : ""
                        }
                      >
                        Order History
                      </span>
                    </Col>
                    <Col
                      xxs={2}
                      sm={2}
                      xs={2}
                      md={2}
                      lg={2}
                      xl={2}
                      xxl={2}
                      className="sbm16"
                    >
                      <span>
                        {active === "/orderhistory" ? (
                          <IoMdArrowDropright className="sbm7" />
                        ) : (
                          <MdArrowForwardIos className="sbm8" />
                        )}
                      </span>
                    </Col>
                  </Row>

                  <Row
                    as={Link}
                    to="/items"
                    onClick={() => {
                      handleClose();
                    }}
                    className={active === "/items" ? "sbm6" : "sbm4"}
                  >
                    <Col
                      xxs={10}
                      xs={10}
                      sm={10}
                      md={10}
                      lg={10}
                      xl={10}
                      xxl={10}
                      className="sbm9"
                    >
                      <span className="sbm5">
                        <GoOrganization
                          className={active === "/items" ? "sbm12" : "sbm11"}
                        />
                      </span>
                      <span className={active === "/items" ? "itemActive" : ""}>
                        Items
                      </span>
                    </Col>
                    <Col
                      xxs={2}
                      sm={2}
                      xs={2}
                      md={2}
                      lg={2}
                      xl={2}
                      xxl={2}
                      className="sbm16"
                    >
                      <span>
                        {active === "/items" ? (
                          <IoMdArrowDropright className="sbm7" />
                        ) : (
                          <MdArrowForwardIos className="sbm8" />
                        )}
                      </span>
                    </Col>
                  </Row>

                  <Row
                    as={Link}
                    to="/others"
                    onClick={() => {
                      handleClose();
                    }}
                    className={active === "/others" ? "sbm6" : "sbm4"}
                  >
                    <Col
                      xxs={10}
                      xs={10}
                      sm={10}
                      md={10}
                      lg={10}
                      xl={10}
                      xxl={10}
                      className="sbm9"
                    >
                      <span className="sbm5">
                        <MdTask
                          className={active === "/others" ? "sbm12" : "sbm11"}
                        />
                      </span>
                      <span
                        className={active === "/others" ? "otherActive" : ""}
                      >
                        Others
                      </span>
                    </Col>
                    <Col
                      xxs={2}
                      sm={2}
                      xs={2}
                      md={2}
                      lg={2}
                      xl={2}
                      xxl={2}
                      className="sbm16"
                    >
                      <span>
                        {active === "/others" ? (
                          <IoMdArrowDropright className="sbm7" />
                        ) : (
                          <MdArrowForwardIos className="sbm8" />
                        )}
                      </span>
                    </Col>
                  </Row>
                  <Row
                    as={Link}
                    to="/alltasks"
                    onClick={() => {
                      handleClose();
                    }}
                    className={active === "/alltasks" ? "sbm6" : "sbm4"}
                  >
                    <Col
                      xxs={10}
                      xs={10}
                      sm={10}
                      md={10}
                      lg={10}
                      xl={10}
                      xxl={10}
                      className="sbm9"
                    >
                      <span className="sbm5">
                        <MdTask
                          className={active === "/alltasks" ? "sbm12" : "sbm11"}
                        />
                      </span>
                      <span
                        className={active === "/alltasks" ? "otherActive" : ""}
                      >
                       All Tasks
                      </span>
                    </Col>
                    <Col
                      xxs={2}
                      sm={2}
                      xs={2}
                      md={2}
                      lg={2}
                      xl={2}
                      xxl={2}
                      className="sbm16"
                    >
                      <span>
                        {active === "/alltasks" ? (
                          <IoMdArrowDropright className="sbm7" />
                        ) : (
                          <MdArrowForwardIos className="sbm8" />
                        )}
                      </span>
                    </Col>
                  </Row>
                </Row>
              </Container>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default SideBarMenu;
