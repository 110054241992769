import React from "react";
import "./TransactionForm.css";
import { Container, Row, Col } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import Form from "react-bootstrap/Form";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { useNavigate } from "react-router-dom";
const TransactionForm = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <Container id="transactionForm">
      <Row className="amtopbar">
        <Col id="formTransactionHead">Transaction Details</Col>
        <Col className="amsecondcol">
          <RxCross2 onClick={handleGoBack} id="formCross"/>
        </Col>
      </Row>
      <Form className="mt-2 pb-2">
        <Row className="mt-2">
          <Col md={6}>
            <label>Transaction ID</label>
            <br />
            <Form.Control
              placeholder="Enter Transaction ID"
              className="amthirdcol"
            />
          </Col>
          <Col md={6}>
            <label>Transaction Date</label>
            <br />
            <Form.Control
              type="date"
              placeholder="dd/mm/yyyy"
              className="amthirdcol"
            />
          </Col>
        </Row>
        <div className="mt-2">
          <label>Transaction Type</label>
          <div key={`inline-radio`} className=" mb-3 mt-2 radioBtns">
            <Form.Check
              inline
              label="Purchase:"
              name="group1"
              type="radio"
              id={`inline-radio-1`}
            />
            <Form.Check
              inline
              label="Sale:"
              name="group1"
              type="radio"
              id={`inline-radio-2`}
            />
            <Form.Check
              inline
              // disabled
              label="Return/Refund:"
              type="radio"
              id={`inline-radio-3`}
            />
            <Form.Check
              inline
              // disabled
              label="Others"
              type="radio"
              id={`inline-radio-3`}
            />
          </div>
        </div>

        <Form.Group className="mb-2" controlId="formGroupEmail">
          <Form.Label>Transaction Description</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter Transaction Description"
            className="amthirdcol"
          />
        </Form.Group>

        <Row className="mt-3">
          <Col md={6}>
            <label>Transaction Party</label>
            <br />
            <Form.Control
              placeholder="Name of Customer/Supplier"
              className="amthirdcol"
            />
          </Col>
          <Col md={6}>
            <label>Transaction Party Email Address</label>
            <br />
            <Form.Control
              type="email"
              placeholder="Enter the Customer/Supplier Email"
              className="amthirdcol"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            <label>Transaction Amount</label>
            <br />
            <Form.Control placeholder="Enter Amount" className="amthirdcol" />
          </Col>
          <Col md={6}>
            <label>Payment Method</label>
            <br />
            <Form.Control
              type="text"
              placeholder="Enter the Payment Method"
              className="amthirdcol"
            />
          </Col>
        </Row>
        <Row>
          <Col className="amproductdetails">Product Details</Col>
        </Row>

        <Row className="mt-2">
          <Col md={6}>
            <label>Product Name</label>
            <br />
            <Form.Control
              placeholder="Name of Product"
              className="amthirdcol"
            />
          </Col>
          <Col md={6}>
            <label>Quantity</label>
            <br />
            <Form.Control
              type="text"
              placeholder="Quantity Sold/Purchased"
              className="amthirdcol"
            />
          </Col>
        </Row>

        <Row className="mt-2">
          <Col md={6}>
            <label>Price per Unit</label>
            <br />
            <Form.Control
              type="text"
              placeholder="$25000.0"
              className="amthirdcol"
            />
          </Col>

          <Col md={6}>
            <label>Total Price</label>
            <br />
            <Form.Control
              type="text"
              placeholder="Total Price for Product"
              className="amthirdcol"
            />
          </Col>
        </Row>

        <Row>
          <Col className="amproductdetails">
            <label>Additional Charges (if applicable)</label>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col md={6}>
            <label>Tax</label>
            <br />
            <Form.Control
              placeholder="Enter Tax Amount"
              className="amthirdcol"
            />
          </Col>
          <Col md={6}>
            <label>Shipping Fee</label>
            <br />
            <Form.Control
              type="text"
              placeholder="Shipping Fee"
              className="amthirdcol"
            />
          </Col>
        </Row>

        <Row className="mt-2">
          <Col md={6}>
            <label>Discounts</label>
            <br />
            <Form.Control
              placeholder="Enter Discounts Amount"
              className="amthirdcol"
            />
          </Col>
          <Col></Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <label>Discounts</label>
            <br />
            <Form.Control
              placeholder="Total Transaction Amount"
              className="amthirdcol"
            />
          </Col>
        </Row>

        <Row>
          <Col className="amproductdetails">Payment Details</Col>
        </Row>

        <Row className="mt-2 mb-1">
          <Col md={6}>
            <label>Payment Status</label>
            <br />
            <Form.Control
              placeholder="Enter Payment Status"
              className="amthirdcol"
            />
          </Col>
          <Col md={6}>
            <label>Transaction Reference/ID</label>
            <br />
            <Form.Control
              type="text"
              placeholder="Payment Transaction ID"
              className="amthirdcol"
            />
          </Col>
        </Row>

        <Form.Group className="mb-3 mt-2" controlId="formGroupEmail">
          <Form.Label>Shipping Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Customer's Shipping Address"
            className="amthirdcol"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupEmail">
          <Form.Label>Billing Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Customer's Billing Address"
            className="amthirdcol"
          />
        </Form.Group>
        <Form.Group className="mb-1" controlId="formGroupEmail">
          <Form.Label>Additional Notes</Form.Label>
          <Form.Control
            type="email"
            placeholder="Any additional notes or comments regarding the sale"
            className="amthirdcol"
          />
        </Form.Group>

        <Row className="subadminlastbutton">
          <GlobalButton
            name={"Cancel"}
            paddingTop={"0.4rem"}
            paddingBottom={"0.4rem"}
            paddingLeft={"1rem"}
            paddingRight={"1rem"}
            fontFamily="Roboto"
            border={"1px solid #595959"}
            background={"#E4CDAA"}
            color={"#642315"}
            width={"3rem"}
          />
          <GlobalButton
            name={"Save"}
            paddingTop={"0.4rem"}
            paddingBottom={"0.4rem"}
            paddingLeft={"1rem"}
            paddingRight={"1rem"}
            fontFamily="Roboto"
            border={"1px solid #595959"}
            background={"#E4CDAA"}
            color={"#642315"}
            width={"3rem"}
          />
        </Row>
      </Form>
    </Container>
  );
};

export default TransactionForm;
