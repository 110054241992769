import React, { useEffect, useState } from "react";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";
import "../EmployeeData/EmployeeData.css";
import { UserController } from "../../Redux/controllers/UserController.js";
import { useSelector } from "react-redux";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner.js";
import Utility from "../../Utils/Utility.js";
import ViewLeaveRequests from "../Modals/ViewLeaveRequest/ViewLeaveRequests.js";
import * as XLSX from "xlsx";
import { PDFDocument, StandardFonts } from "pdf-lib";

const LeaveRequests = ({ page }) => {
  const [allLeaves, setAllLeaves] = useState([]);
  const [filteredLeaves, setFilteredLeaves] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of rows per page
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState({});
  const [refresh, setRefresh] = useState(false);

  const token = useSelector((state) => state.user.token);

  const handleCloseModal = async () => {
    setShowModal(false);
    await fetchLeaves();
    setRefresh(!refresh);
  };

  const fetchLeaves = async () => {
    try {
      setLoading(true);
      const res = await UserController.getAllLeaves(token);
      setAllLeaves(res?.data?.data?.allLeaves || []);
      setFilteredLeaves(res?.data?.data?.allLeaves || []);
    } catch (error) {
      console.error("Error fetching leave requests:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLeaves();
  }, []);

  // Filter and search logic
  useEffect(() => {
    const filtered = allLeaves.filter((item) => {
      const searchText = searchTerm.toLowerCase();
      return (
        item?.appliedBy?.name?.toLowerCase().includes(searchText) ||
        item?.appliedBy?.empDepartment?.toLowerCase().includes(searchText) ||
        Utility.formatDate(item?.fromDate).includes(searchText) ||
        Utility.formatDate(item?.toDate).includes(searchText) ||
        item?.approvalStatus?.toLowerCase().includes(searchText)
      );
    });
    setFilteredLeaves(filtered);
    setCurrentPage(1); // Reset to the first page after a new search
  }, [searchTerm, allLeaves]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredLeaves.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredLeaves.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

    const exportToExcel = () => {
      const data = filteredLeaves.map((leave) => ({
        "Requested By": leave?.appliedBy?.name || "N/A",
        Department: leave?.appliedBy?.empDepartment || "N/A",
        "From Date": Utility.formatDate(leave?.fromDate) || "N/A",
        "To Date": Utility.formatDate(leave?.toDate) || "N/A",
        Status: leave?.approvalStatus || "N/A",
      }));
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Leave Requests");
      XLSX.writeFile(workbook, "LeaveRequests.xlsx");
    };

const exportToPDF = async () => {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage([600, 700]);
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const fontSize = 10;
  let y = 650;

  const addHeader = (page) => {
    const headers = [
      "Requested By",
      "Department",
      "From Date",
      "To Date",
      "Status",
    ];
    page.drawText(headers.join(" | "), {
      x: 50,
      y,
      size: fontSize,
      font,
      colorRgb: [0, 0, 0],
    });
    y -= 20;
  };

  addHeader(page);

  filteredLeaves.forEach((leave, index) => {
    if (y < 50) {
      const newPage = pdfDoc.addPage([600, 700]);
      y = 650;
      addHeader(newPage);
    }
    const row = [
      leave?.appliedBy?.name || "N/A",
      leave?.appliedBy?.empDepartment || "N/A",
      Utility.formatDate(leave?.fromDate) || "N/A",
      Utility.formatDate(leave?.toDate) || "N/A",
      leave?.approvalStatus || "N/A",
    ];
    page.drawText(row.join(" | "), { x: 50, y, size: fontSize, font });
    y -= 20;
  });

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "LeaveRequests.pdf";
  link.click();
};



  return (
    <>
      {showModal && (
        <ViewLeaveRequests
          show={showModal}
          onHide={handleCloseModal}
          leaveData={selectedLeave}
        />
      )}
      <div style={{ position: "relative" }}>
        <Row>
          {loading ? (
            <div className="spinnerrowitem">
              <CircularIndeterminate />
            </div>
          ) : (
            <>
              {/* Search and Refresh Controls */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <button
                  className="btn btn-success me-2"
                  onClick={exportToExcel}
                >
                  Export Excel
                </button>
                <button className="btn btn-primary me-3" onClick={exportToPDF}>
                  Export PDF
                </button>
              </div>
              <div className="d-flex justify-content-between mb-3 mt-3">
                <input
                  type="text"
                  className="form-control"
                  style={{ width: "70%" }}
                  placeholder="Search leave requests..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <button
                  className="btn btn-secondary"
                  onClick={fetchLeaves}
                  disabled={loading}
                >
                  {loading ? "Refreshing..." : "Refresh"}
                </button>
              </div>

              {/* Table */}
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>Sl No</th>
                    <th>Requested By</th>
                    <th>Department</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.length > 0 ? (
                    currentItems.map((item, index) => (
                      <tr key={index}>
                        <td>{indexOfFirstItem + index + 1}</td>
                        <td>{item?.appliedBy?.name || "N/A"}</td>
                        <td>{item?.appliedBy?.empDepartment || "N/A"}</td>
                        <td>{Utility.formatDate(item?.fromDate) || "N/A"}</td>
                        <td>{Utility.formatDate(item?.toDate) || "N/A"}</td>
                        <td>
                          <GlobalButton
                            disabledBgColor={Utility.getStatusColor(
                              item?.approvalStatus
                            )}
                            disabled={true}
                            name={
                              item?.approvalStatus?.charAt(0).toUpperCase() +
                              item?.approvalStatus?.slice(1)
                            }
                            background="dc2329"
                            width="4rem"
                            padding="1.5px"
                            color="#fff"
                            fontsize="10px"
                          />
                        </td>
                        <td>
                          <button
                            id="btnnn"
                            className="selectButton"
                            onClick={() => {
                              setShowModal(true);
                              setSelectedLeave(item);
                            }}
                            style={{ width: "4rem" }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No leave requests found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {/* Pagination Controls */}
              <div className="d-flex justify-content-between align-items-center">
                <button
                  className="btn btn-primary"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className="btn btn-primary"
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </button>
              </div>
            </>
          )}
        </Row>
      </div>
    </>
  );
};

export default LeaveRequests;
