import React from "react";
import { Route, Router, Routes } from "react-router-dom";
import DashBoard from "../Admin/pages/DashBoard/DashBoard";
import AboutUs from "../Admin/pages/AboutUs/AboutUs";
import Financials from "../Admin/pages/Financials/Financials";
import TransactionForm from "../Admin/pages/TransactionForm/TransactionForm";
import OrderHIstory from "../Admin/pages/OrderHistory/OrderHIstory";
import Vendors from "../Admin/pages/Vendors/Vendors";
// import CustomerOrderDetails from "../Admin/pages/Modals/CustomerOrderDetails/CustomerOrderDetails";
// import QRCode from "../Admin/pages/Modals/QRCode/QRCode";
import Items from "../Admin/pages/Items/Items";
import EmployeData from "../Admin/pages/EmployeeData/EmployeData";
import AddSubadminForm from "../Admin/pages/AddSubadminForm/AddSubadminForm";
import Saleryform from "../Admin/pages/viewsaleryform/Saleryform";
import Updatesalery from "../Admin/pages/updatesaleryform/Updatsalery";
import CustomerData from "../Admin/pages/CustomerData/CustomerData";
import Productform from "../Admin/pages/PruductForm/Productform";
import Login from "../Admin/pages/Login/Login";
import Others from "../Admin/pages/Othres/Others";
import QueryInfoModal from "../Admin/pages/Modals/QueryInformationModal/QueryInfoModal";
import TaskInfoModal from "../Admin/pages/Modals/TaskInfoModal/TaskInfoModal";
import AllTasks from "../Admin/pages/AllTasks/AllTasks";

export default function RootRouter() {
  return (
    <Routes>
      {/* <Route path="/login" element={<Login />} /> */}
      <Route path="/" element={<DashBoard />} />
      <Route path="/productform" element={<Productform />} />
      <Route path="/employeedata" element={<EmployeData />} />
      <Route path="/addnewsubadmin" element={<AddSubadminForm />} />
      <Route
        path="/edit-subadmin/:id"
        element={<AddSubadminForm isEdit={true} />}
      />
      <Route path="/viewsalery" element={<Saleryform />} />
      <Route path="/customerdata" element={<CustomerData />} />

      {/* <Route path="/" element={<Updatesalery />} /> */}
      {/* <Route path="/" element={<Saleryform />} /> */}
      <Route path="about" element={<AboutUs />} />
      <Route path="/financials" element={<Financials />} />
      <Route path="/orderhistory" element={<OrderHIstory />} />
      <Route path="/transactionform" element={<TransactionForm />} />
      <Route path="/vendors" element={<Vendors />} />
      <Route path="/alltasks" element={<AllTasks />} />
      <Route path="/items" element={<Items />} />
      <Route path="/others" element={<Others />} />
      <Route path="/query" element={<QueryInfoModal />} />
      <Route path="/task" element={<TaskInfoModal />} />
      {/* <Route path="/modal" element={<CustomerOrderDetails show={true}/>} /> */}
    </Routes>
  );
}
