import React, { useState } from "react";
import { Row, Col, Modal, Container, Form } from "react-bootstrap";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import "./ProductDetailsModal.css";
import { useNavigate } from "react-router-dom";
import { AiOutlineQrcode } from "react-icons/ai";
import Feature from "../ShowFeature/Feature";
import DOMPurify from "dompurify";
import Utility from "../../../Utils/Utility";
import { useSelector } from "react-redux";
import CircularIndeterminate from "../../../../globalcomponents/Spinner/Spinner";
import { UserController } from "../../../Redux/controllers/UserController";
import { RxCross2 } from "react-icons/rx";

const ProductDetailsModal = (props) => {
  const [isKeyFeaturesOpen, setIsKeyFeaturesOpen] = useState(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [ismaterialInfo, setismaterialInfo] = useState(false);
  const navigate = useNavigate();

  const { selectedProduct } = props;

  console.log("selected Product", selectedProduct);

  const toggleKeyFeatures = () => {
    setIsKeyFeaturesOpen(!isKeyFeaturesOpen);
  };

  const toggleDescription = () => {
    setIsDescriptionOpen(!isDescriptionOpen);
  };
  const toggleMaterialInfo = () => {
    setismaterialInfo(!ismaterialInfo);
  };

  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setShowModal((prev) => !prev);
  };

  const [type, setType] = useState("stock_in");
  const [stock, setStock] = useState(null);
  const token = useSelector((st) => st?.user?.token);
  const [loading, setLoading] = useState(false);

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleUpdateStock = async (e) => {
    e.preventDefault();
    try {
      if (!stock) {
        return Utility.eToast("please enter stock quantity");
      }
      setLoading(true);
      const { data } = await UserController?.updateProductStock(token, {
        productId: selectedProduct?._id,
        type: type,
        quantity: stock,
      });
      Utility.sToast(data?.message);
      props.onHide();
    } catch (error) {
      // console.log("Error",error);
      Utility?.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container className="p-2">
        {/* 
        {showModal && (
          <UpdateProductModal
            show={showModal}
            onHide={() => setShowModal(false)}
            material={selectedProduct}
          />
        )} */}

        <Modal id="transactionModal" {...props} size="lg">
          <Modal.Header closeButton style={{ background: "#f4f3f0" }}>
            <Modal.Title style={{ color: "#642315E5" }}>
              Product Information
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mt-1">
              <Col>Product Name</Col>
              <Col className="value">
                {selectedProduct ? selectedProduct.name : "N/A"}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>Product ID</Col>
              <Col className="value">
                {selectedProduct?.productID
                  ? "#" + selectedProduct.productID
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>Product Date Of Listing</Col>
              <Col className="value">
                {selectedProduct
                  ? Utility.formatDate(selectedProduct.createdAt)
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>Dimensions</Col>
              <Col className="value">
                {selectedProduct?.size
                  ? selectedProduct.size.join(", ")
                  : "N/A"}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>Color Options</Col>
              <Col className="value">
                <div className="colorOptionBox">
                  {selectedProduct
                    ? selectedProduct.color.map((clr, index) => (
                        <div
                          key={index}
                          className="colorOption"
                          style={{ backgroundColor: `${clr}` }}
                        ></div>
                      ))
                    : "N/A"}
                </div>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>Texture</Col>
              <Col className="value">
                <div className="textureBox">
                  {selectedProduct
                    ? selectedProduct.pattern.map((pat, index) => (
                        <img
                          key={index}
                          className="texture"
                          src={pat}
                          alt="texture"
                        />
                      ))
                    : "N/A"}
                </div>
              </Col>
            </Row>
            <Row className="mt-3 mb-1">
              <Col>Key Features</Col>
              <Col className="value">
                {selectedProduct ? (
                  <>
                    <GlobalButton
                      name={isKeyFeaturesOpen ? "Close" : "View"}
                      background={"#dc2329"}
                      color={"#fff"}
                      padding={"0"}
                      onclick={toggleKeyFeatures}
                    />
                  </>
                ) : (
                  "N/A"
                )}
              </Col>
              <Col sm={12} xs={12}>
                <div
                  className={`key-features-slider ${
                    isKeyFeaturesOpen ? "open" : ""
                  }`}
                >
                  <div />
                  <h6 className="descriptionparser">
                    {Utility.HTMLParser(selectedProduct?.keyFeatures)}
                  </h6>
                </div>
              </Col>
            </Row>
            <Row className="mt-1 mb-1">
              <Col>Additional Info</Col>
              <Col className="value">
                {selectedProduct ? (
                  <>
                    <GlobalButton
                      name={isDescriptionOpen ? "Close" : "View"}
                      background={"#dc2329"}
                      color={"#fff"}
                      padding={"0"}
                      onclick={toggleDescription}
                    />
                  </>
                ) : (
                  "N/A"
                )}
              </Col>
              <Col sm={12} xs={12}>
                <div
                  className={`description-slider ${
                    isDescriptionOpen ? "open" : ""
                  }`}
                >
                  <div />
                  <h6 className="descriptionparser">
                    {Utility.HTMLParser(selectedProduct.description)}
                  </h6>
                </div>
              </Col>
            </Row>
            <Row className="mt-1 mb-1">
              <Col>Materials</Col>
              <Col className="value">
                {selectedProduct ? (
                  <>
                    <GlobalButton
                      name={ismaterialInfo ? "Close" : "View"}
                      background={"#dc2329"}
                      color={"#fff"}
                      padding={"0"}
                      onclick={toggleMaterialInfo}
                    />
                  </>
                ) : (
                  "N/A"
                )}
              </Col>
              <Col sm={12} xs={12}>
                <div
                  className={`description-slider ${
                    ismaterialInfo ? "open" : ""
                  }`}
                >
                  <div />
                  <h6 className="descriptionparser">
                    {selectedProduct?.materialInfo
                      ? Utility.HTMLParser(selectedProduct.materialInfo)
                      : "No Information is present"}
                  </h6>
                </div>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>Total Stock</Col>
              <Col className="value">
                {selectedProduct?.stock ? selectedProduct?.stock : "0"}
              </Col>
            </Row>

            {/* second row  */}
            <h6 id="productImgHead">Product Images</h6>
            <Row className="ImageContainer">
              <div className="productImage">
                {selectedProduct.images.map((product, index) => (
                  <div key={index} className="viewProduct">
                    <img className="viewProduct" src={product} alt=" " />
                  </div>
                ))}
              </div>
            </Row>

            <Row>
              <Col className="updateButton">
                <GlobalButton
                  name={showModal ? <RxCross2 /> : "Update Stock"}
                  background={"#dc2329"}
                  color={"#fff"}
                  padding={"0"}
                  onclick={handleModal}
                />
              </Col>
              {showModal && <hr />}
            </Row>
            {showModal && (
              <Row>
                <Form>
                  <div className="radio-container">
                    <Form.Check
                      inline
                      label="Stock In"
                      name="stock"
                      type="radio"
                      id="stock-in"
                      value="stock_in"
                      checked={type === "stock_in"}
                      onChange={handleTypeChange}
                    />
                    <Form.Check
                      inline
                      label="Stock Out"
                      name="stock"
                      type="radio"
                      id="stock-out"
                      value="stock_out"
                      checked={type === "stock_out"}
                      onChange={handleTypeChange}
                    />
                  </div>
                  <Row className="second-row">
                    <Col xs={6} className="quantity-input">
                      <Form.Group
                        className=""
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          className="custom-placeholder"
                          type="number"
                          placeholder="Enter quantity"
                          value={stock}
                          onChange={(e) => setStock(e.target.value)}
                          style={{
                            backgroundColor: "#f4f3f0",
                            color: "#642315E5",
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="update">
                      {loading ? (
                        <CircularIndeterminate />
                      ) : (
                        <GlobalButton
                          name={"Update"}
                          fontFamily="Roboto"
                          background={"#dc2329"}
                          color={"#fff"}
                          border={"1px solid #642315"}
                          onclick={handleUpdateStock}
                        />
                      )}
                    </Col>
                  </Row>
                </Form>
              </Row>
            )}
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default ProductDetailsModal;
