import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export default function GlobalButton(props) {
  const BootstrapButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: props.fontsize,
    padding: props.padding,
    paddingLeft: props.paddingLeft,
    paddingRight: props.paddingRight,
    border: props.border,
    fontWeight: props.fontWeight,
    borderRadius: props.borderradius,
    backgroundColor: props.background,
    borderColor: props.bordercolor,
    color: props.color,
    margin: props.margin,
    width: props.width,
    marginTop: props.marginTop,
    marginBottom: props.marginBottom,
    marginRight: props.marginRight || "0",
    marginLeft: props.marginLeft || "0",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      "Open Sans se",
    ].join(","),
    "&:hover": {
      backgroundColor: props.hoverbgcolor || "#dc2923",
      borderColor: props.hoverbordercolor,
      boxShadow: "none",
      color: props.hoverColor,
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: props.activebgcolor,
      borderColor: props.activebordercolor,
    },
    "&:disabled": {
      backgroundColor: props.disabledBgColor,
      color: props.color,
    },
  });

  return (
    <BootstrapButton
      disabled={props?.disabled || false}
      variant="contained"
      onClick={props.onclick}
    >
      {props.icon && <span style={{ marginRight: 8 }}>{props.icon}</span>}
      {props.name}
    </BootstrapButton>
  );
}
