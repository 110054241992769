import React, { useState } from "react";
import { Row, Col, Modal, Container } from "react-bootstrap";
import "./AddCategory.css";
import { FaPlus } from "react-icons/fa6";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { UserController } from "../../Redux/controllers/UserController";
import Utility from "../../Utils/Utility";
import { useSelector } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddCategory = (props) => {
  const navigate = useNavigate();
  const [loadingPicture, setLoadingPicture] = useState(false);
  const [images, setImages] = useState("");
  const [inputs, setInputs] = useState({ name: "", image: "" });
  const [loading, setLoading] = useState(false);
  const [touched, setTouched] = useState(false);

  const token = useSelector((state) => state.user.token);
  let imgurl;
  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        setLoadingPicture(true);
        const formdata = new FormData();
        formdata.append("doc", file);
        const response = await UserController.SingleImageUploader(formdata);
        console.log("here is response-----------------", response);
        imgurl = response.data.doc;
        setImages(imgurl);
        setInputs({ ...inputs, image: imgurl });
        setLoadingPicture(false);
      } catch (error) {
        console.error("Error uploading image:", error);
        setLoadingPicture(false);
      }
    }
  };

  const handlechange = (e) => {
    setInputs({ ...inputs, name: e.target.value });
  };

  const handleclick = async () => {
    setTouched(true);

    if (inputs.name.length === 0 || inputs.image.length === 0) {
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const response = await UserController.addNewCategory({ inputs, token });

      Utility.sToast("Item added successfully");
      setLoading(false);
      console.log(response);
      props.onHide();
      props.Allcategory();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <Container className="p-2">
      <Modal id="transactionModal" {...props} size="lg">
        <Modal.Header closeButton className="productmodalheader">
          <Modal.Title>Category of Products</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder="Write Name Product Category"
            className="categoryinput"
            name="inputcategory"
            onChange={handlechange}
          />
          {touched && !inputs.name && (
            <span className="error"> Please select Category name</span>
          )}
          <Row className="modalproductlastrow">
            <Col>
              <div className="categoryImage">
                <label style={{ position: "absolute" }}>
                  {images ? (
                    <img
                      className="categoryImg"
                      src={images}
                      alt="Uploaded"
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  ) : (
                    <div className="plus">
                      {loadingPicture ? (
                        <div className="spinner-border loader" role="status">
                          <span className="sr-only"></span>
                        </div>
                      ) : (
                        <FaPlus />
                      )}
                    </div>
                  )}
                  <input
                    className="inputBox"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImageChange(e)}
                  />
                </label>
              </div>
              {touched && !inputs.image && (
                <span className="error"> Please select Category image</span>
              )}
            </Col>
          </Row>
          <Row className="addproductlastbutton">
            {loading ? (
              <div className="uploadLoader">
                <div className="spinner-border loader" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              <GlobalButton
                name={"Add Category"}
                paddingTop={"0.4rem"}
                disabled={loadingPicture}
                paddingBottom={"0.4rem"}
                paddingLeft={"1rem"}
                onclick={handleclick}
                paddingRight={"1rem"}
                fontFamily="Roboto"
                background={"#dc2329"}
                color={"#fff"}
                width={"9rem"}
              />
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default AddCategory;
