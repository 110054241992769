import React, { useEffect, useState } from "react";
import "./InitialRenderer.css";
import { Col, Container, Row } from "react-bootstrap";
import SideBarMenu from "../globalcomponents/SideBarMenu/SideBarMenu";
import TopNavBar from "../globalcomponents/TopNavBar/TopNavBar";
import { BrowserRouter as Router } from "react-router-dom";
import RootRouter from "./RootRouter";
import Login from "../Admin/pages/Login/Login";
import { useSelector } from "react-redux";

export default function InitialRenderer() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  const token = useSelector((state) => state.user.token);
  console.log(token);

  return (
    <Router>
      {!token ? (
        <Login />
      ) : (
        <Container fluid className="rr3">
          <Row>
            <Col
              xxs={12}
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              xxl={2}
              className="rr1"
            >
              <SideBarMenu />
            </Col>
            <Col
              xxs={12}
              xs={12}
              sm={12}
              md={10}
              lg={10}
              xl={10}
              xxl={10}
              className="rr2"
            >
              <div className="rr1">
                {screenWidth < 599.95 ? null : <TopNavBar />}
              </div>
              <Container className="rr4">
                <RootRouter />
              </Container>
            </Col>
          </Row>
        </Container>
      )}
    </Router>
  );
}
