import React, { useEffect, useState } from "react";
import "./Items.css";
import { Container, Row, Col } from "react-bootstrap";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoQrCodeSharp } from "react-icons/io5";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import ProductDetailsModal from "../Modals/ProductDetailsModal/ProductDetailsModal";
import GenerateQRModal from "../Modals/GenerateQRModal/GenerateQRModal";
import QRCode from "../Modals/QRCode/QRCode";
import { useNavigate } from "react-router-dom";
import { UserController } from "../../Redux/controllers/UserController";
import DeleteModal from "../Modals/DeleteModal/DeleteModal";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";
import Utility from "../../Utils/Utility";
import ViewQrCode from "../Modals/ViewQrCode/ViewQrCode";
import { useDispatch } from "react-redux";
import { logOut } from "../../Redux/Slice/userSlice";
import * as XLSX from "xlsx";
import { PDFDocument, StandardFonts } from "pdf-lib";

const Items = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showThirdModal, setShowThirdModal] = useState(false);
  const [fetcheddata, setFetcheddata] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [spin, setSpin] = useState(false);
  const [deleteModal, setdeleteModal] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);
  const [selectedProductQr, setSelectedProductQr] = useState(null);
  const [showForthModal, setShowForthModal] = useState(false);

  const handleModal = () => {
    setShowModal(true);
  };
  const handleOpenSecondModal = () => {
    setShowModal(false);
    setShowSecondModal(true);
  };
  const handleOpenThirdModal = () => {
    setShowSecondModal(false);
    setShowThirdModal(true);
  };

  const handelForthModal = () => {
    setShowForthModal(true);
  };

  const fetchAllProducts = async () => {
    try {
      setSpin(true);
      const res = await UserController.getProductItem();
      setFetcheddata(res.data.data);
      setSpin(false);
    } catch (error) {
      setSpin(false);
      Utility.eToast(error?.response?.data?.message || error?.message);
      if (
        error?.response?.data?.message ===
        "token has expired , please login again"
      ) {
        dispatch(logOut());
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setSpin(false);
    }
  };

  useEffect(() => {
    fetchAllProducts();
  }, [showModal]);

  const handleViewClick = (product) => {
    setSelectedProduct(product);
    handleModal();
  };

  const handelQrClick = (product) => {
    setSelectedProductQr(product);
    handelForthModal();
  };

  const handleEditProduct = (product) => {
    setProductToEdit(product);
    setIsEditing(true);
    navigate("/productform", {
      state: { isEditing: true, productToEdit: product },
    });
  };

  const handleDeleteProduct = (productId) => {
    setDeleteProductId(productId);
    setdeleteModal(true);
  };

  const handleAddProduct = () => {
    setIsEditing(false);
    setProductToEdit(null);
    navigate("/productform");
  };

  const filteredData = fetcheddata.filter((product) =>
    product?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const exportToExcel = () => {
    const formattedData = fetcheddata.map((product, index) => ({
      "Sl No": index + 1,
      "HSN Code": product?.hsnCode || "N/A",
      "Product ID": product?.productID || "N/A",
      "Product Name": product?.name || "N/A",
      "GST (%)": product?.gst || "0",
      Cost: product?.price || "N/A",
    }));

    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Products");
    XLSX.writeFile(wb, "Products.xlsx");
  };

const exportToPDF = async () => {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage([600, 800]);
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const fontSize = 12;

  page.drawText("Product List", { x: 220, y: 780, size: 16, font });

  const headers = [
    "Sl No",
    "HSN Code",
    "Product ID",
    "Product Name",
    "GST (%)",
    "Cost",
  ];

  const rows = fetcheddata.map((product, index) => [
    (index + 1).toString(),
    product?.hsnCode?.toString() || "N/A",
    product?.productID?.toString() || "N/A",
    product?.name || "N/A",
    product?.gst?.toString() || "0",
    product?.price?.toString() || "N/A",
  ]);

  let y = 740;
  headers.forEach((header, i) => {
    page.drawText(header, { x: 50 + i * 100, y, size: fontSize, font });
  });

  y -= 20;
  rows.forEach((row) => {
    row.forEach((cell, i) => {
      page.drawText(cell.toString(), {
        x: 50 + i * 100,
        y,
        size: fontSize,
        font,
      });
    });
    y -= 20;
  });

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "Products.pdf";
  link.click();
};



  return (
    <Container className="p-2 mb-2 mt-2">
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          setonHide={() => {
            setdeleteModal(false);
            fetchAllProducts();
          }}
          onHide={() => setdeleteModal(false)}
          productId={deleteProductId}
        />
      )}
      {showModal && (
        <ProductDetailsModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
            setSelectedProduct(null);
          }}
          onOpenSecondModal={handleOpenSecondModal}
          selectedProduct={selectedProduct}
        />
      )}
      {showSecondModal && (
        <GenerateQRModal
          show={showSecondModal}
          onHide={() => {
            setShowSecondModal(false);
          }}
          onOpenThirdModal={handleOpenThirdModal}
        />
      )}
      {showThirdModal && (
        <QRCode
          show={setShowThirdModal}
          onHide={() => {
            setShowThirdModal(false);
          }}
        />
      )}

      {showForthModal && (
        <ViewQrCode
          show={showForthModal}
          onHide={() => {
            setShowForthModal(false);
            setSelectedProductQr(null);
          }}
          selectedProduct={selectedProductQr}
        />
      )}

      <Row className="second-box">
        <Col>
          <button className="btn btn-success me-2" onClick={exportToExcel}>
            Export Excel
          </button>
          <button className="btn btn-primary me-3" onClick={exportToPDF}>
            Export PDF
          </button>
          <button id="btnnn" onClick={handleAddProduct}>
            <IoIosAddCircleOutline id="plusicon" /> Add Product
          </button>
        </Col>
        {/* <Col>
          <input
            type="text"
            placeholder="Search by product name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </Col> */}
      </Row>

      {spin ? (
        <Container className="spinnerrowitem">
          <CircularIndeterminate />
        </Container>
      ) : (
        <Row>
          <Col>
            <table className="lightweight-table">
              <thead>
                <tr>
                  <th>Sl No</th>
                  <th>HSN Code</th>
                  <th>Product Id</th>
                  <th>Product Name</th>
                  <th>GST (in %age)</th>
                  <th>Cost</th>
                  <th>Details</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((product, index) => (
                  <tr key={product?.productID || index}>
                    <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                    <td>{product?.hsnCode || "N/A"}</td>
                    <td>{product?.productID || "N/A"}</td>
                    <td>{product?.name || "N/A"}</td>
                    <td>{product?.gst || "0"}</td>
                    <td>{Utility.MoneyFormatter(product?.price)}</td>
                    <td>
                      <div className="viewAndQRButton">
                        <GlobalButton
                          name={"View"}
                          background={"#dc2329"}
                          color={"#fff"}
                          padding={"0"}
                          onclick={() => handleViewClick(product)}
                        />
                        <div
                          title="generate Qr code"
                          className="qrCodeBackGround"
                          onClick={() => handelQrClick(product)}
                        >
                          <IoQrCodeSharp className="qrCode" />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div style={{ display: "flex", gap: "0.5rem" }}>
                        <GlobalButton
                          name={"Edit"}
                          background={"#dc2329"}
                          color={"#fff"}
                          padding={"0"}
                          onclick={() => handleEditProduct(product)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                (page) => (
                  <button
                    key={page}
                    onClick={() => handlePageChange(page)}
                    className={page === currentPage ? "active" : ""}
                  >
                    {page}
                  </button>
                )
              )}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Items;
