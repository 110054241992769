import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { UserController } from "../../../Redux/controllers/UserController";
import Utility from "../../../Utils/Utility";

function DeleteModal(props) {
  const productid = props.productId;
  const handledeleteitem = async () => {
    try {
      await UserController.deleteProductItem(productid).then(() => {
        props.setonHide();
        Utility.sToast("Product deleted successfully");
      });
    } catch (error) {
      console.log("error during deleting in product", error);
      Utility.eToast("Something went wrong");
    }
  };
  return (
    <>
      <Modal {...props}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Are you sure, you want to delete ?</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>Are you sure, you want to delete ?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              handledeleteitem();
            }}
          >
            Yes
          </Button>
          <Button variant="primary" onClick={props.onHide}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteModal;
