import React, { useEffect, useState } from "react";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import "../EmployeeData/EmployeeData.css";
import { UserController } from "../../Redux/controllers/UserController.js";
import { useDispatch, useSelector } from "react-redux";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner.js";
import CustomerInformationModal from "../Modals/CustomerInformationModal/CustomerInformationModal.js";
import { LineChart } from "@mui/x-charts/LineChart";
import { Label } from "recharts";
import Utility from "../../Utils/Utility.js";
import { Margin } from "@mui/icons-material";
import { logOut } from "../../Redux/Slice/userSlice.js";
import * as XLSX from "xlsx";
import { PDFDocument, StandardFonts } from "pdf-lib";

const CustomerData = () => {
  const [allUsers, setAllUsers] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});

  const handleView = (item) => {
    setShowViewModal(true);
    setSelectedCustomer(item);
  };

  const token = useSelector((state) => state.user.token);

  const getAllUsers = async () => {
    try {
      setLoading(true);
      const res = await UserController.getAllUsers(token);
      setAllUsers(res?.data?.data);
      setLoading(false);
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
      if (
        error?.response?.data?.message ===
        "token has expired , please login again"
      ) {
        dispatch(logOut());
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const columns = [
    {
      accessorKey: "slNo",
      header: "Sl No",
      size: 50,
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 100,
    },
    {
      accessorKey: "email",
      header: "Email",
      size: 100,
    },
    {
      accessorKey: "phoneNo",
      header: "Phone No",
      size: 100,
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 100,
    },
  ];

  const data = allUsers?.map((item, index) => ({
    slNo: index + 1,
    name: item?.name || "N/A",
    email: item?.email || "N/A",
    phoneNo: item?.phoneNo || "N/A",
    action: (
      <GlobalButton
        name={"View"}
        background={"#dc2329"}
        color={"#fff"}
        padding={"0"}
        onclick={() => handleView(item)}
      />
    ),
  }));

  const [customerChartData, setCustomerChartData] = useState([]);
  const getCustomerChartDetails = async () => {
    try {
      setLoading(true);
      const res = await UserController.customerChartDetails(token);
      setCustomerChartData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomerChartDetails();
  }, []);

  console.log("customerChartData", customerChartData?.customersByMonthLastYear);

  const customerData = customerChartData?.customersByMonthLastYear?.map(
    (item) => item?.count
  ) ?? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const exportToExcel = () => {
    const ws1 = XLSX.utils.json_to_sheet(
      allUsers.map((user, index) => ({
        "Sl No": index + 1,
        Name: user.name || "N/A",
        Email: user.email || "N/A",
        "Phone No": user.phoneNo || "N/A",
      }))
    );

    const graphData = month.map((m, index) => ({
      Month: Utility.getShortMonthName(m),
      Customers: customerData[index] || 0,
    }));

    console.log("Graph Data for Excel:", graphData);

    const ws2 = XLSX.utils.json_to_sheet(graphData);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, "CustomerData");
    XLSX.utils.book_append_sheet(wb, ws2, "GraphData");

    XLSX.writeFile(wb, "CustomerData.xlsx");
  };



  const exportToPDF = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([595.28, 841.89]);
    const { width, height } = page.getSize();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    let y = height - 50;
    const lineHeight = 20;

    page.drawText("Customer Data", { x: 50, y, size: 18, font });
    y -= 40;

    const header = ["Sl No", "Name", "Email", "Phone No"];
    header.forEach((text, i) => {
      page.drawText(text, { x: 50 + i * 100, y, size: 12, font });
    });
    y -= lineHeight;

    const tableData = allUsers.map((user, index) => [
      index + 1,
      user.name || "N/A",
      user.email || "N/A",
      user.phoneNo || "N/A",
    ]);
    tableData.forEach((row) => {
      row.forEach((text, i) => {
        page.drawText(text.toString(), {
          x: 50 + i * 100,
          y,
          size: 10,
          font,
        });
      });
      y -= lineHeight;
    });

    y -= 20;

    page.drawText("Last 12 Months Customer Data", { x: 50, y, size: 14, font });
    y -= 30;

    const graphHeader = ["Month", "Customers"];
    graphHeader.forEach((text, i) => {
      page.drawText(text, { x: 50 + i * 150, y, size: 12, font });
    });
    y -= lineHeight;
    month.forEach((m, index) => {
      const row = [Utility.getShortMonthName(m), customerData[index] || 0];
      row.forEach((text, i) => {
        page.drawText(text.toString(), {
          x: 50 + i * 150,
          y,
          size: 10,
          font,
        });
      });
      y -= lineHeight;
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "CustomerData.pdf";
    link.click();
  };



  return (
    <>
      {showViewModal && (
        <CustomerInformationModal
          show={showViewModal}
          onHide={() => setShowViewModal(false)}
          selectedCustomer={selectedCustomer}
        />
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
      </div>
      <div className="mt-3 emptapblepaddding">
        <Row className="graph">
          <div style={{ width: "74%", height: "100%" }}>
            <p
              style={{
                color: "black",
                margin: "10px",
                textDecoration: "underline",
              }}
            >
              <strong>Last 12 months Customer</strong>
            </p>
            {loading ? (
              <div className="spinnerrowitem">
                <CircularIndeterminate />
              </div>
            ) : (
              <LineChart
                grid={{ horizontal: true, vertical: true }}
                xAxis={[
                  {
                    data: month,
                    valueFormatter: Utility.getShortMonthName,
                  },
                ]}
                series={[
                  {
                    data: customerData,
                    color: "#C76E00",
                  },
                ]}
                // width={800}
                height={450}
                leftAxis={{}}
                bottomAxis={{
                  label: "Month",
                }}
              />
            )}
          </div>
        </Row>

        <Row className="tablepaddinginner">
          {loading ? (
            <div className="spinnerrowitem">
              <CircularIndeterminate />
            </div>
          ) : (
            <div className="p-2" style={{
              backgroundColor: "white",
            }}>
              <Row>
                <Col>
                  <div className="d-flex justify-content-end align-items-center mb-2">
                    <button className="btn btn-success me-2 ms-auto" onClick={exportToExcel}>
                      Export Excel
                    </button>
                    <button className="btn btn-primary me-3" onClick={exportToPDF}>
                      Export PDF
                    </button>
                  </div>
                </Col>
              </Row>
              <DataTable columns={columns} data={data} />
            </div>
          )}
        </Row>
      </div>
    </>
  );
};

export default CustomerData;
