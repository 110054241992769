import React from "react";
import QR from "../../../assets/items/QR.png";
import { Container, Modal, Row } from "react-bootstrap";
import "./QRCode.css";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
const QRCode = (props) => {
  return (
    <>
      <Container className="p-2">
        <Modal id="transactionModal" {...props} size="lg">
          <Modal.Header closeButton style={{ background: "#F4F3F0" }}>
            <Modal.Title style={{ color: "black", background: "#F4F3F0" }}>
              Product QR Code
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="d-flex justify-content-center align-items-center">
              <img src={QR} alt="" id="QRContainer" />
            </Row>
            <Row className="congratsHeading">
              <h2 className="text-center">Congratulations!</h2>
              <p id="qrNote">
                Your product's QR code has been successfully generated. Scan it
                for instant access to product details and information.
              </p>
            </Row>
            <Row className="d-flex justify-content-center">
              <GlobalButton
                name={"Download QR Code"}
                background={"#E4CDAA"}
                color={"#642315E5"}
                width={`${window.innerWidth < 768 ? "50%" : "30%"}`}
                border={"1px solid #642315E5"}
                padding={`${window.innerWidth < 768 ? "0" : ""}`}
                fontsize={`${window.innerWidth < 768 ? "14px" : "16px"}`}
              />
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default QRCode;
