import React, { useEffect, useState } from "react";
import "./AddSubadmin.css";
import { Container, Row, Col } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import Form from "react-bootstrap/Form";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { FaFileDownload } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { UserController } from "../../Redux/controllers/UserController";
import Utility from "../../Utils/Utility.js";
import axios from "axios";
import { toast } from "react-toastify";
import { RxEyeOpen } from "react-icons/rx";
import { FaEyeSlash } from "react-icons/fa";
import { HttpClient } from "../../Redux/controllers/HttpClient.js";

const AddSubadminForm = ({ isEdit }) => {
  const [phoneNmberValid, setPhoneNumberValid] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    name: "",
    phoneNo: "",
    email: "",
    dob: "",
    address: "",
    empDepartment: "",
    empDateOfHire: "",
    empUserName: "",
    password: "",
    empEmgContactName: "",
    empEmgContactPhone: "",
    empEmgAddtionalInfo: "",
    empSalaryPerFrequency: "",
    empPayFrequency: "",
    empPaymentMethod: "",
    empAccountNo: "",
    empIFSC: "",
    empBankName: "",
    empSubDepartment: "",
    empEmployeeID: "", // Added Employee ID
  });
  const [touched, setTouched] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const [loading, setLoading] = useState(false);

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  useEffect(() => {
    const checkIsEdit = async () => {
      if (isEdit && id) {
        try {
          const response = await HttpClient.apiCaller(
            `auth/get-my-profile/${id}`
          );
          setInputs(response.data.data);
        } catch (error) {
          toast.error("Failed to fetch user data");
        }
      }
    };

    checkIsEdit();
  }, [isEdit, id]);

  const checkValidation = () => {
    if (!inputs.name) {
      return false;
    } else if (!inputs.phoneNo || inputs.phoneNo.length !== 10) {
      return false;
    } else if (!inputs.email || !emailRegex.test(inputs.email)) {
      return false;
    } else if (!inputs.dob) {
      return false;
    } else if (!inputs.empDepartment) {
      return false;
    } else if (
      inputs.empDepartment === "production" &&
      !inputs.empSubDepartment
    ) {
      return false;
    } else if (!inputs.empDateOfHire) {
      return false;
    } else if (!inputs.empEmployeeID) {
      return false; // Validation for Employee ID
    } else if (!inputs.empUserName) {
      return false;
    } else if (!isEdit && !inputs.password) {
      return false;
    } else if (!inputs.empEmgContactName) {
      return false;
    } else if (
      !inputs.empEmgContactPhone ||
      inputs.empEmgContactPhone.length !== 10
    ) {
      return false;
    } else if (!inputs.empEmgAddtionalInfo) {
      return false;
    } else if (!inputs.empSalaryPerFrequency) {
      return false;
    } else if (!inputs.empPayFrequency) {
      return false;
    } else if (!inputs.empPaymentMethod) {
      return false;
    }
    return true;
  };

  const cleanUpData = () => {
    setInputs({
      name: "",
      phoneNo: "",
      email: "",
      dob: "",
      address: "",
      empDepartment: "",
      empDateOfHire: "",
      empUserName: "",
      password: "",
      empEmgContactName: "",
      empEmgContactPhone: "",
      empEmgAddtionalInfo: "",
      empSalaryPerFrequency: "",
      empPayFrequency: "",
      empPaymentMethod: "",
      empBankName: "",
      empAccountNo: "",
      empIFSC: "",
      empSubDepartment: "",
      empEmployeeID: "", // Reset Employee ID
    });
  };

  const handlesubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: inputs.name,
      phoneNo: inputs.phoneNo,
      email: inputs.email,
      dob: inputs.dob,
      address: inputs.address,
      empDepartment: inputs.empDepartment,
      empDateOfHire: inputs.empDateOfHire,
      empUserName: inputs.empUserName,
      password: isEdit ? undefined : inputs.password,
      empEmgContactName: inputs.empEmgContactName,
      empEmgContactPhone: inputs.empEmgContactPhone,
      empEmgAddtionalInfo: inputs.empEmgAddtionalInfo,
      empSalaryPerFrequency: inputs.empSalaryPerFrequency,
      empPayFrequency: inputs.empPayFrequency,
      empPaymentMethod: inputs.empPaymentMethod,
      empBankName: inputs.empBankName,
      empAccountNo: inputs.empAccountNo,
      empIFSC: inputs.empIFSC,
      empSubDepartment:
        inputs.empSubDepartment && inputs.empSubDepartment !== ""
          ? inputs.empSubDepartment
          : undefined,
      empEmployeeID: inputs.empEmployeeID, // Added to submission data
      role: "employee",
    };

    if (isEdit) {
      data._id = id;
    }

    try {
      setLoading(true);

      if (!touched) {
        setTouched(true);
        setLoading(false);
        return;
      }

      const valid = checkValidation();
      if (!valid) {
        toast.error("Please enter valid information");
        setLoading(false);
        return;
      }

      const response = isEdit
        ? await UserController.editEmployee(data) // Update API
        : await UserController.addNewSubAdmin(data); // Add API

      setLoading(false);
      cleanUpData();
      navigate(-1);
      Utility.sToast(response?.data?.data?.message);
    } catch (error) {
      console.log("errrrr", error);
      Utility.eToast(error?.response?.data?.message || error?.message);
      setLoading(false);
    }
  };

  const [type, setType] = useState("password");
  const [seePassword, setSeePassword] = useState(false);
  const togglePassword = () => {
    setSeePassword(!seePassword);
    setType(type === "password" ? "text" : "password");
  };

  return (
    <Container className="subadminform">
      <Row id="amtopbarsalery">
        <Col md={11} xs={10} id="firstnames" style={{ color: "black" }}>
          {isEdit ? "Edit new employee" : "Add new employee"}
        </Col>
        <Col md={1} xs={2} className="admincrossicon">
          {" "}
          <RxCross2 id="transaction-crossicon" onClick={() => navigate(-1)} />
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          <p7 className="titleofcontainer">Employee ID</p7>
          <br />
          <Form.Control
            value={inputs.empEmployeeID}
            placeholder="Enter Employee ID"
            className="amthirdcol"
            name="empEmployeeID"
            onChange={handleChange}
          />
          {touched && !inputs.empEmployeeID && (
            <p className="error">Please enter the Employee ID</p>
          )}
        </Col>
      </Row>

      <Row>
        <Col className="amproductdetails" style={{ color: "black" }}>
          Personal Information
        </Col>
      </Row>

      <Form onSubmit={handlesubmit}>
        <Row>
          <Col sm={6}>
            <p7 className="titleofcontainer">Full name</p7>
            <br />
            <Form.Control
              value={inputs.name}
              placeholder="Enter full name"
              className="amthirdcol"
              name="name"
              onChange={handleChange}
            />
            {touched && !inputs.name && (
              <p className="error">Please enter your full name </p>
            )}
          </Col>

          <Col sm={6}>
            <p7 className="titleofcontainer">Phone number</p7>
            <br />
            <Form.Control
              value={inputs.phoneNo}
              type="number"
              placeholder="Enter phone number"
              className="amthirdcol"
              name="phoneNo"
              onChange={handleChange}
            />
            {touched && inputs.phoneNo.length !== 10 && (
              <p className="error">
                Please enter a valid 10-digit phone number
              </p>
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <p7 className="titleofcontainer"> Email address</p7>
            <br />
            <Form.Control
              value={inputs.email}
              placeholder="Enter email address"
              className="amthirdcol"
              name="email"
              onChange={handleChange}
            />
            {touched && !inputs.email && (
              <p className="error">Please enter your email address </p>
            )}
          </Col>

          <Col sm={6}>
            <p7 className="titleofcontainer"> Date of birth</p7>
            <br />
            <Form.Control
              value={inputs.dob}
              type="date"
              placeholder="Date of Birth"
              className="amthirdcol"
              name="dob"
              onChange={handleChange}
            />
            {touched && !inputs.dob && (
              <p className="error">Please enter your date of birth</p>
            )}
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="formGroupEmail">
          <Form.Label className="titleofcontainer">Address</Form.Label>
          <Form.Control
            value={inputs.address}
            type="name"
            placeholder="Enter address"
            className="amthirdcol"
            name="address"
            onChange={handleChange}
          />
          {touched && !inputs.address && (
            <p className="error">Please enter address </p>
          )}
        </Form.Group>

        <Row>
          <Col className="amproductdetails">Employment Details</Col>
        </Row>

        <Row>
          <Col sm={6}>
            <p7 className="titleofcontainer"> Department</p7>
            <br />
            <select
              value={inputs.empDepartment}
              id="cars"
              data-placeholder=""
              className="selecttagsubadminform"
              name="empDepartment"
              onChange={handleChange}
            >
              <option value="">Choose your department</option>
              <option value="sales">Sales</option>
              <option value="production">Production</option>
              <option value="procurement_and_inventory">
                Procurement and inventory
              </option>
              <option value="delivery">Delivery</option>
              <option value="accounts">Accounts</option>
              <option value="manager">Manager</option>
            </select>
            {touched && !inputs.empDepartment && (
              <p className="error">please choose your department </p>
            )}
            {inputs.empDepartment === "production" && (
              <>
                <p7 className="titleofcontainer"> Sub-Department</p7>
                <br />
                <select
                  value={inputs.empSubDepartment}
                  id="sub-departments"
                  className="selecttagsubadminform"
                  name="empSubDepartment"
                  onChange={handleChange}
                >
                  <option value="">Choose employee sub-department</option>
                  <option value="carpenter">Carpenter</option>
                  <option value="carbin">Carbin</option>
                  <option value="polishing">Polishing</option>
                  <option value="cushioning">Cushioning</option>
                  <option value="packaging">Packaging</option>
                </select>
                {touched && !inputs.empSubDepartment && (
                  <p className="error">Please choose employee sub-department</p>
                )}
              </>
            )}
          </Col>

          <Col>
            <p7 className="titleofcontainer"> Date of hire</p7>
            <br />
            <Form.Control
              value={inputs.empDateOfHire}
              type="date"
              placeholder="Enter Employee Id"
              className="amthirdcol"
              name="empDateOfHire"
              onChange={handleChange}
            />
            {touched && !inputs.empDateOfHire && (
              <p className="error">please enter date of hire </p>
            )}
          </Col>
        </Row>

        <Row>
          <Col className="amproductdetails">Security Information</Col>
        </Row>

        <Row>
          <Col sm={6}>
            <p7 className="titleofcontainer"> Username</p7>
            <br />
            <Form.Control
              value={inputs.empUserName}
              placeholder="Enter the username"
              className="amthirdcol"
              name="empUserName"
              onChange={handleChange}
            />
            {touched && !inputs.empUserName && (
              <p className="error">Please enter your username </p>
            )}
          </Col>

          {!isEdit && (
            <Row>
              <Col sm={6}>
                <p7 className="titleofcontainer"> Password</p7>
                <br />
                <div style={{ position: "relative" }}>
                  <Form.Control
                    value={inputs.password}
                    type={type}
                    placeholder="Enter the password"
                    className="amthirdcol"
                    name="password"
                    onChange={handleChange}
                  />
                  <div className="eyeIconnn">
                    {seePassword ? (
                      <FaEyeSlash onClick={togglePassword} color="#595959" />
                    ) : (
                      <RxEyeOpen onClick={togglePassword} color="#595959" />
                    )}
                  </div>
                </div>
                {touched && !inputs.password && (
                  <p className="error">Please enter your password</p>
                )}
              </Col>
            </Row>
          )}
        </Row>

        <Row>
          <Col className="amproductdetails">Emergency Contact</Col>
        </Row>

        <Row>
          <Col sm={6}>
            <p7 className="titleofcontainer"> Emergency contact name</p7>
            <br />
            <Form.Control
              value={inputs.empEmgContactName}
              placeholder="Enter emergency contact name"
              className="amthirdcol"
              name="empEmgContactName"
              onChange={handleChange}
            />
            {touched && !inputs.empEmgContactName && (
              <p className="error">Please enter your emergency contact name</p>
            )}
          </Col>
          <Col sm={6}>
            <p7 className="titleofcontainer"> Emergency phone number</p7>
            <br />
            <Form.Control
              value={inputs.empEmgContactPhone}
              type="number"
              placeholder="Emergency contact phone number"
              className="amthirdcol"
              name="empEmgContactPhone"
              onChange={handleChange}
            />
            {touched && inputs.empEmgContactPhone.length !== 10 && (
              <p className="error">
                Please enter a valid 10-digit emergency Phone Number
              </p>
            )}
          </Col>
        </Row>

        <Form.Group className="mb-3" controlId="formGroupEmail">
          <Form.Label className="titleofcontainer">Additional notes</Form.Label>
          <Form.Control
            value={inputs.empEmgAddtionalInfo}
            type="text"
            placeholder="write additional information"
            className="amthirdcol"
            name="empEmgAddtionalInfo"
            onChange={handleChange}
          />
          {touched && !inputs.empEmgAddtionalInfo && (
            <p className="error">Please enter your additional notes</p>
          )}
        </Form.Group>

        <Row>
          <Col className="amproductdetails">Salary Details</Col>
        </Row>

        <Row>
          <Col sm={6}>
            <p7 className="titleofcontainer"> Pay frequency</p7>
            <br />
            <select
              value={inputs.empPayFrequency}
              id="cars"
              data-placeholder="Choose your preferred payment method"
              className="selecttagsubadminform"
              name="empPayFrequency"
              onChange={handleChange}
            >
              <option value="">Indicate payment Frequency</option>
              <option value="monthly">Monthly</option>
              {/* <option value="bi_weekly">Bi-weekly</option> */}
              <option value="hourly">Hourly</option>
            </select>
            {touched && !inputs.empPayFrequency && (
              <p className="error">
                Please choose your preferred payment method{" "}
              </p>
            )}
          </Col>

          <Col>
            <p7 className="titleofcontainer"> Salary amount</p7>
            <br />
            <Form.Control
              type="number"
              value={inputs.empSalaryPerFrequency}
              placeholder="Specify monthly or hourly salary"
              className="amthirdcol"
              name="empSalaryPerFrequency"
              onChange={handleChange}
            />
            {touched && !inputs.empSalaryPerFrequency && (
              <p className="error">
                Please specify your monthly or annual salary
              </p>
            )}
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <p7 className="titleofcontainer"> Payment method</p7>
            <br />
            <select
              value={inputs.empPaymentMethod}
              id="cars"
              data-placeholder="Choose preferred payment method"
              className="selecttagsubadminform"
              name="empPaymentMethod"
              onChange={handleChange}
            >
              <option value="">Choose preferred payment method</option>
              <option value="check">Check</option>
              <option value="bank_transfer_or_upi">Bank Transfer/UPI</option>
              <option value="cash">Cash</option>
            </select>
            {touched && !inputs.empPaymentMethod && (
              <p className="error">Payment method </p>
            )}
          </Col>

          {inputs?.empPaymentMethod === "bank_transfer_or_upi" && (
            <Col sm={6}>
              <p7 className="titleofcontainer"> Bank name / UPI id</p7>

              <br />
              <Form.Control
                value={inputs.empBankName}
                type="text"
                placeholder="Enter bank name"
                className="amthirdcol"
                name="empBankName"
                onChange={handleChange}
              />
            </Col>
          )}
        </Row>

        <Row>
          {inputs?.empPaymentMethod === "bank_transfer_or_upi" && (
            <Col sm={6}>
              <p7 className="titleofcontainer"> Bank Account Number</p7>
              <br />
              <Form.Control
                type="number"
                value={inputs.empAccountNo}
                placeholder="Enter account number"
                className="amthirdcol"
                name="empAccountNo"
                onChange={handleChange}
              />
            </Col>
          )}

          {inputs?.empPaymentMethod === "bank_transfer_or_upi" && (
            <Col sm={6}>
              <p7 className="titleofcontainer"> IFSC Code</p7>
              <br />
              <Form.Control
                value={inputs.empIFSC}
                placeholder="Enter IFSC code"
                className="amthirdcol"
                name="empIFSC"
                onChange={handleChange}
              />
            </Col>
          )}
        </Row>

        <Row className="subadminlastbutton">
          <GlobalButton
            name={"Reset"}
            paddingTop={"0.4rem"}
            paddingBottom={"0.4rem"}
            paddingLeft={"1rem"}
            paddingRight={"1rem"}
            fontFamily="Roboto"
            // border={"1px solid #595959"}
            background={"#dc2329"}
            color={"#fff"}
            width={"3rem"}
            onclick={cleanUpData}
          />
          {!loading ? (
            <GlobalButton
              name={"Submit"}
              paddingTop={"0.4rem"}
              paddingBottom={"0.4rem"}
              paddingLeft={"1rem"}
              paddingRight={"1rem"}
              fontFamily="Roboto"
              // border={"1px solid #595959"}
              background={"#dc2329"}
              color={"#fff"}
              width={"3rem"}
              onclick={handlesubmit}
            />
          ) : (
            <div className="uploadLoader">
              <div className="spinner-border loader" role="status">
                <span class="sr-only"></span>
              </div>
            </div>
          )}
        </Row>
      </Form>
    </Container>
  );
};

export default AddSubadminForm;
