import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Utility from "../../../Utils/Utility";
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from "../../../../globalcomponents/DataTable/DataTable";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import { UserController } from "../../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import CircularIndeterminate from "../../../../globalcomponents/Spinner/Spinner";
import { RxCross2 } from "react-icons/rx";
import { FaUserPlus } from "react-icons/fa6";
import { Padding } from "@mui/icons-material";

const TaskInfoModal = (props) => {
  const { token } = useSelector((st) => st?.user);
  const location = useLocation();
  const taskId = location?.state?._id;
  const orderId = location?.state?.orderID;
  const [selectedQuery, setSelectedQuery] = useState({});
  const [loading, setLoading] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [task, setTask] = useState({});
  const [isKeyFeaturesOpen, setIsKeyFeaturesOpen] = useState([]);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState([]);
  const [ismaterialInfo, setismaterialInfo] = useState([]);

  const navigate = useNavigate();

  // console.log("order Id", orderId);

  const getTaskById = async () => {
    try {
      setLoading(true);
      const resp = await UserController.getTaskById(token, taskId);
      setTask(resp?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error in getTask By Id", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTaskById();
  }, []);

  const toggleKeyFeatures = (index) => {
    setIsKeyFeaturesOpen((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const toggleDescription = (index) => {
    setIsDescriptionOpen((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };
  const toggleMaterialInfo = (index) => {
    setismaterialInfo((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  console.log("Task state", task);
  // console.log("Type of key features",typeof(task?.orderID?.productDetails?.product?.keyFeatures));
  const [isTaskNameOpen, setIsTaskNameOpen] = useState(false);

  const toggleTaskName = () => {
    setIsTaskNameOpen(!isTaskNameOpen);
  };

  return (
    <>
      <Container
        size="lg"
        className="mt-3"
        style={{ background: "white", padding: "20px", borderRadius: "10px" }}
      >
        <Row
          closeButton
          className="mb-3 p-2 rounded-2 fw-bold "
          style={{ background: "#f4f3f0" }}
        >
          <Col
            style={{ color: "black", background: "#f4f3f0" }}
            className="query-col"
          >
            Task Information
          </Col>
          <Col className="d-flex justify-content-end align-items-center">
            <RxCross2
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
            />
          </Col>
        </Row>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center mt-lg-5">
            <CircularIndeterminate />
          </div>
        ) : (
          <>
            <Row className="mt-3 mb-1">
              <Col>Task Name</Col>
              <Col className="value">
                {(
                  <>
                    <GlobalButton
                      name={isTaskNameOpen ? "Close" : "View"}
                      background={"#dc2329"}
                      color={"#fff"}
                      padding={"0"}
                      onclick={toggleTaskName}
                    />
                  </>
                ) || "N/A"}
              </Col>
              <Col sm={12} xs={12}>
                <div
                  className={` key-features-slider ${
                    isTaskNameOpen ? "open" : ""
                  } `}
                >
                  <div />
                  <h6 className="descriptionparser">
                    {task?.taskName || "N/A"}
                  </h6>
                </div>
              </Col>
            </Row>
            {/* <Row className="mt-1">
              <Col>Task Name</Col>
              <Col className="value">{task?.taskName || "N/A"}</Col>
            </Row> */}
            <Row className="mt-1">
              <Col>Task Created Date</Col>
              <Col className="value">
                {Utility.formatDate(task?.createdAt) || "--"}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>Task Deadline</Col>
              <Col className="value">
                {Utility.formatDate(task?.deadline) || "--"}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>Status</Col>
              <Col className="d-flex justify-content-end ">
                <GlobalButton
                  name={
                    task?.status
                      ? task.status.charAt(0).toUpperCase() +
                        task.status.slice(1)
                      : "--"
                  }
                  background={Utility?.getStatusColor(task?.status)}
                  color={"#fff"}
                  padding={"0"}
                  margin={"3"}
                  width={"100px"}
                />
              </Col>
            </Row>

            <div id="orderHistory" className="mt-1">
              <h6 style={{ color: "rgba(100, 35, 21, 0.898)" }}>Assigned By</h6>
            </div>

            <Row className="mt-1">
              <Col>Name</Col>
              <Col className="value">{task?.assignedBy?.name || "--"}</Col>
            </Row>
            <Row className="mt-1">
              <Col>Mobile No</Col>
              <Col className="value">{task?.assignedBy?.phoneNo || "--"}</Col>
            </Row>
            <Row className="mt-1">
              <Col>Email</Col>
              <Col className="value">{task?.assignedBy?.email || "--"}</Col>
            </Row>

            <div id="orderHistory" className="mt-1">
              <h6 style={{ color: "rgba(100, 35, 21, 0.898)" }}>Assigned To</h6>
            </div>
            <Row className="mt-1">
              <Col>Name</Col>
              <Col className="value">{task?.assignedTo?.name || "--"}</Col>
            </Row>
            <Row className="mt-1">
              <Col>Mobile No</Col>
              <Col className="value">{task?.assignedTo?.phoneNo || "--"}</Col>
            </Row>
            <Row className="mt-1">
              <Col>Email</Col>
              <Col className="value">{task?.assignedTo?.email || "--"}</Col>
            </Row>

            {orderId && (
              <>
                <div id="orderHistory">
                  <h6 style={{ color: "rgba(100, 35, 21, 0.898)" }}>
                    <strong>Order Information</strong>
                  </h6>
                </div>

                <Row className="mt-1">
                  <Col>Order ID</Col>
                  <Col className="value">
                    #{task?.orderID?.orderID || "N/A"}
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>Order Creation Date</Col>
                  <Col className="value">
                    {Utility.formatDate(task?.orderID?.orderCreationDate) ||
                      "N/A"}
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>Order Managed By</Col>
                  <Col className="value">
                    {task?.orderID?.managedBy?.name || "N/A"}
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>Delivery Status</Col>
                  <Col className="value">
                    {task?.orderID?.deliveryStatus || "N/A"}
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>Estimate Delivery Date</Col>
                  <Col className="value">
                    {Utility.formatDate(task?.orderID?.estimatedDeliveryDate) ||
                      "N/A"}
                  </Col>
                </Row>

                <div id="orderHistory" className="mt-1">
                  <h6 style={{ color: "rgba(100, 35, 21, 0.898)" }}>
                    Payment Information
                  </h6>
                </div>

                <Row className="mt-1">
                  <Col>Total Amount</Col>
                  <Col className="value">
                    {task?.orderID?.paymentDetails?.totalAmount || "N/A"}
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>Remaining Amount</Col>
                  <Col className="value">
                    {task?.orderID?.paymentDetails?.remainingAmount || 0}
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>Additional Information</Col>
                  <Col className="value">
                    {task?.orderID?.paymentDetails?.additionalComments || "N/A"}
                  </Col>
                </Row>

                <div id="orderHistory" className="mt-1">
                  <h6 style={{ color: "rgba(100, 35, 21, 0.898)" }}>
                    Product Information
                  </h6>
                </div>

                {task?.orderID?.productDetails?.map((product, index) => (
                  <div>
                    <Row className="">
                      <span
                        style={{
                          marginTop: "8px",
                          marginBottom: "8px",
                          fontSize: "17px",
                          textDecoration: "underline",
                        }}
                      >
                        <strong>{`Product ${index + 1}`}</strong>
                      </span>
                      <Row className="mt-1">
                        <Col>Product Name</Col>
                        <Col className="value">
                          {product?.product?.name || "N/A"}
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>Product ID</Col>
                        <Col className="value">
                          #{product?.product?.productID || "N/A"}
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>Product Date Of Listing</Col>
                        <Col className="value">
                          {Utility.formatDate(product?.product?.createdAt) ||
                            "N/A"}
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>Dimensions</Col>
                        <Col className="value">
                          {product?.requirements?.selectedSize || "N/A"}
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>Selected Color</Col>
                        <Col className="value">
                          <div className="colorOptionBox">
                            {(
                              <div
                                className="colorOption"
                                style={{
                                  backgroundColor: `${product?.requirements?.selectedColor}`,
                                }}
                              ></div>
                            ) || "N/A"}
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-1">
                        <Col>Texture</Col>
                        <Col className="value">
                          <div className="textureBox">
                            {(
                              <img
                                className="texture"
                                src={product?.requirements?.selectedPattern}
                                alt="texture"
                              />
                            ) || "N/A"}
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3 mb-1">
                        <Col>Key Features</Col>
                        <Col className="value">
                          {(
                            <>
                              <GlobalButton
                                name={
                                  isKeyFeaturesOpen[index] ? "Close" : "View"
                                }
                                background={"#dc2329"}
                                color={"#fff"}
                                padding={"0"}
                                onclick={() => toggleKeyFeatures(index)}
                              />
                            </>
                          ) || "N/A"}
                        </Col>
                        <Col sm={12} xs={12}>
                          <div
                            className={`key-features-slider ${
                              isKeyFeaturesOpen[index] ? "open" : ""
                            }`}
                          >
                            <div />
                            <h6>
                              {Utility.HTMLParser(
                                (product?.product?.keyFeatures || " ") ?? " "
                              )}
                            </h6>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-1 mb-1">
                        <Col>Additional Info</Col>
                        <Col className="value">
                          {(
                            <>
                              <GlobalButton
                                name={
                                  isDescriptionOpen[index] ? "Close" : "View"
                                }
                                background={"#dc2329"}
                                color={"#fff"}
                                padding={"0"}
                                onclick={() => toggleDescription(index)}
                              />
                            </>
                          ) || "N/A"}
                        </Col>
                        <Col sm={12} xs={12}>
                          <div
                            className={`description-slider ${
                              isDescriptionOpen[index] ? "open" : ""
                            }`}
                          >
                            <div />
                            <h6 className="descriptionparser">
                              {Utility.HTMLParser(
                                product?.requirements?.additionalInfo ?? " "
                              )}
                            </h6>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-1 mb-1">
                        <Col>Materials</Col>
                        <Col className="value">
                          {(
                            <>
                              <GlobalButton
                                name={ismaterialInfo[index] ? "Close" : "View"}
                                background={"#dc2329"}
                                color={"#fff"}
                                padding={"0"}
                                onclick={() => toggleMaterialInfo(index)}
                              />
                            </>
                          ) || "N/A"}
                        </Col>
                        <Col sm={12} xs={12}>
                          <div
                            className={`description-slider ${
                              ismaterialInfo[index] ? "open" : ""
                            }`}
                          >
                            <div />
                            <h6 className="">
                              {Utility.HTMLParser(
                                product?.product?.materialInfo ?? " "
                              ) || "N/A"}
                            </h6>
                          </div>
                        </Col>
                      </Row>
                    </Row>

                    <h6
                      style={{
                        marginTop: "8px",
                        marginBottom: "8px",
                        fontSize: "17px",
                        textDecoration: "underline",
                      }}
                    >
                      Product Images
                    </h6>
                    <Row className="ImageContainer">
                      <div className="productImage">
                        {product?.product?.images?.map((product, index) => (
                          <div key={index} className="viewProduct">
                            <img
                              className="viewProduct"
                              src={product}
                              alt=" "
                            />
                          </div>
                        ))}
                      </div>
                    </Row>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default TaskInfoModal;
