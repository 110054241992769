import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form, Container } from "react-bootstrap";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import { MdOutlineFileDownload } from "react-icons/md";
import DOMPurify from "dompurify";

const Feature = (props) => {
  const AllFeatureData = props.AllFeatureData;
  useEffect(() => {
    console.log("Product Features", AllFeatureData);
  });
  return (
    <>
      {AllFeatureData ? (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(AllFeatureData.keyFeatures),
          }}
        />
      ) : (
        "N/A"
      )}
    </>
  );
};

export default Feature;
