import React, { useEffect, useState } from "react";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { IoFilter } from "react-icons/io5";
import "./EmployeeData.css";
import { UserController } from "../../Redux/controllers/UserController.js";
import Saleryform from "../viewsaleryform/Saleryform";
import { useDispatch, useSelector } from "react-redux";
import Utility from "../../Utils/Utility.js";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner.js";
import { ResponsiveContainer } from "recharts";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import TickPlacementBars from "../../../globalcomponents/Barchart/DashboaedChart.js";
import { logOut } from "../../Redux/Slice/userSlice.js";
import * as XLSX from "xlsx";
import { PDFDocument, StandardFonts } from "pdf-lib";
const EmployeData = () => {
  const dispatch = useDispatch();
  const [allUsers, setAllUsers] = useState([]);
  const navigate = useNavigate();
  const [selectedData, setSelectedData] = useState(null);
  const [saleryState, setSaleryState] = useState(false);
  const [selectedDept, setSelectedDept] = useState("");
  const [loading, setLoading] = useState(false);

  const handlenavigate = () => {
    navigate("/addnewsubadmin");
  };

  const onclose = () => {
    setSaleryState(false);
  };

  const handleView = (item) => {
    setSelectedData(item);
    setSaleryState(true);
  };

  const token = useSelector((state) => state.user.token);

  const getAllEmp = async () => {
    try {
      setLoading(true);
      const res = await UserController.getAllEmployee(token);
      setAllUsers(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Utility.eToast(error?.response?.data?.message || error?.message);
      if (
        error?.response?.data?.message ===
        "token has expired , please login again"
      ) {
        dispatch(logOut());
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };
  const [employeeChartData, setEmployeeChartData] = useState("");
  const getEmployeeChartDetails = async () => {
    try {
      setLoading(true);
      const res = await UserController.employeeChartDetails(token);
      setEmployeeChartData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error?.message);
    } finally {
      setLoading(false);
    }
  };

  console.log("employeeChartData", employeeChartData?.employeeDetails);

  useEffect(() => {
    getAllEmp();
    getEmployeeChartDetails();
  }, [navigate, token, selectedDept]);

  const columns = [
    {
      accessorKey: "slno",
      header: "Sl No",
      size: 100,
    },
    {
      accessorKey: "emno",
      header: "Employee ID",
      size: 100,
    },
    {
      accessorKey: "fullname",
      header: "Full Name",
      size: 100,
    },
    {
      accessorKey: "phno",
      header: "Phone Number",
      size: 100,
    },
    {
      accessorKey: "dept",
      header: "Department",
      size: 100,
    },
    {
      accessorKey: "email",
      header: "Email",
      size: 100,
    },
    {
      accessorKey: "details",
      header: "Action",
      size: 100,
    },
    // {
    //   accessorKey: "action",
    //   header: "Action",
    //   size: 100,
    // },
  ];

  const data = allUsers
    ?.filter((item) =>
      !selectedDept ? true : selectedDept === item?.empDepartment
    )
    ?.map((item, index) => ({
      slno: index + 1,
      emno: item?.empEmployeeID || "N/A",
      fullname: item.name,
      phno: item.phoneNo || "N/A",
      dept: Utility.getDepartment(item?.empDepartment),
      email: item.email || "N/A",
      details: (
        <GlobalButton
          name={"View"}
          background={"#dc2329"}
          color={"#fff"}
          padding={"0"}
          onclick={() => handleView(item)}
        />
      ),
    }));

  const dataset = employeeChartData?.salaryDetails?.map((item) => {
    const arr = item?.month?.split(" ");
    return {
      month: `${arr[0]?.slice(0, 3)} ${arr[1]?.slice(2)}`,
      totalNetSalary: item?.totalNetSalary,
    };
  });
  // console.log(dataset)

  const valueFormatter = Utility.valueFormatter;

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(
      allUsers.map((user, index) => ({
        "Sl No": index + 1,
        "Employee ID": user.empEmployeeID || "N/A",
        Name: user.name || "N/A",
        Email: user.email || "N/A",
        "Phone Number": user.phoneNo || "N/A",
        Department: Utility.getDepartment(user.empDepartment),
      }))
    );

    const chartData = [
      [
        "Department",
        "Manager",
        "Accounts",
        "Delivery",
        "Inventory",
        "Production",
        "Sales",
      ],
      [
        "Employee Count",
        employeeChartData?.employeeDetails?.manager || 0,
        employeeChartData?.employeeDetails?.accounts || 0,
        employeeChartData?.employeeDetails?.delivery || 0,
        employeeChartData?.employeeDetails?.procurement_and_inventory || 0,
        employeeChartData?.employeeDetails?.production || 0,
        employeeChartData?.employeeDetails?.sales || 0,
      ],
    ];

    const chartWs = XLSX.utils.aoa_to_sheet(chartData);

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Employees");
    XLSX.utils.book_append_sheet(wb, chartWs, "Employee Chart Data");

    XLSX.writeFile(wb, "EmployeeData.xlsx");
  };

  const exportToPDF = async () => {
    const pdfDoc = await PDFDocument.create();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const pageWidth = 595.28;
    const pageHeight = 841.89;
    const margin = 50;
    const lineHeight = 20;

    const createPage = () => {
      const page = pdfDoc.addPage([pageWidth, pageHeight]);
      const { height } = page.getSize();
      return { page, y: height - margin };
    };

    let { page, y } = createPage();

    const checkNewPage = () => {
      if (y < margin) {
        const newPage = createPage();
        page = newPage.page;
        y = newPage.y;
      }
    };

    const drawCell = (text, x, y, width, height, font, fontSize) => {
      const padding = 5;
      const textX = x + padding;
      const textY = y - height / 2 + fontSize / 2;
      page.drawText(text, {
        x: textX,
        y: textY,
        size: fontSize,
        font,
        maxWidth: width - 2 * padding,
      });
    };

    const drawTable = (header, rows, columnWidths, startX, startY) => {
      let y = startY;
      const cellHeight = lineHeight + 10;

      header.forEach((text, i) => {
        const x = startX + columnWidths.slice(0, i).reduce((a, b) => a + b, 0);
        drawCell(text, x, y, columnWidths[i], cellHeight, font, 12);
      });
      y -= cellHeight;

      rows.forEach((row) => {
        checkNewPage();
        row.forEach((text, i) => {
          const x =
            startX + columnWidths.slice(0, i).reduce((a, b) => a + b, 0);
          drawCell(
            text.toString(),
            x,
            y,
            columnWidths[i],
            cellHeight,
            font,
            10
          );
        });
        y -= cellHeight;
      });

      return y;
    };

    page.drawText("Employee Data", { x: margin, y, size: 18, font });
    y -= 40;

    const employeeHeader = [
      "Sl No",
      "Employee ID",
      "Name",
      "Email",
      "Phone Number",
      "Department",
    ];

    const employeeColumnWidths = [40, 80, 100, 150, 100, 100];
    const employeeRows = allUsers.map((user, index) => [
      index + 1,
      user.empEmployeeID || "N/A",
      user.name || "N/A",
      user.email || "N/A",
      user.phoneNo || "N/A",
      Utility.getDepartment(user.empDepartment),
    ]);

    y = drawTable(
      employeeHeader,
      employeeRows,
      employeeColumnWidths,
      margin,
      y
    );

    checkNewPage();
    y -= 40;
    page.drawText("Employee Chart Data", { x: margin, y, size: 18, font });
    y -= 40;

    const chartHeader = [
      "Department",
      "Manager",
      "Accounts",
      "Delivery",
      "Inventory",
      "Production",
      "Sales",
    ];
    const chartColumnWidths = [100, 80, 80, 80, 80, 80, 80];
    const chartRows = [
      [
        "Employee Count",
        employeeChartData?.employeeDetails?.manager || 0,
        employeeChartData?.employeeDetails?.accounts || 0,
        employeeChartData?.employeeDetails?.delivery || 0,
        employeeChartData?.employeeDetails?.procurement_and_inventory || 0,
        employeeChartData?.employeeDetails?.production || 0,
        employeeChartData?.employeeDetails?.sales || 0,
      ],
    ];

    y = drawTable(chartHeader, chartRows, chartColumnWidths, margin, y);

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "EmployeeData.pdf";
    link.click();
  };

  return (
    <>
      {saleryState ? (
        <Saleryform onclose={onclose} selectedData={selectedData} />
      ) : (
        <div className="mt-3 emptapblepaddding">
          <Row className="graphContainer">
            <Col className="piChart">
              <p
                style={{
                  color: "black",
                  margin: "20px",
                  textDecoration: "underline",
                }}
              >
                <strong>Employee Charts</strong>
              </p>
              {!employeeChartData ? (
                <div className="spinnerrowitem">
                  <CircularIndeterminate />
                </div>
              ) : (
                <PieChart
                  series={[
                    {
                      data: [
                        {
                          id: 0,
                          value: employeeChartData?.employeeDetails?.manager,
                          label: "Manager",
                          color: "#FACC15",
                        },
                        {
                          id: 1,
                          value: employeeChartData?.employeeDetails?.accounts,
                          label: "Accounts",
                          color: "#F59E0B",
                        },
                        {
                          id: 2,
                          value: employeeChartData?.employeeDetails?.delivery,
                          label: "Delivery",
                          color: "#EC4899",
                        },
                        {
                          id: 3,
                          value:
                            employeeChartData?.employeeDetails
                              ?.procurement_and_inventory,
                          label: "Inventory",
                          color: "#3B82F6",
                        },
                        {
                          id: 4,
                          value: employeeChartData?.employeeDetails?.production,
                          label: "Production",
                          color: "#14B8A6",
                        },
                        {
                          id: 5,
                          value: employeeChartData?.employeeDetails?.sales,
                          label: "Sales",
                          color: "#6366F1",
                        },
                      ],
                    },
                  ]}
                  height={250}
                />
              )}
            </Col>
            <Col className="barChart">
              <p style={{ color: "black", textDecoration: "underline" }}>
                <strong>Last 6 Month Employee Salary</strong>
              </p>
              {!dataset ? (
                <div className="spinnerrowitem">
                  <CircularIndeterminate />
                </div>
              ) : (
                <TickPlacementBars
                  data={dataset}
                  accessKey="totalNetSalary"
                  widthOfBar={0.85}
                />
              )}
            </Col>
          </Row>

          <Row className="second-box" style={{ marginTop: "40px" }}>
            <Col>
              <button
                id="btnnn"
                onClick={handlenavigate}
                className="addNewSubButton"
              >
                <IoIosAddCircleOutline id="plusicon" /> Add New Employee
              </button>
              <button id="btnnn" className="selectButton">
                <Form.Select
                  onChange={(e) => setSelectedDept(e.target.value)}
                  aria-label="Default select example"
                  className="multiselect-dropdown"
                >
                  <option value={""}>
                    {" "}
                    <IoFilter />
                    All Users
                  </option>
                  <option value="sales">Sales team</option>
                  <option value="production">Production team</option>
                  <option value="procurement_and_inventory">
                    Inventory Team
                  </option>
                  <option value="delivery">Delivery team</option>
                  <option value="manager">Reporting Manager</option>
                  <option value="accounts">Account Team</option>
                  {/* <option value="7">Agent</option> */}
                </Form.Select>
              </button>
            </Col>
          </Row>

          <Row className="tablepaddinginner">
            {loading ? (
              <div className="spinnerrowitem">
                <CircularIndeterminate />
              </div>
            ) : (
              <div className="p-2" style={{
                backgroundColor: "white",
              }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                  className="mb-2"
                >
                  <button className="btn btn-success me-2" onClick={exportToExcel}>
                    Export Excel
                  </button>
                  <button className="btn btn-primary me-3" onClick={exportToPDF}>
                    Export PDF
                  </button>
                </div>
                <DataTable columns={columns} data={data} />
              </div>
            )}
          </Row>
        </div>
      )}
    </>
  );
};

export default EmployeData;
