import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UserController } from "../controllers/UserController";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  isProfileCompleted: false,
  username: null,
  email: null,
  token: null,
  userInfo: {},
  userid: null,
};
// export const login = createAsyncThunk(
//   "login",
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await UserController.login(data);
//       console.log(response, "userSlice");
//       return response;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   }
// );

export const login = createAsyncThunk(
  "login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.login(data);
      return response.data; // Return the response data directly
    } catch (error) {
      return rejectWithValue(error.response.data); // Return the error response data
    }
  }
);

export const addNewProduct = createAsyncThunk(
  "addNewProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.addProductItem(data);
      console.log(response, "add new product in userslice");
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProductItem = createAsyncThunk(
  "updateForm",
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserController.updateProductItem(data);
      console.log(response, "updated user in userslice");
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// export const addNewcategory = createAsyncThunk(
//   "addnewcategory",async()
// )

// export const SingleImageUploader = createAsyncThunk(
//   "SingleImageUploader",
//   async (data, { rejectWithValue }) => {
//     try {
//       const response = await UserController.SingleImageUploader(data);
//       console.log(
//         "singleimage-----------======================>>>>>fetched",
//         response
//       );
//       return response.data.doc;
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setUserInfo: (state, action) => {
      console.log("USER INFO.....", action.payload);
      state.userInfo = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    logOut: (state) => {
      state.isLoading = false;
      state.userid = null;
      state.token = null;
      state.userInfo = null;
      state.isError = false;
      state.isSuccess = false;
      state.email = null;
      state.username = null;
    },
  },
  extraReducers: (builder) => {
    //user login case
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
    });

    builder.addCase(login.fulfilled, (state, action) => {
      // state.isLoading = false;
      // state.isSuccess = true;
      // state.isError = false;
      console.log("Action payload:", action.payload); // Log the action payload
      state.token = action.payload.token;
      state.userid = action.payload?._id;
      // state.email = action.payload.userData.user.email;
      state.userInfo = action.payload.data;
      console.log("Token set:", state.token); // Log the token after setting it
      console.log(
        "===================------------------=================-------------->>>>",
        action.payload.token
      );
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      // You can access the error message from action.payload
      console.error(action.payload);
    });
    builder.addCase(addNewProduct.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(addNewProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
    });
    builder.addCase(addNewProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
    });
    builder.addCase(updateProductItem.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateProductItem.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.isError = false;
    });
    builder.addCase(updateProductItem.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
    });

    // builder.addCase(SingleImageUploader.pending, (state, action) => {
    //   state.isLoading = true;
    // });
    // builder.addCase(SingleImageUploader.fulfilled, (state, action) => {
    //   console.log("Image fetched succefully");
    //   // state.isLoading = false;
    //   // state.isSuccess = true;
    //   // state.isError = false;
    // });
    // builder.addCase(SingleImageUploader.rejected, (state, action) => {
    //   state.isLoading = false;
    //   state.isSuccess = false;
    //   state.isError = true;
    // });
  },
});

export const { logOut, setToken, setIsLoading, setUserInfo } =
  userSlice.actions;
export default userSlice.reducer;
