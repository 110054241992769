import React, { useEffect, useState } from "react";
import "./TopNavBar.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import { IoSettingsOutline } from "react-icons/io5";
import { MdNotificationsNone } from "react-icons/md";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { GoDotFill } from "react-icons/go";
import mmlogo from "../../Admin/assets/dashboard/mmlogo.png";
import { useDispatch, useSelector } from "react-redux";
// import { setLogout } from "../../Features/Auth/authSlice";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../Admin/Redux/Slice/userSlice";
import Notification from "../../Admin/pages/Modals/Notification/Notification";
import { UserController } from "../../Admin/Redux/controllers/UserController";
import LogoutModal from "../../Admin/pages/Modals/LogoutModal/LogoutModal";

function TopNavBar() {
  const token = useSelector((state) => state.user.token);
  const userId = useSelector((state) => state.user.userInfo._id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logOut());
    localStorage.clear()
    navigate("/");
    // dispatch(setLogout())
    // alert('You have been successfully logged out.')
  };

  const [modal, setModal] = useState(false);
  // console.log("modal", modal);
  const [allNotification, setAllNotification] = useState("");
  const getNotification = async () => {
    try {
      const res = await UserController.getAllNotification(token, userId);
      // console.log("RESP", res.data.data);
      setAllNotification(res.data.data);
    } catch (error) {
      console.log("Error", error);
    }
  };
  
  useEffect(() => {
    getNotification();
  }, [setModal,modal]);

  const handleConfirmLogout = (value) => {
    if (value === "ok") {
      handleLogout();
    }
    setModal(false);
  };

  const [modal2, setModal2] = useState(false);
  const handelSubmit = () => {
    setModal2(true);
    // handleLogout()
  }

  return (

    <Container>
      {/* {
      modal && <Notification/>
     } */}
      <Row>
        <Col className="tnb2">
          {/* <div>
            <IoSettingsOutline className="tnb3" />
          </div> */}
          <div className="tnb5"
            onClick={() => setModal(true)}>
            <MdNotificationsNone className="tnb3"
            />
            <span>
              {allNotification.length > 0 && <GoDotFill className="tnb6" />}
            </span>
          </div>
          <div className="tnb4" onClick={handelSubmit}>
            Logout
            <span>
              <RiLogoutBoxRLine className="tnb3" />
            </span>
          </div>
          {/* <div className="tnb1">
            <Image src={mmlogo} alt="" className="tnb7" />
          </div> */}
        </Col>
      </Row>

      {
        modal && <Notification
          show={modal}
          onHide={() => {
            setModal(false);
          }}
        />
      }
      {
        modal2 && (
          <LogoutModal
            show={modal2}
            onHide={() => {
              setModal2(false);
            }}
            onConfirm={handleConfirmLogout}
          />
        )
      }
    </Container>


  );
}

export default React.memo(TopNavBar);
