import React, { useRef, useState } from "react";
import { Modal, Container } from "react-bootstrap";
import "./ViewQrCode.css";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";
import mmLogo from "../../../assets/dashboard/mmlogo.png";
import Utility from "../../../Utils/Utility";
import { BsPrinter } from "react-icons/bs";
import { MdOutlineFileDownload } from "react-icons/md";

const ViewQrCode = (props) => {
  const { selectedProduct } = props;
  const printQrRef = useRef(null);
  const downloadQrRef = useRef(null);

  const [showForDownload, setShowForDownload] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => printQrRef.current,
    documentTitle: `${selectedProduct.productID}_qrcode`,
    removeAfterPrint: true,
    pageStyle: `
      @page {
        size: auto;
        margin: 10mm;
      }
      @media print {
        body {
          margin: 0;
          -webkit-print-color-adjust: exact;
        }
      }
    `,
  });

  const downloadQr = () => {
    setShowForDownload(true);
    setTimeout(() => {
      const qrCodeElement = downloadQrRef.current;
      if (!qrCodeElement) {
        Utility.eToast("QR code element not found");
        setShowForDownload(false);
        return;
      }

      html2canvas(qrCodeElement)
        .then((canvas) => {
          const image = canvas.toDataURL("image/png");
          const link = document.createElement("a");
          link.href = image;
          link.download = `${selectedProduct.productID}_qrcode.png`;
          link.click();
          setShowForDownload(false);
        })
        .catch((error) => {
          console.log("Error in QR code", error);
          setShowForDownload(false);
        });
    }, 100);
  };

  return (
    <>
      <Container className="p-2">
        <Modal id="transactionModal" {...props} size="lg">
          <Modal.Header closeButton style={{ background: "#F4F3F0" }}>
            <Modal.Title style={{ color: "black", background: "#F4F3F0" }}>
              Product QR Code
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
            <div className="qrCodeBox">
              <div
                className="qrCode"
                style={{ height: "310px", width: "310px", padding: "10px" }}
              >
                <QRCode
                  size={300}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={selectedProduct._id}
                  viewBox={`0 0 256 256`}
                />
              </div>
              {/* qr invisible , but required to print and download */}
              <div style={{ display: "none" }}>
                <ComponentToPrint
                  selectedProduct={selectedProduct}
                  ref={printQrRef}
                />
              </div>{" "}
              <div className="d-flex align-items-center gap-5">
                <button
                  className="downloadButton"
                  onClick={downloadQr}
                  style={{ backgroundColor: "#dc2329", color: "white" }}
                >
                  <MdOutlineFileDownload /> Download
                </button>
                <button
                  className="downloadButton"
                  onClick={handlePrint}
                  style={{ backgroundColor: "#dc2329", color: "white" }}
                >
                  <BsPrinter /> Print
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Container>

      {showForDownload && (
        <div
          style={{ position: "absolute", top: "-9999px" }}
          ref={downloadQrRef}
        >
          <ComponentToPrint selectedProduct={selectedProduct} />
        </div>
      )}
    </>
  );
};

export default ViewQrCode;

const ComponentToPrint = React.forwardRef((props, ref) => {
  const { selectedProduct } = props;

  return (
    <div
      ref={ref}
      style={{
        width: "200mm",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "10mm",
        margin: "0 auto",
      }}
    >
      <div style={{ marginBottom: "5rem" }}>
        <img src={mmLogo} alt="logo" className="sbm18" />
        <span
          style={{
            color: "#642315",
            fontWeight: "600",
            fontFamily: "Roboto",
            fontSize: "20px",
          }}
        >
          JBS
        </span>
      </div>
      <QRCode
        size={300}
        style={{ height: "auto", maxWidth: "100%", width: "200mm" }}
        value={selectedProduct._id}
        viewBox={`0 0 256 256`}
      />
    </div>
  );
});
