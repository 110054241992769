import React, { useState } from "react";
import { Modal, Button, Row, Col, Form, Container } from "react-bootstrap";
import "./AddVendorModal.css";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import { MdOutlineFileDownload } from "react-icons/md";

const AddVendorModal = (props) => {
  return (
    <Container className="">
      <Modal id="" {...props}>
        <Modal.Header closeButton style={{ background: "#E4CDAA" }}>
          <Modal.Title style={{ color: "#642315E5", background: "#E4CDAA" }}>
            Add New Vendor Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-1">
            <Col md={7}>
              Vendor Name
              <br />
              <Form.Control
                placeholder="Enter The Vendor Name"
                className="amthirdcol"
              />
            </Col>
            <Col md={5}>
              Phone Number
              <br />
              <Form.Control
                type="text"
                placeholder="Enter The Phone Number"
                className="amthirdcol"
              />
            </Col>
          </Row>
          <Row className="mt-1">
            <Col md={6}>
              GST No.
              <br />
              <Form.Control
                placeholder="Enter The Salary Amount"
                className="amthirdcol"
              />
            </Col>
            <Col md={6}>
              Category
              <br />
              <Form.Control
                type="text"
                placeholder="Enter The Vendors Category Type"
                className="amthirdcol"
              />
            </Col>
          </Row>
          <Row>
            <Col>
                Address<br/>
                <Form.Control type="text"
                placeholder="Enter the Vendor Address"
                className="amthirdcol"/>
            </Col>
          </Row>
          <Row className="subadminlastbutton">
          <GlobalButton
            name={"Discard"}
            padding={'0'}
            border={'1px solid #642315'}
            fontFamily="Roboto"
            // bordercolor={"#595959"}
            background={"#E4CDAA"}
            color={"#642315"}
            width={"3rem"}
          />
          <GlobalButton
            name={"Submit"}
            padding={'0'}
            border={'1px solid #642315'}
            fontFamily="Roboto"
            bordercolor={"#595959"}
            background={"#E4CDAA"}
            color={"#642315"}
            width={"3rem"}
          />
        </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default AddVendorModal;
