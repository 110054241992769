import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { GiShoppingCart } from "react-icons/gi";
import "./Card.css";

const Card = ({ name, customer, icon, ordernumber, customernumber }) => {
  const cardValue = customernumber;

  const [timePeriod, setTimePeriod] = useState("week");

  // console.log("timePeriod", timePeriod);
  // console.log("cardValue", customernumber);

  return (
    <>
      <Col className="cardslice">
        <Row className="dashcaard">
          <Col>
            {/* <GiShoppingCart /> */}
            {icon}
          </Col>
          <Col className="flexendof">
            <select
              name="period"
              id="period"
              // data-placeholder="This week"
              className="cardselect"
              value={timePeriod}
              onChange={(e) => setTimePeriod(e.target.value)}
            >
              <option value="week">This Week</option>
              <option value="month">This Month</option>
              <option value="year">This Year</option>
            </select>
          </Col>
        </Row>
        <Row className="dashrow">
          <Col className="">
            <h5 className="OrdertNameHeading">{name}</h5>
            <p>
              <p8 className="yoyo">{ordernumber || 0} </p8>
            </p>
          </Col>
          <Col className="flexendof">
            <div>
              <h6>{customer}</h6>
              <p>
                {timePeriod === "week"
                  ? cardValue[0]
                  : timePeriod === "month"
                  ? cardValue[1]
                  : cardValue[2]}
                {/* <span className="text-success">+15.80%</span> */}
              </p>
            </div>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export const PendingItems = ({
  name,
  customer,
  icon,
  ordernumber,
  customernumber,
  pending,
  pendinginumber,
}) => {
  return (
    <Col className="cardslice">
      <Row className="dashcaard">
        <Col>{icon}</Col>
        <Col className="flexendof">
          <select
            name="period"
            id="period"
            data-placeholder="This week"
            className="cardselect"
          >
            <option value="">This Week</option>
            <option value="volvo">Month</option>
            <option value="saab">Year</option>
          </select>
        </Col>
      </Row>
      <Row className="dashrow">
        <Col>
          <h5>{name}</h5>
          <p>
            <p8 className="yoyo">{ordernumber}</p8>
            <span className="text-muted">+0.00%</span>
          </p>
        </Col>
        <Col>
          <h5>{pending}</h5>
          <p>{pendinginumber}</p>
        </Col>
        <Col className="lastcolomnofpennding">
          <div>
            <h6 id="cardid">{customer}</h6>
            <p>
              {customernumber}
              <span className="text-success">+15.80%</span>
            </p>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default Card;
