// import React from "react";
// import {
//   MaterialReactTable,
//   useMaterialReactTable,
// } from "material-react-table";
// import "./DataTable.css";

// const DataTable = ({ columns, data }) => {
//   const table = useMaterialReactTable({
//     columns,
//     data,
//     muiTableHeadCellProps: {
//       sx: {
//         background: "#F4F3F0",
//         color: "#642315",
//       },
//     },
//   });
//   return <MaterialReactTable table={table} />;
// };

// export default DataTable;

import React from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import "./DataTable.css";

const DataTable = ({ columns, data, page }) => {
  const table = useMaterialReactTable({
    columns: columns.map((column) => ({
      ...column,
      render: (rowData) => {
        return column.accessorKey === "action"
          ? rowData.action
          : rowData[column.accessorKey];
      },
    })),
    data,
    initialState: {
      pagination: {
        pageSize: page || 10,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        background: "#F4F3F0",
        color: "#251C1A",
      },
    },
  });
  return <MaterialReactTable table={table} />;
};

export default DataTable;
