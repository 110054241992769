import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import TransactionDetailModal from "../Modals/TransactionDetailModal/TransactionDetailModal";
import TransactionDetailsForm from "../Modals/TransactionDetailsForm/TransactionDetailsForm";
const TransactionExpense = () => {
  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const handleModal = () => {
    setShowModal(true);
  };

  const handleSecondModal = () => {
    setShowModal(false);
    setShowSecondModal(true);
  };

  const columns = [
    {
      accessorKey: "slno",
      header: "Sl No",
      size: 30,
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 100,
    },
    {
      accessorKey: "orderid",
      header: "Order Id",
      size: 50,
    },
    {
      accessorKey: "cname",
      header: "Customer Name",
      size: 50,
    },
    {
      accessorKey: "pname",
      header: "Product Name",
      size: 50,
    },

    {
      accessorKey: "amount",
      header: "Total Amount",
      size: 50,
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 50,
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 50,
    },
  ];

  const data = [
    {
      slno: "1",
      date: "23/05/2000",
      orderid: "12345",
      cname: "jyoti prakash",
      pname: "Air Cooler",
      amount: "45000",
      status: "pending",
      action: (
        <GlobalButton
          name={"View"}
          background={"#E4CDAA"}
          color={"#642315"}
          padding={"0"}
          onclick={handleModal}
        />
      ),
    },
    {
      slno: "2",
      date: "23/05/2000",
      orderid: "12345",
      cname: "jyoti prakash",
      pname: "Air Cooler",
      amount: "45000",
      status: "pending",
      action: (
        <GlobalButton
          name={"View"}
          background={"#E4CDAA"}
          color={"#642315"}
          padding={"0"}
        />
      ),
    },
    {
      slno: "3",
      date: "23/05/2000",
      orderid: "12345",
      cname: "jyoti prakash",
      pname: "Air Cooler",
      amount: "45000",
      status: "pending",
      action: (
        <GlobalButton
          name={"View"}
          background={"#E4CDAA"}
          color={"#642315"}
          padding={"0"}
        />
      ),
    },
  ];
  return (
    <Container className="mb-3">
      {showModal && (
        <TransactionDetailModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          onHideFirstModal={handleSecondModal}
        />
      )}
      {showSecondModal && (
        <TransactionDetailsForm
          show={showSecondModal}
          onHide={() => setShowSecondModal(false)}
        />
      )}
      <Row>
        <h5 style={{ color: "#642315" }}>Expenses Data</h5>
      </Row>
      <Row className="mt-1 p-1">
        <Col className="transactionpadding">
          <DataTable columns={columns} data={data} />
        </Col>
      </Row>
    </Container>
  );
};

export default TransactionExpense;
