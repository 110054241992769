import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import TransactionInfoSales from "../Modals/TransactionInfoSales/TransactionInfoSales";

const TransactionSales = () => {
  const [showSalesModal,setShowSalesModal] = useState(false);
  const handleModal = ()=>{
    console.log("modal");
      setShowSalesModal(true);
  }
  const columns = [
    {
      accessorKey:"slno",
      header:"Sl No",
      size:10
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 50,
    },
    {
      accessorKey: "desc",
      header: "Description",
      size: 50,
    },
    {
      accessorKey: "amount",
      header: "Amount",
      size: 50,
    },
    {
      accessorKey: "category",
      header: "Category",
      size: 50,
    },

    {
      accessorKey: "payment",
      header: "Payment Method",
      size: 50,
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 50,
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 50,
    },
  ];

  const data = [
    {
      slno:"1",
      date: "23/05/2000",
      desc: "Office Supplies",
      amount: "$300",
      category: "Marketing",
      payment: "CARD",
      status: "pending",
      action: <GlobalButton name={"View"} background={'#E4CDAA'} color={'#642315'} padding={'0'} onclick={handleModal}/>,
    },
    {
      slno:"2",
      date: "23/05/2000",
      desc: "Office Supplies",
      amount: "$300",
      category: "Marketing",
      payment: "CARD",
      status: "pending",
      action: <GlobalButton name={"View"} background={'#E4CDAA'} color={'#642315'} padding={'0'}/>,
    },
    {
      slno:"3",
      date: "23/05/2000",
      desc: "Office Supplies",
      amount: "$300",
      category: "Marketing",
      payment: "CARD",
      status: "pending",
      action: <GlobalButton name={"View"} background={'#E4CDAA'} color={'#642315'} padding={'0'}/>,
    },
    // {
    //   slno:"4",
    //   date: "23/05/2000",
    //   desc: "Office Supplies",
    //   amount: "$300",
    //   category: "Marketing",
    //   payment: "CARD",
    //   status: "pending",
    //   action: <GlobalButton name={"View"} background={'#E4CDAA'} color={'#642315'} padding={'0'}/>,
    // },
    // {
    //   slno:"5",
    //   date: "23/05/2000",
    //   desc: "Office Supplies",
    //   amount: "$300",
    //   category: "Marketing",
    //   payment: "CARD",
    //   status: "pending",
    //   action: <GlobalButton name={"View"} background={'#E4CDAA'} color={'#642315'} padding={'0'}/>,
    // },
  ];

  return (
    <Container className="mb-2">
      {showSalesModal && <TransactionInfoSales show={showSalesModal} onHide={()=>{
        setShowSalesModal(false);
      }}/>}
      <Row>
        <h5 style={{color:"#642315"}}>Sales Data</h5>
      </Row>
      <Row className="mt-1">
        <Col className="p-2">
          <DataTable columns={columns} data={data} />
        </Col>
      </Row>
    </Container>
  );
};

export default TransactionSales;