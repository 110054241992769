import { ToastContainer, Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import parse from "html-react-parser";

const sToast = (msg) => {
  toast.success(msg, {
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
  });
};

const eToast = (msg) => {
  toast.error(msg, {
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Slide,
  });
};

function formatDate(dateString) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "N/A";
  }

  const day = date.getUTCDate();
  const month = months[date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return `${day} ${month}, ${year}`;
}

const getStatusColor = (status) => {
  switch (status) {
    case "pending":
      return "#C03744";
    case "rejected":
      return "#855CD0";

    case "confirmed":
      return "#457B9D";
    case "processing":
      return "#EB9F14";
    case "progress":
      return "#EB9F14";
    case "shipped":
      return "#855CD0";
    case "delivered":
      return "#15B097";
    case "completed":
      return "#15B097";
    case "approved":
      return "#15B097";
    case false:
      return "#C03744";
    case true:
      return "#15B097";
    default:
      return "#C03744";
  }
};

const getDepartment = (dept) => {
  switch (dept) {
    case "sales":
      return "Sales";
    case "production":
      return "Production";
    case "procurement_and_inventory":
      return "Inventory";
    case "delivery":
      return "Delivery";
    case "accounts":
      return "Accountant";
    case "manager":
      return "Manager";

    default:
      return "N/A";
  }
};

const MoneyFormatter = (value) => {
  if (value == null) return "N/A";

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(value);
};
const HTMLParser = (htmlString) => {
  return parse(htmlString);
};

const getPaymentMethod = (paymentMethod) => {
  switch (paymentMethod) {
    case "bank_transfer_or_upi":
      return "Bank Transfer / UPI";
    case "cash":
      return "Cash";
    case "check":
      return "Check";
    default:
      return "N/A";
  }
};

const formatNumber = (number) => {
  if (number >= 1e9) {
    return (number / 1e9).toFixed(1) + "B";
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(1) + "M";
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(1) + "K";
  } else {
    return number.toString();
  }
};

const valueFormatter = (value) => {
  if (value >= 1e9) {
    return (value / 1e9).toFixed(1).replace(/\.0$/, "") + "b";
  }
  if (value >= 1e6) {
    return (value / 1e6).toFixed(1).replace(/\.0$/, "") + "m";
  }
  if (value >= 1e3) {
    return (value / 1e3).toFixed(1).replace(/\.0$/, "") + "k";
  }
  return value;
};

const convertMonthsToNumbers = (data) => {
  const monthMapping = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  return data.map((item) => {
    const [monthName] = item.month.split(" ");
    return monthMapping[monthName];
  });
};

const getShortMonthName = (value) => {
  const shortMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  if (value < 1 || value > 12) {
    return " ";
  }

  return shortMonthNames[value - 1];
};

const Utility = {
  sToast,
  eToast,
  formatDate,
  getStatusColor,
  HTMLParser,
  MoneyFormatter,
  getDepartment,
  getPaymentMethod,
  formatNumber,
  valueFormatter,
  convertMonthsToNumbers,
  getShortMonthName,
};

export default Utility;
