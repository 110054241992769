import React, { useState } from "react";
import { Row, Col, Modal, Container, Form } from "react-bootstrap";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import "./GenerateQRModal.css";
import dining from "../../../assets/items/dining.png";
import vase from "../../../assets/items/vase.png";
import { AiOutlineQrcode } from "react-icons/ai";
const GenerateQRModal = (props) => {
  const handleModal = () => {
    props.onOpenThirdModal();
  };
  return (
    <Container className="p-2">
      <Modal id="transactionModal" {...props} size="lg">
        <Modal.Header closeButton style={{ background: "#E4CDAA" }}>
          <Modal.Title style={{ color: "#642315E5", background: "#E4CDAA" }}>
            Product QR code
          </Modal.Title>
        </Modal.Header>
        <Modal.Body id="qrModalBody">
          {/* first row  */}
          <Row className="">
            <Row className="m-1">
              <Col>Product Name</Col>
              <Col className="value">Harmony Dining Table Set</Col>
            </Row>
            <Row className="m-1">
              <Col>Product ID</Col>
              <Col className="value">TRX2024001</Col>
            </Row>
            <Row className="m-1">
              <Col>Materials</Col>
              <Col className="value">
                Solid wood (walnut/oak/espresso finish) table, solid wood frame
                with upholstered seats
              </Col>
            </Row>
            <Row className="m-1">
              <Col>Texture</Col>
              <Col className="value">Smooth wood grain</Col>
            </Row>
            <Row className="m-1">
              <Col>Key Features</Col>
              <Col className="value">
                Solid wood construction, Sleek design, Extendable tabletop ,
                Comfortable seating, Versatile finish options
              </Col>
            </Row>
            <Row className="m-1">
              <Col>Additional Info</Col>
              <Col className="value">
                Solid wood construction, Sleek design, Extendable tabletop ,
                Comfortable seating, Versatile finish options{" "}
              </Col>
            </Row>
          </Row>
          <form>
            <Row className="m-1">
              <Col md={6}>
                <Form.Label>Color</Form.Label>
                <Form.Control
                  placeholder="Write Color of Product"
                  type="text"
                  className="QRinput"
                ></Form.Control>
              </Col>
              <Col md={6}>
                <Form.Label>Dimensions</Form.Label>
                <Form.Control
                  placeholder="Enter dimensions of Product"
                  type="text"
                  className="QRinput"
                ></Form.Control>
              </Col>
            </Row>
            <Row className="m-1">
              <Col md={6}>
                <Form.Label>Choose Production Start Date</Form.Label>
                <Form.Control
                  placeholder="Enter the production start date"
                  type="date"
                  className="QRinput"
                ></Form.Control>
              </Col>
              <Col md={6}>
                <Form.Label>Choose Production Start Date</Form.Label>
                <Form.Control
                  placeholder="Enter the production end date"
                  type="date"
                  className="QRinput"
                ></Form.Control>
              </Col>
            </Row>
            <Row className="infoText">
              <Form.Label>Additional Info</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Additional info you Want to store in QR"
                className="w-100 QRinput"
              />
            </Row>
          </form>

          {/* second row  */}
          <h6 id="productImgHead">Product Images</h6>
          <Row className="ImageContainer">
            <Col md={3} className="productImage">
              <img src={dining} alt="" className="w-100" />
            </Col>
            <Col md={3} className="productImage">
              <img src={vase} alt="" className="w-100" />
            </Col>
            <Col md={3} className="productImage">
              <img src={dining} alt="" className="w-100" />
            </Col>
            <Col md={3} className="productImage">
              <img src={vase} alt="" className="w-100" />
            </Col>
          </Row>
          <Row>
            <Col className="subadminlastbutton">
              <button id="generateQR" onClick={handleModal}>
                {" "}
                <AiOutlineQrcode /> Generate
              </button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default GenerateQRModal;
