import React, { useEffect, useRef, useState } from "react";
import "./productform.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import Multiselect from "multiselect-react-dropdown";
import Utility from "../../Utils/Utility";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdDelete } from "react-icons/md";
import { addNewProduct, updateProductItem } from "../../Redux/Slice/userSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { UserController } from "../../Redux/controllers/UserController";
import { FiPlusCircle } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

const Productform = () => {
  const [images, setImages] = useState([0]);
  const [color, setColor] = useState([]);
  const [selectedColor, setSelectedColor] = useState("#642315");
  const [selectedSize, setselectedSize] = useState("");
  const [size, setSize] = useState([]);
  const [pattern, setPattern] = useState([]);
  const [initialValues, setInitialValues] = useState(null);
  const { userInfo } = useSelector((state) => state?.user);
  const [allData, setAllData] = useState([]);
  const [showAddColor, setShowAddColor] = useState(false);
  const [showSize, setshowSize] = useState(false);
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
    category: "",
    keyFeatures: "",
    materialInfo: "",
    description: "",
    price: "",
    hsnCode: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingPicture, setLoadingPicture] = useState(false);
  const [quillValue, setQuillValue] = useState(" ");
  const fileInputRef = useRef(null);
  const [baseAmount, setBaseAmount] = useState("");
  const [gst, setGst] = useState("0");
  const [grossAmount, setGrossAmount] = useState("");
  const [stack, setStack] = useState(0);
  const inputRef = useRef(null);
  const token = useSelector((state) => state?.user?.token);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isEditing, productToEdit } = location.state || {};
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (isEditing && productToEdit) {
      setInitialValues(productToEdit);
      setInputs(productToEdit);
    }
  }, [isEditing, productToEdit]);

  const handleImageChange = async (e) => {
    setLoadingPicture(true);
    const file = e.target.files[0];
    console.log(file);

    if (file) {
      try {
        const formData = new FormData();
        formData.append("doc", file);
        const response = await UserController.SingleImageUploader(formData);

        const imageUrl = response.data.doc;

        setImages((prevImages) => [imageUrl, ...prevImages]);
        setLoadingPicture(false);
      } catch (error) {
        console.error("Error uploading image:", error);
        setLoadingPicture(false);
      }
    }
  };

  const handleReset = () => {
    setImages([0]);
    setColor([]);
    setSize([]);
    setPattern([]);
    setAllData([]);
    setBaseAmount("");
    setGrossAmount("");
    setGst("");
    setInputs({
      name: "",
      category: "",
      keyFeatures: "",
      materialInfo: "",
      description: "",
      price: "",
      hsnCode: "",
    });
    setTouched(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handlePatternChange = async (e) => {
    setLoading(true);
    console.log("Event:", e);
    const file = e.target.files[0];
    console.log("File", file);

    if (file) {
      try {
        const formData = new FormData();
        formData.append("doc", file);
        const response = await UserController.SingleImageUploader(formData);

        const imageUrl = response.data.doc;

        setPattern((prevImages) => [imageUrl, ...prevImages]);
        setLoading(false);
      } catch (error) {
        console.error("Error uploading image:", error);
        setLoading(false);
      }
    }
  };

  const handlechange = (e) => {
    const { name, value } = e.target;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleAddProduct = async (event) => {
    event.preventDefault();
    setTouched(true);

    const {
      name,
      category,
      keyFeatures,
      materialInfo,
      description,
      price,
      hsnCode,
    } = inputs;
    console.log(inputs, "=======================");
    const priceNumber = Number(price);
    if (isNaN(priceNumber)) {
      Utility.eToast("Price must be a valid number");
      return;
    }
    if (
      !name ||
      !category ||
      !materialInfo ||
      // !price
      // //  ||
      !hsnCode ||
      !baseAmount ||
      color?.length === 0 ||
      size?.length === 0
    ) {
      return Utility.eToast("provide all required fields");
    }

    const data = {
      ...inputs,
      // price: priceNumber,
      color: color,
      size: size,
      pattern: pattern,
      images: images.slice(0, -1),
      basePrice: baseAmount,
      gst: gst,
      stack: stack,
      price: parseFloat(grossAmount),
    };
    console.log("yoooooooooo", data);

    await dispatch(addNewProduct({ data, token }))
      .then(unwrapResult)
      .then((res) => {
        Utility.sToast("Product added successfully!");
        console.log(res);
        navigate(-1);
      })
      .catch((err) => {
        console.log("err", err);
        Utility.eToast("Something went wrong");
      });
  };

  const fetchalldata = async () => {
    try {
      const response = await UserController.GetAllCategory(token);
      setAllData(response?.data?.data);
    } catch (error) {
      console.log(
        "Error while fetching category item in Productform----",
        error
      );
    }
  };

  useEffect(() => {
    const base = parseFloat(baseAmount) || 0;
    const gstAmount = parseFloat(gst) || 0;

    const calculatedGrossAmount = base * (1 + gstAmount / 100);

    setGrossAmount(calculatedGrossAmount.toFixed(2));
  }, [baseAmount, gst]);

  useEffect(() => {
    fetchalldata();
  }, []);

  useEffect(() => {
    if (showSize && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showSize]);

  console.log("Image", images.slice(0, -1));

  return (
    <Container className="subadminform">
      <Row id="amtopbarsalery">
        <Col md={11} xs={10} id="firstnames" style={{ color: "black" }}>
          {isEditing ? "Edit Product" : "Add New Product"}
        </Col>
        <Col md={1} xs={2} className="admincrossicon">
          <RxCross2 id="transaction-crossicon" onClick={() => navigate(-1)} />
        </Col>
      </Row>

      <Row>
        <Col className="amproductdetails" style={{ color: "black" }}>
          Product info
        </Col>
      </Row>

      <Form>
        <Row>
          <Col sm={6}>
            <p7 className="titleofcontainer">Product Name</p7>
            <br />
            <Form.Control
              type="text"
              placeholder="Enter Product Name"
              className="amthirdcol"
              name="name"
              value={inputs.name || ""}
              onChange={handlechange}
            />
            <span style={{ color: "red" }}>
              {touched && !inputs.name ? "Product name is required" : ""}
            </span>
          </Col>
          <Col sm={6}>
            <p7 className="titleofcontainer">Select Product category</p7>
            <br />
            <Form.Select
              name="category"
              value={inputs.category || ""}
              onChange={handlechange}
              className="amthirdcoltype"
            >
              <option value="">Select an option</option>
              {allData.map((category) => (
                <option key={category?._id} value={category?._id}>
                  {category?.name}
                </option>
              ))}
            </Form.Select>
            <span style={{ color: "red" }}>
              {touched && !inputs.category ? "Category is required" : ""}
            </span>
          </Col>
        </Row>

        <Row>
          <Col sm={6} className="colorContainer">
            <p7 className="titleofcontainer"> Color</p7>
            <br />
            <div className="color-container">
              {color?.map((item, idx) => {
                return (
                  <div className="color-box-container">
                    <div
                      className={`color-box `}
                      style={{ backgroundColor: item }}
                      key={idx}
                    >
                      {/* {color} */}
                    </div>
                    <RxCross2
                      className="cross-mark"
                      onClick={() => {
                        let newColors = color.filter(
                          (ele, index) => index !== idx
                        );
                        setColor(newColors);
                      }}
                    />
                  </div>
                );
              })}
            </div>
            {showAddColor ? (
              <Col className="color-container">
                <Form.Control
                  type="color"
                  // id="exampleColorInput"
                  // value={selectedColor}
                  defaultValue="#642315"
                  title="Choose your color"
                  onChange={(e) => setSelectedColor(e.target.value)}
                />
                <Button
                  className="add-btn"
                  size="sm"
                  onClick={() => {
                    if (!color.includes(selectedColor)) {
                      setColor([...color, selectedColor]);
                    }
                    setShowAddColor((prev) => !prev);
                  }}
                >
                  Add
                </Button>
              </Col>
            ) : (
              <FiPlusCircle
                onClick={() => setShowAddColor((prev) => !prev)}
                className="add-button"
              />
            )}
            <span style={{ color: "red" }}>
              {touched && color.length === 0 ? "Please select a color" : ""}
            </span>
          </Col>

          <Col sm={6} className="titleofcontainerrrr">
            <p7 className="titleofcontainer">Choose Pattern / Textures</p7>
            <br />
            <input
              type="file"
              placeholder="Add Textures"
              className="amthirdcol"
              onChange={(e) => handlePatternChange(e)}
              ref={fileInputRef}
            />
            <div className="pattern">
              {loading && (
                <div className="spinner-border loader" role="status">
                  <span class="sr-only"></span>
                </div>
              )}

              {pattern.map((value, index) => (
                <>
                  <div key={index} style={{ position: "relative" }}>
                    <img src={value} className="imageInPattern" />
                    <RxCross2
                      className="cross-mark-pattern"
                      onClick={() => {
                        let newColors = pattern.filter(
                          (ele, idx) => idx !== index
                        );

                        setPattern(newColors);
                      }}
                    />
                  </div>
                </>
              ))}
            </div>
          </Col>
        </Row>

        <Row className="prodoductfoamrow">
          <Col sm={6} className="titleofcontainerrrr">
            <p7 className="titleofcontainer">Dimention / Size</p7>
            <br />
            <div className="color-container">
              {size?.map((item, idx) => (
                <div key={idx} className="dimension-item">
                  <div className="DimentionSizeText">
                    <p>{item}</p>
                    <RxCross2
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let newSize = size.filter(
                          (ele, index) => index !== idx
                        );
                        setSize(newSize);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            {showSize ? (
              <Col className="color-container">
                <Form.Control
                  type="text"
                  ref={inputRef}
                  placeholder="Enter Dimension"
                  className="amthirdcol"
                  name="materialInfo"
                  value={selectedSize}
                  onChange={(e) => {
                    setselectedSize(e.target.value);
                    setHasAttemptedSubmit(false);
                  }}
                  isInvalid={hasAttemptedSubmit && selectedSize.trim() === ""}
                />
                {hasAttemptedSubmit && selectedSize.trim() === "" && (
                  <Form.Control.Feedback type="invalid">
                    Please enter a dimension.
                  </Form.Control.Feedback>
                )}
                <Button
                  className="add-btn"
                  size="sm"
                  onClick={() => {
                    setHasAttemptedSubmit(true);
                    if (selectedSize.trim() !== "") {
                      if (!size.includes(selectedSize)) {
                        setSize([...size, selectedSize]);
                      }
                      setshowSize(false);
                      setselectedSize("");
                      setHasAttemptedSubmit(false);
                    }
                  }}
                >
                  Add
                </Button>
              </Col>
            ) : (
              <FiPlusCircle
                onClick={() => {
                  setshowSize(true);
                  setHasAttemptedSubmit(false);
                }}
                className="add-button"
              />
            )}
            <span style={{ color: "red" }}>
              {touched && size.length === 0 ? "Please Enter size" : ""}
            </span>
          </Col>

          <Col sm={6} className="titleofcontainerrrr">
            <p7 className="titleofcontainer">Product Description</p7>
            <br />
            <Form.Control
              type="text"
              placeholder="Enter product description"
              className="amthirdcol"
              name="description"
              onChange={handlechange}
              value={inputs?.description}
            />
            <span style={{ color: "red" }}>
              {touched && !inputs?.description
                ? "Please Enter product description"
                : ""}
            </span>
          </Col>
        </Row>

        <Form.Group
          className="mb-3 prodoductfoamrow"
          controlId="formGroupEmail"
        >
          <Form.Label className="titleofcontainer">Key Features</Form.Label>

          <ReactQuill
            theme="snow"
            className="amthirdcol"
            name="keyFeatures"
            value={inputs.keyFeatures}
            onChange={(val) =>
              setInputs((prev) => ({ ...prev, keyFeatures: val }))
            }
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGroupEmail">
          <Form.Label className="titleofcontainer">
            Material Information{" "}
          </Form.Label>
          <ReactQuill
            theme="snow"
            className="amthirdcol"
            name="materialInfo"
            value={inputs?.materialInfo}
            onChange={(val) =>
              setInputs((prev) => ({ ...prev, materialInfo: val }))
            }
          />
        </Form.Group>

        <Row>
          <Col className="amproductdetails" style={{ color: "black" }}>
            Price Details
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label className="titleofcontainer">Base Amount</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Base Amount"
                className="amthirdcol"
                name="baseAmount"
                value={baseAmount}
                onChange={(e) => {
                  setBaseAmount(e.target.value);
                }}
              />
              <span style={{ color: "red" }}>
                {touched && !baseAmount ? "Please Base Amount" : ""}
              </span>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label className="titleofcontainer">HSN Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the HSN Code of the Product"
                className="amthirdcol"
                value={inputs.hsnCode || ""}
                name="hsnCode"
                onChange={handlechange}
              />
              <span style={{ color: "red" }}>
                {touched && !inputs.hsnCode ? "Please Hsn code" : ""}
              </span>
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label className="titleofcontainer">
                GST (in %age)
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter GST"
                className="amthirdcol"
                onChange={(e) => setGst(e.target.value)}
                value={gst}
                name="gst"
                // onChange={handlechange}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label className="titleofcontainer">Gross Amount</Form.Label>
              <Form.Control
                type="number"
                disabled
                placeholder="Gross Amount"
                className="amthirdcol"
                value={grossAmount}
                name="price"
                // onChange={(e) =>
                //   setInputs((prev) => ({ ...prev, price: e.target.value }))
                // }
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label className="titleofcontainer">Stock</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Stock"
                className="amthirdcol"
                onChange={(e) => setStack(e.target.value)}
                value={stack}
                name="stack"
                // onChange={handlechange}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col className="amproductdetails" style={{ color: "black" }}>
            Upload Picture of Product
          </Col>
        </Row>

        <Row className="productlastrow">
          {images.map((value, index) => (
            <Col key={index} sm={3} xs={6}>
              <div className="image-container">
                {value ? (
                  <div className="image-wrapper">
                    <RxCross2
                      className="cross-mark-picture"
                      onClick={() => {
                        let newImages = images.filter(
                          (ele, idx) => idx !== index
                        );
                        setImages(newImages);
                      }}
                    />
                    <img
                      src={value}
                      alt="Uploaded"
                      className="uploaded-image"
                    />
                  </div>
                ) : (
                  <label className="upload-label">
                    {loadingPicture ? (
                      <div className="spinner-wrapper">
                        <div className="spinner-border" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </div>
                    ) : (
                      <>
                        <FaPlus />
                        <input
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) => handleImageChange(e)}
                        />
                      </>
                    )}
                  </label>
                )}
              </div>
              <span style={{ color: "red" }}>
                {touched && images.length === 0 ? "Please Send an image" : ""}
              </span>
            </Col>
          ))}
        </Row>

        <Row className="subadminlastbutton">
          <GlobalButton
            name={"Reset"}
            paddingTop={"0.4rem"}
            paddingBottom={"0.4rem"}
            paddingLeft={"1rem"}
            paddingRight={"1rem"}
            fontFamily="Roboto"
            // border={"1px solid #595959"}
            background={"#dc2329"}
            color={"#fff"}
            width={"3rem"}
            onclick={handleReset}
          />
          <GlobalButton
            disabled={loadingPicture || loading}
            name={"Submit"}
            paddingTop={"0.4rem"}
            paddingBottom={"0.4rem"}
            paddingLeft={"1rem"}
            paddingRight={"1rem"}
            fontFamily="Roboto"
            // border={"1px solid #595959"}
            background={"#dc2329"}
            color={"#fff"}
            width={"3rem"}
            onclick={handleAddProduct} // Make sure this is correctly bound
          />
        </Row>
      </Form>
    </Container>
  );
};

export default Productform;
