import React, { useState, useEffect } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import { UserController } from "../../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import Utility from "../../../Utils/Utility";
import CircularIndeterminate from "../../../../globalcomponents/Spinner/Spinner";

const AssignEmployeeModal = (props) => {
  const { token } = useSelector((st) => st?.user);
  const [employeeLists, setEmployeeLists] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  let assignedEmployees = props?.selectedQuery?.assignedEmployees?.map(
    (item) => item?.employee?._id
  );
  const getEmployeeList = async () => {
    try {
      const { data } = await UserController.getEmployeeList(token, {
        dept: ["sales", "manager"],
      });
      let responseEmployees = data?.data || [];
      let filteredEmployees = responseEmployees.filter(
        (employee) => !assignedEmployees.includes(employee?._id)
      );
      setEmployeeLists(
        filteredEmployees?.map((item) => ({
          ...item,
          valueToShow: `${item?.name} (${Utility.getDepartment(
            item?.empDepartment
          )})`,
        }))
      );
    } catch (error) {}
  };

  const handleAssign = async () => {
    try {
      if (selectedEmployees?.length === 0) {
        return Utility.eToast("select at least one employee");
      }
      setLoading(true);
      let assignData = {
        action: "assign",
        employeeIds: selectedEmployees,
        queryId: props?.selectedQuery?._id,
      };
      const { data } = await UserController.grantOrRevokeQueryAccess(
        token,
        assignData
      );
      props.onHide();
      Utility?.sToast(data?.message);
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployeeList();
  }, []);
  return (
    <>
      {/* color: "#642315E5", background: "#f4f3f0" */}
      <Container className="p-2">
        <Modal id="transactionModal" {...props} size="lg">
          <Modal.Header closeButton style={{ background: "#f4f3f0" }}>
            <Modal.Title>Assign New Employee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Multiselect
                options={employeeLists}
                // selectedValues={}
                onSelect={(selectedList) => {
                  let selectedIds = selectedList?.map((item) => item?._id);
                  setSelectedEmployees(selectedIds);
                }}
                onRemove={(selectedList) => {
                  let selectedIds = selectedList?.map((item) => item?._id);
                  setSelectedEmployees(selectedIds);
                }}
                displayValue="valueToShow"
              />
            </Row>
            <Row className=" mt-5">
              <Col className="d-flex justify-content-end w-100">
                {loading ? (
                  <CircularIndeterminate />
                ) : (
                  <GlobalButton
                    name={"Assign"}
                    background={"#dc2329"}
                    color={"#fff"}
                    onclick={handleAssign}
                  />
                )}
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default AssignEmployeeModal;
