import { useSelector } from "react-redux";
import { HttpClient } from "./HttpClient";

export const addProductItem = (data) => {
  let url = "product/add-product";
  console.log("http token    ==========", data.token);
  return HttpClient.apiCaller(url, "POST", data.data, data.token);
};

export const getProductItem = () => {
  let url = "product/get-all-products";
  return HttpClient.apiCaller(url, "get");
};

export const deleteProductItem = (id) => {
  let url = `product/delete-product-by-id/${id}`;
  return HttpClient.deleteApicaller(url);
};
export const updateProductItem = (data) => {
  let url = `product/update-product-by-id`;
  return HttpClient.apiCaller(url, "PUT", data);
};

export const login = (data) => {
  let url = "auth/login";
  return HttpClient.apiCaller(url, "POST", data);
};
// export const imageUploader = (data) => {
//   let url = "admins/uploadimage";
//   return HttpClient.apiCaller(url, "POST", data, "", "multipart/form-data");
// };
export const SingleImageUploader = (data) => {
  let url = "upload-single-doc";
  return HttpClient.apiCaller(url, "POST", data, "", "multipart/form-data");
};
export const updateYearlyLeave = (data,token) => {
  let url = "leave/upload-holiday";
  return HttpClient.apiCaller(url, "POST", data,token);
};

export const getAllUsers = (token) => {
  let url = "user/users-by-role-or-dept?role=user";
  return HttpClient.apiCaller(url, "GET", null, token);
};
const getOrdersOfUser = (token, id) => {
  let url = "order/get-orders-by-user/" + id;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

export const addNewSubAdmin = (data) => {
  let url = "auth/register";
  return HttpClient.apiCaller(url, "POST", data, null);
};
export const editEmployee = (data) => {
  let url = "auth/update-user";
  return HttpClient.apiCaller(url, "PUT", data, null);
};
export const addNewCategory = (data) => {
  let url = "category/add-category";
  return HttpClient.apiCaller(url, "POST", data.inputs, data.token);
};
export const GetAllCategory = (token) => {
  let url = "category/get-all-categories";
  return HttpClient.apiCaller(url, "GET", null, token);
};
export const getOrderDetailsAPI = (token) => {
  let url = "order/get-all-orders";
  return HttpClient.apiCaller(url, "GET", null, token);
};
export const getAllManagerAPI = (token) => {
  let url = "user/users-by-role-or-dept?role=employee&dept=manager";
  return HttpClient.apiCaller(url, "GET", null, token);
};
export const assignManagerAPI = (data) => {
  console.log(data, "data");
  let url = `order/assign-manager/${data.orderId}/${data.selectedEmployee}`;
  return HttpClient.apiCaller(url, "PUT", {}, data.token);
};

export const getAllEmployee = (token) => {
  let url = "user/users-by-role-or-dept?role=employee";
  return HttpClient.apiCaller(url, "GET", null, token);
};

export const getAttendanceById = (token, id) => {
  let url = `attendance/get-attendances-by-employee?empID=${id}`;
  return HttpClient.apiCaller(url, "GET", null, token);
};
export const getSaleryDetails = (token, id) => {
  let url = `salary/get-salary-details-by-employee/${(token, id)}`;
  return HttpClient.apiCaller(url, "GET", null, token);
};
export const getAllNotification = (token, id) => {
  let url = `notify/get-notification/${id}`;
  return HttpClient.apiCaller(url, "GET", null, token);
};
export const getAllQueries = (token) => {
  let url = `query/get-all`;
  return HttpClient.apiCaller(url, "GET", null, token);
};
export const getAllLeaves = (token) => {
  let url = `leave/get-all`;
  return HttpClient.apiCaller(url, "GET", null, token);
};
export const deleteNotificationById = (token, id) => {
  let url = `notify/delete-notification/${id}`;
  return HttpClient.apiCaller(url, "DELETE", {}, token);
};
export const deleteAllNotificationById = (token, id) => {
  let url = `notify/delete-all-notification/${id}`;
  return HttpClient.apiCaller(url, "DELETE", {}, token);
};
export const getDashboardStatistics = (token) => {
  let url = `stats/dashboard-details`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
export const employeeChartDetails = (token) => {
  let url = `stats/chart/employee`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
export const customerChartDetails = (token) => {
  let url = `stats/chart/customer`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
export const orderChartDetails = (token) => {
  let url = `stats/chart/order`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

export const financialChartDetails = (token) => {
  let url = `stats/chart/financial`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
export const grantOrRevokeQueryAccess = (token, data) => {
  let url = `query/assign-or-revoke`;
  return HttpClient.apiCaller(url, "PUT", data, token);
};
export const getQueryByID = (token, id) => {
  let url = `query/get-by-id/` + id;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
export const getEmployeeList = (token, data) => {
  let url = `user/employee-list?`;
  if (data?.role) {
    data.role.forEach((item) => {
      url += `role=${item}&`;
    });
  }
  if (data?.dept) {
    data.dept.forEach((item) => {
      url += `dept=${item}&`;
    });
  }
  return HttpClient.apiCaller(url, "GET", {}, token);
};

export const getAllTask = (token) => {
  let url = `task/get-all`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

export const getTaskById = (token, id) => {
  let url = `task/get-by-id/${id}`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
export const assignNewTask = (token, data) => {
  let url = `task/assign-new`;
  return HttpClient.apiCaller(url, "POST", data, token);
};
export const assignOrRejectLeave = (token, id, data) => {
  let url = `leave/approve-or-reject/` + id;
  return HttpClient.apiCaller(url, "PUT", data, token);
};
export const getOrderList = (token) => {
  let url = `order/list`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
export const getPaymentHistory = (token, id) => {
  let url = `order/get-payment-history/${id}`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};
export const getAllLeaveEmployeeToDay = (token) => {
  let url = `leave/get-all?status=approved&time=today`;
  return HttpClient.apiCaller(url, "GET", {}, token);
};

export const updateProductStock = (token, data) => {
  let url = `/product/update-stock`;
  return HttpClient.apiCaller(url, "PUT", data, token);
};

export const UserController = {
  getAllUsers,
  addProductItem,
  login,
  getProductItem,
  deleteProductItem,
  addNewCategory,
  GetAllCategory,
  updateProductItem,
  getAllQueries,
  getOrdersOfUser,
  SingleImageUploader,
  addNewSubAdmin,
  getOrderDetailsAPI,
  getAllManagerAPI,
  assignManagerAPI,
  getAllEmployee,
  getAttendanceById,
  getSaleryDetails,
  getAllNotification,
  deleteNotificationById,
  deleteAllNotificationById,
  getDashboardStatistics,
  employeeChartDetails,
  customerChartDetails,
  orderChartDetails,
  financialChartDetails,
  grantOrRevokeQueryAccess,
  getQueryByID,
  getEmployeeList,
  getAllTask,
  getTaskById,
  getOrderList,
  assignNewTask,
  getPaymentHistory,
  getAllLeaves,
  assignOrRejectLeave,
  getAllLeaveEmployeeToDay,
  updateProductStock,
  editEmployee,
  updateYearlyLeave
};
