import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Container } from "react-bootstrap";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import { MdOutlineFileDownload } from "react-icons/md";
import "./OrderDetailsModal.css";
import { useNavigate } from "react-router-dom";
import DataTable from "../../../../globalcomponents/DataTable/DataTable";
import { MdDeleteOutline } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import Utility from "../../../Utils/Utility";
import { UserController } from "../../../Redux/controllers/UserController";
import CircularIndeterminate from "../../../../globalcomponents/Spinner/Spinner";
import { useSelector } from "react-redux";

const OrderDetailsModal = (props) => {
  const [isKeyFeaturesOpen, setIsKeyFeaturesOpen] = useState([]);
  const [ismaterialInfo, setismaterialInfo] = useState([]);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState([]);
  const token = useSelector((state) => state.user.token);

  let viewModalData = props?.viewModalData;

  const toggleKeyFeatures = (index) => {
    setIsKeyFeaturesOpen((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };
  const toggleMaterialInfo = (index) => {
    setismaterialInfo((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };
  const toggleDescription = (index) => {
    setIsDescriptionOpen((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("viewModalData", viewModalData);
  const getPaymentHistory = async () => {
    try {
      setLoading(true);
      const res = await UserController.getPaymentHistory(
        token,
        viewModalData?._id
      );
      console.log("RESP", res?.data?.data);
      setPaymentHistory(res?.data?.data?.paymentHistory);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error!!", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPaymentHistory();
  }, []);

  const columns = [
    {
      accessorKey: "slNo",
      header: "Sl No",
      size: 50,
    },
    {
      accessorKey: "paymentAmount",
      header: "Payment Amount",
      size: 100,
    },
    {
      accessorKey: "paymentDate",
      header: "Payment Date",
      size: 100,
    },
    {
      accessorKey: "paymentMethod",
      header: "Payment Method",
      size: 100,
    },
  ];

  const data = paymentHistory?.map((item, index) => ({
    slNo: index + 1,
    paymentAmount: Utility.MoneyFormatter(item?.paymentAmount),
    paymentDate: Utility.formatDate(item?.paymentDate) || "N/A",
    paymentMethod: item?.paymentMethod || "N/A",
  }));

  // console.log("viewModalData",viewModalData?.productDetails);
  // color: "#642315E5", background: "#f4f3f0"
  return (
    <Container className="p-2">
      <Modal id="transactionModal" {...props} size="lg">
        <Modal.Header closeButton style={{ background: "#f4f3f0" }}>
          <Modal.Title style={{ color: "black" }}>
            Order Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Order Info  */}

          <Row className="mt-1">
            <Col>Order ID</Col>
            <Col className="value">#{viewModalData?.orderID || "N/A"}</Col>
          </Row>
          <Row className="mt-1">
            <Col>Order Creation Date</Col>
            <Col className="value">
              {Utility.formatDate(viewModalData?.orderCreationDate) || "N/A"}
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>Order Managed By</Col>
            <Col className="value">{viewModalData?.approverDetails?.name}</Col>
          </Row>
          <Row className="mt-1">
            <Col>Delivery Status</Col>
            <Col className="d-flex justify-content-end align-items-center">
              <GlobalButton
                name={viewModalData?.deliveryStatus || "--"}
                disabledBgColor={Utility.getStatusColor(
                  viewModalData?.deliveryStatus
                )}
                disabled={true}
                color={"#fff"}
                padding={"0"}
                margin={"3"}
                width={"100px"}
              />
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>Estimate Delivery Date</Col>
            <Col className="value">
              {Utility.formatDate(viewModalData?.estimatedDeliveryDate) ||
                "N/A"}
            </Col>
          </Row>

          {/* product Info */}
          {viewModalData?.productDetails?.length > 0 && (
            <Row className="">
              <h6 className="transactionHeading" style={{ color: "black" }}>
                Product Information
              </h6>
              <div>
                {viewModalData?.productDetails?.map((product, index) => (
                  <Row className="">
                    {viewModalData?.productDetails.length > 1 && (
                      <span
                        style={{
                          marginTop: "8px",
                          marginBottom: "8px",
                          fontSize: "17px",
                          textDecoration: "underline",
                        }}
                      >
                        <strong>{`Product ${index + 1}`}</strong>
                      </span>
                    )}
                    <Row className="mt-1">
                      <Col>Name</Col>
                      <Col className="value">
                        {product?.product?.name || "N/A"}
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col>ID</Col>
                      <Col className="value">
                        #{product?.product?.productID || "N/A"}
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col>Date Of Listing</Col>
                      <Col className="value">
                        {Utility.formatDate(product?.product?.createdAt) ||
                          "N/A"}
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col>Dimension</Col>
                      <Col className="value">
                        {product?.requirements?.selectedSize || "N/A"}
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col>Selected Color</Col>
                      <Col className="value">
                        <div className="colorOptionBox">
                          {(
                            <div
                              className="colorOption"
                              style={{
                                backgroundColor: `${product?.requirements?.selectedColor}`,
                              }}
                            ></div>
                          ) || "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col>Texture</Col>
                      <Col className="value">
                        <div className="textureBox">
                          {(
                            <img
                              className="texture"
                              src={product?.requirements?.selectedPattern}
                              alt="texture"
                            />
                          ) || "N/A"}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-3 mb-1">
                      <Col>Key Features</Col>
                      <Col className="value">
                        {(
                          <>
                            <GlobalButton
                              name={isKeyFeaturesOpen[index] ? "Close" : "View"}
                              background={"#dc2329"}
                              color={"#fff"}
                              padding={"0"}
                              onclick={() => toggleKeyFeatures(index)}
                            />
                          </>
                        ) || "N/A"}
                      </Col>
                      <Col sm={12} xs={12}>
                        <div
                          className={`key-features-slider ${
                            isKeyFeaturesOpen[index] ? "open" : ""
                          }`}
                        >
                          <div />
                          <h6>
                            {Utility.HTMLParser(
                              (product?.product?.keyFeatures || " ") ?? " "
                            )}
                          </h6>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-1 mb-1">
                      <Col>Additional Info</Col>
                      <Col className="value">
                        {(
                          <>
                            <GlobalButton
                              name={isDescriptionOpen[index] ? "Close" : "View"}
                              background={"#dc2329"}
                              color={"#fff"}
                              padding={"0"}
                              onclick={() => toggleDescription(index)}
                            />
                          </>
                        ) || "N/A"}
                      </Col>
                      <Col sm={12} xs={12}>
                        <div
                          className={`description-slider ${
                            isDescriptionOpen[index] ? "open" : ""
                          }`}
                        >
                          <div />
                          <h6 className="descriptionparser">
                            {Utility.HTMLParser(
                              product?.requirements?.additionalInfo ?? " "
                            )}
                          </h6>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-1 mb-1">
                      <Col>Materials</Col>
                      <Col className="value">
                        {(
                          <>
                            <GlobalButton
                              name={ismaterialInfo[index] ? "Close" : "View"}
                              background={"#dc2329"}
                              color={"#fff"}
                              padding={"0"}
                              onclick={() => toggleMaterialInfo(index)}
                            />
                          </>
                        ) || "N/A"}
                      </Col>
                      <Col sm={12} xs={12}>
                        <div
                          className={`description-slider ${
                            ismaterialInfo[index] ? "open" : ""
                          }`}
                        >
                          <div />
                          <h6 className="">
                            {Utility.HTMLParser(
                              product?.product?.materialInfo ?? " "
                            ) || "N/A"}
                          </h6>
                        </div>
                      </Col>
                    </Row>

                    <h6
                      style={{
                        fontSize: "17px",
                        textDecoration: "underline",
                        marginTop: "8px",
                        marginBottom: "8px",
                        color: "black",
                      }}
                    >
                      Product Images
                    </h6>
                    <Row className="ImageContainer">
                      <div className="productImage">
                        {product?.product?.images?.map((product, index) => (
                          <div key={index} className="viewProduct">
                            <img
                              className="viewProduct"
                              src={product}
                              alt=" "
                            />
                          </div>
                        ))}
                      </div>
                    </Row>
                  </Row>
                ))}
                {/* <Row className="">
                <Row className="mt-1">
                  <Col>Product Name</Col>
                  <Col className="value">
                    {viewModalData?.productDetails?.product?.name || "N/A"}
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>Product ID</Col>
                  <Col className="value">
                    #{viewModalData?.productDetails?.product?.productID || "N/A"}
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>Product Date Of Listing</Col>
                  <Col className="value">
                    {Utility.formatDate(viewModalData?.productDetails?.product?.createdAt)
                      || "N/A"}
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>Dimensions</Col>
                  <Col className="value">
                    {viewModalData?.productDetails?.requirements?.selectedSize || "N/A"}
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>Selected Color</Col>
                  <Col className="value">
                    <div className="colorOptionBox">
                      {
                        <div

                          className="colorOption"
                          style={{ backgroundColor: `${viewModalData?.productDetails?.requirements?.selectedColor}` }}
                        ></div>

                        || "N/A"}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col>Texture</Col>
                  <Col className="value">
                    <div className="textureBox">
                      {
                        <img

                          className="texture"
                          src={viewModalData?.productDetails?.requirements?.selectedPattern}
                          alt="texture"
                        />

                        || "N/A"}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3 mb-1">
                  <Col>Key Features</Col>
                  <Col className="value">
                    {(
                      <>
                        <GlobalButton
                          name={isKeyFeaturesOpen ? "Close" : "View"}
                          background={"#E4CDAA"}
                          color={"#642315"}
                          padding={"0"}
                          onclick={toggleKeyFeatures}
                        />
                      </>
                    ) || (
                        "N/A"
                      )}
                  </Col>
                  <Col sm={12} xs={12}>
                    <div
                      className={`key-features-slider ${isKeyFeaturesOpen ? "open" : ""
                        }`}
                    >
                      <div />
                      <h6>
                        {Utility.HTMLParser((viewModalData?.productDetails?.product?.keyFeatures || " ") ?? " ")}
                      </h6>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-1 mb-1">
                  <Col>Additional Info</Col>
                  <Col className="value">
                    {(
                      <>
                        <GlobalButton
                          name={isDescriptionOpen ? "Close" : "View"}
                          background={"#E4CDAA"}
                          color={"#642315"}
                          padding={"0"}
                          onclick={toggleDescription}
                        />
                      </>
                    ) || (
                        "N/A"
                      )}
                  </Col>
                  <Col sm={12} xs={12}>
                    <div
                      className={`description-slider ${isDescriptionOpen ? "open" : ""
                        }`}
                    >
                      <div />
                      <h6 className="descriptionparser">
                        {Utility.HTMLParser((viewModalData?.productDetails?.requirements?.additionalInfo) ?? " ")}
                      </h6>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-1 mb-1">
                  <Col>Materials</Col>
                  <Col className="value">
                    {(
                      <>
                        <GlobalButton
                          name={ismaterialInfo ? "Close" : "View"}
                          background={"#E4CDAA"}
                          color={"#642315"}
                          padding={"0"}
                          onclick={toggleMaterialInfo}
                        />
                      </>
                    ) || (
                        "N/A"
                      )}
                  </Col>
                  <Col sm={12} xs={12}>
                    <div
                      className={`description-slider ${ismaterialInfo ? "open" : ""
                        }`}
                    >
                      <div />
                      <h6 className="">
                        {Utility.HTMLParser((viewModalData?.productDetails?.product?.materialInfo) ?? " ") || "N/A"}
                      </h6>
                    </div>
                  </Col>
                </Row>
              </Row> */}
              </div>
            </Row>
          )}
          {/* Payment Info */}
          <Row className="">
            <h6 className="transactionHeading" style={{ color: "black" }}>
              Payment Information
            </h6>
          </Row>
          <Row className="mt-1">
            <Col>Total Amount</Col>
            <Col className="value">
              {Utility.MoneyFormatter(
                viewModalData?.paymentDetails?.totalAmount
              )}
            </Col>
          </Row>

          <Row className="mt-1">
            <Col>Remaining Amount</Col>
            <Col className="value">
              {Utility.MoneyFormatter(
                viewModalData?.paymentDetails?.remainingAmount
              )}
            </Col>
          </Row>

          <Row className="mt-1">
            <Col> Additional Comments</Col>
            <Col className="value">
              {viewModalData?.paymentDetails?.additionalComments || "__"}
            </Col>
          </Row>

          <Row className="">
            <h6 className="transactionHeading" style={{ color: "black" }}>
              Payment History
            </h6>
            {loading ? (
              <div className="spinnerrowitem">
                <CircularIndeterminate />
              </div>
            ) : (
              <DataTable columns={columns} data={data} />
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default OrderDetailsModal;
