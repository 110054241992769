import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Badge,
  ListGroup,
  Form,
} from "react-bootstrap";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import { MdDeleteOutline } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import "./Vendors.css";
import AddVendorModal from "../Modals/AddVendorModal/AddVendorModal";

const Vendors = () => {
  const [vendors, setVendors] = useState([1, 2, 3]);
  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setShowModal(true);
  };
  return (
    <Container>
      {showModal && (
        <AddVendorModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
        />
      )}
      <Row className="mt-2">
        <Col>
          <h5 style={{ color: "#642315" }}>Vendors</h5>
        </Col>
        <Col className="d-flex flex-row-reverse">
         <div onClick={handleModal}>
          <GlobalButton
            name={"Add New"}
            background={"#E4CDAA"}
            color={"#642315"}
            margin={"2px"}
            
          
          />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={7}>
          {vendors.map(() => {
            return (
              <Card className="vendorInfo">
                <Card.Body>
                  <Card.Title style={{ background: "#F4F3F0" }}>
                    Akshay Syal{" "}
                    <Badge id="badge" bg="#E4CDAA">
                      Sub-agent
                    </Badge>
                    <MdDeleteOutline style={{ float: "right" }} id="deleteVendor"/>
                    <FaRegEdit
                      style={{ float: "right", marginLeft: "8px" }}
                      id="editVendor"
                      
                    />
                  </Card.Title>
                  <ListGroup variant="flush">
                    <ListGroup.Item className="lItem">
                      Material Suppliers
                    </ListGroup.Item>
                    <ListGroup.Item className="lItem">
                      <span>Gst :</span> 09898989898
                    </ListGroup.Item>
                    <ListGroup.Item className="lItem">
                      <span>Phone no : </span>9876543210
                    </ListGroup.Item>
                    <ListGroup.Item className="lItem">
                      Fonseca Compound, 541, Akurli Rd, Near Post Office,
                      Cuttack (CDA - 09)
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            );
          })}
        </Col>
        <Col md={5}>
          <div className="vendorInfo p-2">
            <h4>Show Only</h4>
            <Form>
              <Form.Check type="checkbox" label="Furniture Manufacturers" />
              <Form.Check type="checkbox" label="Material Suppliers" />
              <Form.Check type="checkbox" label="Hardware Suppliers" />
              <Form.Check
                type="checkbox"
                label="Transportation and Logistics Providers"
              />
              <Form.Check
                type="checkbox"
                label="Maintenance and Repair Services"
              />
              <Form.Check
                type="checkbox"
                label="Marketing and Advertising Agencies"
              />
              <Form.Check type="checkbox" label="Consultants and Designers" />
              <Form.Check type="checkbox" label="Others" defaultChecked />
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Vendors;
