import React from "react";
import { Row, Col, Modal, Form } from "react-bootstrap";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import "./TransactionDetailsForm.css";
const TransactionDetailsForm = (props) => {
  return (
    <Modal id="transactionModal" {...props} size="lg">
      <Modal.Header closeButton style={{ background: "#E4CDAA" }}>
        <Modal.Title style={{ color: "#642315E5", background: "#E4CDAA" }}>
          Transaction Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mt-2 pb-2">
          <Row className="mt-1">
            <Col md={6}>
              <label>Transaction ID</label>
              <br />
              <Form.Control
                placeholder="Enter Transaction ID"
                className="amthirdcol"
              />
            </Col>
            <Col md={6}>
              <label>Transaction Date</label>
              <br />
              <Form.Control
                type="date"
                placeholder="dd/mm/yyyy"
                className="amthirdcol"
              />
            </Col>
          </Row>
          <div className="mt-1">
            <label>Transaction Type</label>
            <div key={`inline-radio`} className=" mb-3 mt-1 radioBtns">
              <Form.Check
                inline
                label="Purchase:"
                name="group1"
                type="radio"
                id={`inline-radio-1`}
              />
              <Form.Check
                inline
                label="Sale:"
                name="group1"
                type="radio"
                id={`inline-radio-2`}
              />
              <Form.Check
                inline
                // disabled
                label="Return/Refund:"
                type="radio"
                id={`inline-radio-3`}
              />
              <Form.Check
                inline
                // disabled
                label="Others"
                type="radio"
                id={`inline-radio-3`}
              />
            </div>
          </div>

          <Form.Group className="mb-2" controlId="formGroupEmail">
            <Form.Label>Transaction Description</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Transaction Description"
              className="amthirdcol"
            />
          </Form.Group>
          <Row className="mt-1">
            <Col md={6}>
              <label>Transaction Party</label>
              <br />
              <Form.Control
                placeholder="Name of Customer/Supplier"
                className="amthirdcol"
              />
            </Col>
            <Col md={6}>
              <label>Transaction Party Mobile Number</label>
              <br />
              <Form.Control
                type="email"
                placeholder="Enter the Customer/Supplier Email"
                className="amthirdcol"
              />
            </Col>
          </Row>
          <Row className="mt-1">
            <Col md={6}>
              <label>Payment Method</label>
              <br />
              <Form.Control
                type="text"
                placeholder="Enter the Payment Method"
                className="amthirdcol"
              />
            </Col>
            <Col md={6}>
              <label>Transaction ID</label>
              <br />
              <Form.Control
                placeholder="Enter Transaction ID"
                className="amthirdcol"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Total Transaction Amount</label>
              <Form.Control
                placeholder="Enter Total Amount"
                className="amthirdcol"
              ></Form.Control>
            </Col>
          </Row>
          <Row>
            <Form.Group className="mb-1" controlId="formGroupEmail">
              {/* <Form.Label>Additional Notes</Form.Label> */}
              <label>Additional Notes</label>
              <Form.Control
                type="email"
                placeholder="Any additional notes or comments regarding the sale"
                className="amthirdcol"
              />
            </Form.Group>
          </Row>
          <Row>
            <Col id="formProduct">Product Details</Col>
          </Row>
          <Row className="mt-3">
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control type="file" className="amthirdcol"/>
            </Form.Group>
          </Row>
        </Form>

        <Row>
          <Col className="d-flex flex-row-reverse">
            <GlobalButton
              name={"Cancel"}
              background={"#E4CDAA"}
              color={"#642315"}
              width={"3rem"}
              padding={"0"}
              border={"1px solid #642315"}
            />

            <GlobalButton
              name={"Save"}
              background={"#E4CDAA"}
              color={"#642315"}
              width={"3rem"}
              padding={"0.8"}
              marginRight={"1rem"}
              border={"1px solid #642315"}
            />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default TransactionDetailsForm;
