import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "../CustomerInformationModal/CustomerInformationModal.css";
import DataTable from "../../../../globalcomponents/DataTable/DataTable";
import Utility from "../../../Utils/Utility";
import CircularIndeterminate from "../../../../globalcomponents/Spinner/Spinner";

const CustomerLeaveModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [leaveData, setLeaveData] = useState([]);

  useEffect(() => {
    setLoading(true);
    setLeaveData(props?.data);
    setLoading(false);
  }, []);

  const columns = [
    {
      accessorKey: "slNo",
      header: "Sl No",
      size: 30,
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 30,
    },
    {
      accessorKey: "fromDate",
      header: "From",
      size: 50,
    },
    {
      accessorKey: "toDate",
      header: "To",
      size: 50,
    },
    {
      accessorKey: "approvedBy",
      header: "Approved By",
      size: 50,
    },
    {
      accessorKey: "cause",
      header: "Cause",
      size: 50,
    },
    {
      accessorKey: "noOfDays",
      header: "No.Of Days",
      size: 50,
    },
  ];

  const data = leaveData?.map((item, index) => {
    return {
      slNo: index + 1,
      name: item?.appliedBy?.name,
      fromDate: Utility.formatDate(item?.fromDate),
      toDate: Utility.formatDate(item?.toDate),
      approvedBy: item?.approvedBy?.name,
      cause: item?.causeOfLeave,
      noOfDays: item?.noOfDays,
    };
  });

  // console.log("leaveData",leaveData);

  return (
    <Modal {...props} size="lg">
      <Modal.Header closeButton style={{ background: "#F3F3F0" }}>
        <Modal.Title style={{ color: "black", background: "#F3F3F0" }}>
          Total Employee On Leave Today
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="spinnerrowitem">
            <CircularIndeterminate />
          </div>
        ) : (
          <DataTable data={data} columns={columns} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default CustomerLeaveModal;
