import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import DataTable from "../../../globalcomponents/DataTable/DataTable";
import GlobalButton from "../../../globalcomponents/GlobalButton/GlobalButton";
import OrderDetailsModal from "../Modals/OrderDetailsModal/OrderDetailsModal";
import Utility from "../../Utils/Utility";
import "./OrderHistory.css";
import { UserController } from "../../Redux/controllers/UserController";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import CircularIndeterminate from "../../../globalcomponents/Spinner/Spinner";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import TickPlacementBars from "../../../globalcomponents/Barchart/DashboaedChart";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../Redux/Slice/userSlice";
import * as XLSX from "xlsx";
import { PDFDocument, StandardFonts } from "pdf-lib";

const OrderHistory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployeeByOrder, setSelectedEmployeeByOrder] = useState({});
  const [viewModalData, setViewModalData] = useState([]);
  const [loading, setLoading] = useState(false);

  const token = useSelector((state) => state.user.token);

  const handleModal = (item) => {
    setShowModal(true);
    setViewModalData(item);
  };

  const getAllManager = async () => {
    try {
      const response = await UserController.getAllManagerAPI(token);
      setEmployees(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getOrderDetails = async () => {
    try {
      setLoading(true);
      const response = await UserController.getOrderDetailsAPI(token);
      setOrderData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Utility.eToast(error?.response?.data?.message || error?.message);
      if (
        error?.response?.data?.message ===
        "token has expired , please login again"
      ) {
        dispatch(logOut());
        localStorage.clear();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { accessorKey: "slno", header: "Sl.No", size: 100 },
    { accessorKey: "orderID", header: "Order ID", size: 100 },
    { accessorKey: "price", header: "Price", size: 100 },
    { accessorKey: "status", header: "Status", size: 100 },
    { accessorKey: "action", header: "Action", size: 100 },
    { accessorKey: "assign", header: "Managed By", size: 100 },
  ];

  const shortMonths = (data) => {
    const monthAbbreviations = {
      January: "Jan",
      February: "Feb",
      March: "Mar",
      April: "Apr",
      May: "May",
      June: "Jun",
      July: "Jul",
      August: "Aug",
      September: "Sep",
      October: "Oct",
      November: "Nov",
      December: "Dec",
    };

    return data?.map((item) => {
      const [fullMonth, fullYear] = item?.month?.split(" ");
      const shortMonth = monthAbbreviations[fullMonth];
      const shortYear = fullYear?.slice(-2);
      return {
        month: `${shortMonth} ${shortYear}`,
        count: item?.count,
      };
    });
  };
  const [assignLoading, setAssignLoading] = useState([]);

  const handelSubmit = async (orderId, selectedEmployee, index) => {
    try {
      setAssignLoading((prop) => {
        const newState = [prop];
        newState[index] = !newState[index];
        return newState;
      });
      if (!orderId || !selectedEmployee) {
        toast.error("Please select an employee");
        return;
      }
      await UserController?.assignManagerAPI({
        orderId,
        selectedEmployee,
        token,
      });
      Utility.sToast("Manager assigned successfully");
      getOrderDetails();
      setAssignLoading(false);
    } catch (error) {
      setAssignLoading(false);
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setAssignLoading(false);
    }
  };

  const exportToExcel = () => {
    const orderHistory = data.map((row) => ({
      "Sl.No": row.slno,
      "Order ID": row.orderID,
      Price: row.price,
      Status: row.status.props.name,
      "Managed By": row.assign || "Not Assigned",
    }));

    const currentMonthStatuses = [
      { label: "Pending", value: currentMonthStatus?.pending || 0 },
      { label: "Confirmed", value: currentMonthStatus?.confirmed || 0 },
      { label: "Processing", value: currentMonthStatus?.processing || 0 },
      { label: "Shipped", value: currentMonthStatus?.shipped || 0 },
      { label: "Delivered", value: currentMonthStatus?.delivered || 0 },
    ];

    const lastMonthOrderStatus = currentMonthStatuses.map((status) => ({
      Status: status.label,
      Count: status.value,
    }));

    const lastSixMonthsOrders = dataset.map((data) => ({
      Month: data.month,
      Count: data.count,
    }));

    const workbook = XLSX.utils.book_new();

    const orderSheet = XLSX.utils.json_to_sheet(orderHistory);
    XLSX.utils.book_append_sheet(workbook, orderSheet, "Order History");

    const statusSheet = XLSX.utils.json_to_sheet(lastMonthOrderStatus);
    XLSX.utils.book_append_sheet(
      workbook,
      statusSheet,
      "Last Month Order Status"
    );

    const sixMonthsSheet = XLSX.utils.json_to_sheet(lastSixMonthsOrders);
    XLSX.utils.book_append_sheet(
      workbook,
      sixMonthsSheet,
      "Last 6 Months Orders"
    );

    XLSX.writeFile(workbook, "OrderHistory.xlsx");
  };

  const exportToPDF = async () => {
    const pdfDoc = await PDFDocument.create();
    let page = pdfDoc.addPage();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontSize = 12;
    let y = 750;

    page.setFont(font);
    page.setFontSize(fontSize);

    // Add Order History Table
    page.drawText("Order History Data", { x: 50, y, size: 16 });
    y -= 20;
    data.forEach((row, index) => {
      if (y < 50) {
        page.drawText("Continued on next page...", { x: 50, y: 30 });
        page = pdfDoc.addPage();
        y = 750;
      }
      const sanitizedRowText = `Sl.No: ${row.slno}, Order ID: ${row.orderID
        }, Price: ${row.price.replace("₹", "INR")}, Status: ${row.status
        }, Managed By: ${row.assign}`;
      page.drawText(sanitizedRowText, { x: 50, y });
      y -= 20;
    });

    y -= 30;
    page.drawText("Last Month Order Status", { x: 50, y: y, size: 16 });
    y -= 20;
    const currentMonthStatuses = [
      { label: "Pending", value: currentMonthStatus?.pending || 0 },
      { label: "Confirmed", value: currentMonthStatus?.confirmed || 0 },
      { label: "Processing", value: currentMonthStatus?.processing || 0 },
      { label: "Shipped", value: currentMonthStatus?.shipped || 0 },
      { label: "Delivered", value: currentMonthStatus?.delivered || 0 },
    ];
    currentMonthStatuses.forEach((status) => {
      if (y < 50) {
        page = pdfDoc.addPage();
        y = 750;
      }
      page.drawText(`${status.label}: ${status.value}`, { x: 50, y });
      y -= 20;
    });

    y -= 30;
    page.drawText("Last 6 Month Orders", { x: 50, y: y, size: 16 });
    y -= 20;
    dataset.forEach((data) => {
      if (y < 50) {
        page = pdfDoc.addPage();
        y = 750;
      }
      page.drawText(`Month: ${data.month}, Count: ${data.count}`, { x: 50, y });
      y -= 20;
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "OrderHistory.pdf";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleChange = (event, orderId) => {
    const selectedOption = event?.target?.options[event?.target?.selectedIndex];
    const employeeId = selectedOption?.getAttribute("data-id");
    const employeeName = event?.target?.value;

    setSelectedEmployeeByOrder((prev) => ({
      ...prev,
      [orderId]: { employeeId, employeeName },
    }));
  };

  const data = orderData.map((item, index) => {
    const employeeSelection = selectedEmployeeByOrder[item?._id] || {};
    return {
      slno: index + 1,
      orderID: item?.orderID,
      price: Utility?.MoneyFormatter(item?.paymentDetails?.totalAmount),
      budget: item?.productDetails?.product?.price,
      status: (
        <GlobalButton
          disabledBgColor={Utility?.getStatusColor(item?.deliveryStatus)}
          disabled={true}
          name={
            item?.deliveryStatus?.charAt(0)?.toUpperCase() +
            item?.deliveryStatus?.slice(1) || "N/A"
          }
          width="6rem"
          padding="2px"
          color="#fff"
        />
      ),
      action: (
        <GlobalButton
          name={"View"}
          background={"#dc2329"}
          color={"#fff"}
          padding={"0"}
          onclick={() => handleModal(item)}
        />
      ),
      assign: item?.managedBy ? (
        item?.managedBy.name
      ) : (
        <div className="managedBy">
          <select
            className="pleaseChooseAnEmp fixed-width-select"
            onChange={(e) => handleChange(e, item._id)}
            value={employeeSelection.employeeName || ""}
            style={{ background: "#dc2329", color: "#fff" }}
          >
            <option value="">Select a manager</option>
            {employees.map((employee) => (
              <option
                key={employee?._id}
                data-name={item?._id}
                value={employee?.name}
                data-id={employee._id}
                className="selectitemsoforder"
                style={{ color: "#fff" }}
              >
                {employee.name}
              </option>
            ))}
          </select>
          {assignLoading[index] ? (
            <CircularIndeterminate />
          ) : (
            <GlobalButton
              name={"assign"}
              onclick={() =>
                handelSubmit(item._id, employeeSelection.employeeId, index)
              }
              background={"#dc2329"}
              width="50px"
              borderradius={"0.2rem"}
              padding="1px"
              color={"#fff"}
            />
          )}
          {/* <input
            type="checkbox"
            onChange={() =>
              handelSubmit(item._id, employeeSelection.employeeId)
            }
            className="checkButton"
          /> */}
        </div>
      ),
    };
  });

  // console.log(
  //   "Here is the oreder to be mapped========---------++++++++++++",
  //   orderData
  // );

  const [orderChartData, setOrderChartData] = useState([]);
  const getOrderChartDetails = async () => {
    try {
      setLoading(true);
      const res = await UserController?.orderChartDetails(token);
      setOrderChartData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error", error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrderDetails();
    getAllManager();
    getOrderChartDetails();
  }, []);

  // console.log("orderChartData", orderChartData);

  const currentMonthStatus = orderChartData?.currentMonthStatusCounts;

  // console.log("currentMonthStatus",currentMonthStatus);

  const lastSixMonthOrder = orderChartData?.lastSixMonthsOrders?.slice(6, 12);

  // console.log("lastSixMonthOrder", lastSixMonthOrder);

  const dataset = shortMonths(lastSixMonthOrder) ?? [
    { month: "Jan 24", count: 0 },
    { month: "Feb 24", count: 0 },

    { month: "Mar 24", count: 0 },

    { month: "Apr 24", count: 0 },

    { month: "May 24", count: 0 },

    { month: "Jun 24", count: 3 },
  ];

  return (
    <Container className="mb-3 mt-3">
      {showModal && (
        <OrderDetailsModal
          show={showModal}
          onHide={() => {
            setShowModal(false);
          }}
          viewModalData={viewModalData}
        />
      )}

      <Row className="graphContainer ">
        <Col className="piChart">
          <p
            style={{
              color: "black",
              margin: "10px",
              textDecoration: "underline",
            }}
          >
            <strong>Last Month Order</strong>
          </p>
          {loading ? (
            <div className="spinnerrowitem">
              <CircularIndeterminate />
            </div>
          ) : (
            <PieChart
              series={[
                {
                  data: [
                    {
                      id: 0,
                      value: currentMonthStatus?.pending,
                      label: "Pending",
                      color: "#FACC15",
                    },
                    {
                      id: 1,
                      value: currentMonthStatus?.confirmed,
                      label: "Confirmed",
                      color: "#F59E0B",
                    },
                    {
                      id: 2,
                      value: currentMonthStatus?.processing,
                      label: "Processing",
                      color: "#EC4899",
                    },
                    {
                      id: 3,
                      value: currentMonthStatus?.shipped,
                      label: "Shipped",
                      color: "#3B82F6",
                    },
                    {
                      id: 4,
                      value: currentMonthStatus?.shipped,
                      label: "Delivered",
                      color: "#14B8A6",
                    },
                  ],
                },
              ]}
              width={450}
              height={200}
            />
          )}
        </Col>
        <Col className="barChart">
          <p
            style={{
              color: "black",
              margin: "10px",
              textDecoration: "underline",
            }}
          >
            <strong>Last 6 Month Order</strong>
          </p>
          {loading ? (
            <div className="spinnerrowitem">
              <CircularIndeterminate />
            </div>
          ) : (
            <TickPlacementBars
              data={dataset}
              accessKey="count"
              widthOfBar={0.85}
            />
          )}
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          {loading ? (
            <div className="spinnerrowitem">
              <CircularIndeterminate />
            </div>
          ) : (
            <div className="p-2" style={{
              backgroundColor: "white",
            }}>
              <Row className="">
                <Col>
                  <div id="orderhistory1">
                    <button
                      className="btn btn-success ms-auto"
                      onClick={exportToExcel}
                    >
                      Export Excel
                    </button>
                    <button
                      className="btn btn-primary ms-2"
                      onClick={exportToPDF}
                    >
                      Export PDF
                    </button>
                  </div>
                </Col>
              </Row>
              <DataTable columns={columns} data={data} />
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};
export default OrderHistory;
