import React from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import ProductCategory from "../ProductCategory/ProductCategory";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import "./Other.css";
import LeaveRequests from "../LeaveRequests/LeaveRequest";
import YearlyLeaves from "../YearlyLeaves/YearlyLeaves";

const Others = () => {
  return (
    <Container className="mb-3 mt-3 othercontainer">
      
      <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
        <Tab eventKey="home" title="Product Category" className="tab">
          <ProductCategory />
        </Tab>
        <Tab eventKey="query" title="Leave Requests" className="tab">
          <LeaveRequests />
        </Tab>
        {/* <Tab eventKey="tr" title="Transactions" className="tab">
          <LeaveRequests />
        </Tab> */}
        <Tab eventKey="yearly" title="Yearly Leaves" className="tab">
          <YearlyLeaves />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default Others;
