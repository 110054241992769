import React, { useEffect, useState } from "react";
import { Container, Modal, Row, Col, Form } from "react-bootstrap";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import { UserController } from "../../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import CircularIndeterminate from "../../../../globalcomponents/Spinner/Spinner";
import Utility from "../../../Utils/Utility";

const ViewLeaveRequests = (props) => {
  const { token } = useSelector((st) => st?.user);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const leaveData = props?.leaveData;
  const handleApprove = async (approvalStatus) => {
    try {
      approvalStatus === "approved"
        ? setApproveLoading(true)
        : setRejectLoading(true);
      const { data } = await UserController.assignOrRejectLeave(
        token,
        leaveData?._id,
        { approvalStatus }
      );
      Utility.sToast(data?.message);
      props.onHide();
    } catch (error) {
      Utility.eToast(error?.response?.data?.message || error?.message);
    } finally {
      setApproveLoading(false);
      setRejectLoading(false);
    }
  };

  return (
    <Modal {...props} size="lg">
      <Modal.Header closeButton style={{ background: "#F4F3F0" }}>
        <Modal.Title style={{ color: "black", background: "#F4F3F0" }}>
          Leave Request Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-1">
          <Col>Employee Name</Col>
          <Col className="value">{leaveData?.appliedBy?.name || "N/A"}</Col>
        </Row>
        <Row className="mt-1">
          <Col>Department</Col>
          <Col className="value">
            {leaveData?.appliedBy?.empDepartment
              ? Utility.getDepartment(leaveData?.appliedBy?.empDepartment)
              : "N/A"}
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>Requested On</Col>
          <Col className="value">
            {Utility.formatDate(leaveData?.createdAt) || "N/A"}
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>Reason</Col>
          <Col className="value">{leaveData?.causeOfLeave || "N/A"}</Col>
        </Row>
        <Row className="mt-1">
          <Col>From</Col>
          <Col className="value">
            {Utility.formatDate(leaveData?.fromDate) || "N/A"}
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>To</Col>
          <Col className="value">
            {Utility.formatDate(leaveData?.toDate) || "N/A"}
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>No Of Days</Col>
          <Col className="value">{leaveData?.noOfDays || "N/A"}</Col>
        </Row>
        <hr className="mt-2" />
        {leaveData?.approvalStatus === "pending" ? (
          <div className="w-100 d-flex justify-content-end align-items-center">
            {rejectLoading ? (
              <div
                style={{
                  width: "4.5rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularIndeterminate />
              </div>
            ) : (
              <button
                id="btnnn"
                className="selectButton"
                onClick={() => handleApprove("rejected")}
                style={{ width: "4.5rem" }}
              >
                Reject
              </button>
            )}
            {approveLoading ? (
              <div
                style={{
                  width: "4.5rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularIndeterminate />
              </div>
            ) : (
              <button
                id="btnnn"
                className="selectButton"
                onClick={() => handleApprove("approved")}
                style={{ width: "4.5rem" }}
              >
                Approve
              </button>
            )}
          </div>
        ) : (
          <>
            <Row className="mt-1">
              <Col>Approval Status</Col>
              <Col className="value">
                {leaveData?.approvalStatus?.charAt(0).toUpperCase() +
                  leaveData?.approvalStatus?.slice(1) || "N/A"}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>
                {leaveData?.approvalStatus?.charAt(0).toUpperCase() +
                  leaveData?.approvalStatus?.slice(1)}{" "}
                By
              </Col>
              <Col className="value">
                {leaveData?.approvedBy?.name || "N/A"}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>
                {leaveData?.approvalStatus?.charAt(0).toUpperCase() +
                  leaveData?.approvalStatus?.slice(1)}{" "}
                On
              </Col>
              <Col className="value">
                {Utility.formatDate(leaveData?.approvedOn) || "N/A"}
              </Col>
            </Row>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ViewLeaveRequests;
