import React, { useEffect, useState } from "react";
import { Container, Modal, Row, Col } from "react-bootstrap";
import GlobalButton from "../../../../globalcomponents/GlobalButton/GlobalButton";
import "./CustomerInformationModal.css";
import DataTable from "../../../../globalcomponents/DataTable/DataTable";
import { UserController } from "../../../Redux/controllers/UserController";
import { useSelector } from "react-redux";
import Utility from "../../../Utils/Utility";
import CircularIndeterminate from "../../../../globalcomponents/Spinner/Spinner";

const CustomerInformationModal = (props) => {
  const { token } = useSelector((st) => st?.user);
  const [orderData, setOrderData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllOrdersOfUser = async () => {
    try {
      setLoading(true);
      const { data } = await UserController.getOrdersOfUser(
        token,
        props?.selectedCustomer?._id
      );
      setOrderData(data?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllOrdersOfUser();
  }, []);
  const columns = [
    {
      accessorKey: "slNo",
      header: "Sl",
      size: 30,
    },
    {
      accessorKey: "date",
      header: "Date",
      size: 30,
    },
    {
      accessorKey: "orderId",
      header: "Order Id",
      size: 100,
    },
    {
      accessorKey: "price",
      header: "Price",
      size: 50,
    },
  ];

  const data = orderData?.map((item, index) => {
    return {
      slNo: index + 1,
      date: Utility?.formatDate(item?.createdAt),
      orderId: "#" + item?.orderID,
      price: Utility?.MoneyFormatter(item?.paymentDetails?.totalAmount),
      // details: (
      //   <GlobalButton
      //     name={"View"}
      //     background={"#E4CDAA"}
      //     color={"#642315"}
      //     padding={"0"}
      //   />
      // ),
    };
  });

  return (
    // color: "#642315E5", background: "#f4f3f0"
    <Modal {...props} size="lg">
      <Modal.Header closeButton style={{ background: "#f4f3f0" }}>
        <Modal.Title style={{ color: "black", background: "#f4f3f0" }}>
          Customer Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-1">
          <Col>Name</Col>
          <Col className="value">{props?.selectedCustomer?.name || "N/A"}</Col>
        </Row>
        <Row className="mt-1">
          <Col>Phone Number</Col>
          <Col className="value">
            {props?.selectedCustomer?.phoneNo || "--"}
          </Col>
        </Row>
        <Row className="mt-1">
          <Col>Email Address</Col>
          <Col className="value">{props?.selectedCustomer?.email || "--"}</Col>
        </Row>
        <Row className="mt-1">
          <Col>Address</Col>
          <Col className="value">
            {props?.selectedCustomer?.address || "--"}
          </Col>
        </Row>

        <Row className="mt-1">
          <div id="orderHistory">
            <h6 color="#642315">Order History</h6>
          </div>
          {orderData?.length > 0 ? (
            <Col>
              <DataTable data={data} columns={columns} />
            </Col>
          ) : (
            <Col className="d-flex justify-content-center align-items-center">
              {loading ? (
                <div className="spinnerrowitem">
                  <CircularIndeterminate />
                </div>
              ) : (
                "No orders yet"
              )}
            </Col>
          )}
        </Row>
      </Modal.Body>
    </Modal>
    // </Container>
  );
};

export default CustomerInformationModal;
